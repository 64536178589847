import React,{useState} from "react"

import {
    EuiHeaderSectionItem,
    EuiPopover,
    EuiHeaderLogo
} from "@elastic/eui"

export default function({
    children,
    icon
}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const button = <EuiHeaderLogo 
        iconType={icon}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
    />
    return (
        <EuiHeaderSectionItem border="right">
            <EuiPopover
                ownFocus
                button={button}
                isOpen={isMenuOpen}
                closePopover={() => setIsMenuOpen(false)}
            >
               {children}
            </EuiPopover>
        </EuiHeaderSectionItem>
    )
}