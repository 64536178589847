import React from "react"
import Page from '../components/Page'

export default function Attributions(props){
    return (
        <Page title="Software disclosure">
            <h3>Libraries/Packages Licenses</h3>
                <h4>User interface:</h4>
                <ul>
                    <li>Grpc-web (Apache 2.0)</li>
                    <li>Grpc-web-client (Apache 2.0) </li>
                    <li>React (MIT)</li>
                    <li>React redux (MIT)</li>
                    <li>React router (MIT)</li>
                    <li>React script (MIT)</li>
                    <li>React switch (MIT)</li>
                    <li>Redux (MIT) </li>
                    <li>Semiotic (Apache 2.0)</li>
                    <li>Styled components (MIT)</li>
                    <li>Ts-protoc-gen (Apache 2.0)</li>
                    <li>Typescript (Apache 2.0)</li>
                    <li>Vaadin components (Apache 2.0)</li>
                    <li>Webcomponent (BSD style)</li>
                    <li>Xlsx (Apache 2.0)</li>
                    <li>Elastic-UI (Apache 2.0)</li>
                </ul>

                <h4>Backend:</h4>
                <ul>
                    <li>Brightway2 (BSD-3-Clause)</li>
                    <li>Connexion2 (Apache 2.0)</li>
                    <li>Grpc (Apache 2.0)</li>
                    <li>InfluxDb (MIT)</li>
                    <li>Pandas (BSD 3-Clause)</li>
                    <li>Spring (Apache 2.0)</li>
                </ul>

            <h3>Copyprights attributions:</h3>
                <h4>Greek icons:</h4>
                <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/"     title="Flaticon">www.flaticon.com</a></div>
        </Page>
    )
}