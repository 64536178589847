import React, { useRef, useEffect } from 'react'

export default function AgentEditor({betas}) {
    
    const priceRef = useRef(null)
    const greenRef = useRef(null)
    const rangeRef = useRef(null)
    useEffect(
        () =>{
            // form callback
            priceRef.current.addEventListener('change', (e) => betas.price = e.target.value );
            greenRef.current.addEventListener('change', (e) => betas.green = e.target.value );
            rangeRef.current.addEventListener('change', (e) => betas.range = e.target.value );
        },
        [
            greenRef,
            priceRef,
            rangeRef,
            betas
        ]
    )
    
    return (
        <vaadin-form-layout>
            {/* price" */}
            <vaadin-number-field ref={priceRef} label="Beta price" has-controls  min="-2" max="2" step="0.01" value={betas.price}></vaadin-number-field>
            {/* green" */}
            <vaadin-number-field ref={greenRef} label="Beta green" has-controls min="-2" max="2" step="0.01" value={betas.green}  ></vaadin-number-field>
            {/* range */}
            <vaadin-number-field ref={rangeRef} label="Beta range" has-controls  min="-2" max="2" step="0.01" value={betas.range}></vaadin-number-field>
        </vaadin-form-layout>
    )
}