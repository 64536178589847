import { combineReducers } from "redux";

import {configurationLoaderReducer} from './features/configurationLoader'
import featuresSwitchReducer from './features/featureSwitch/reducer'
import colorPaletteReducer from './features/colorPalette/reducer'

export default combineReducers({
    configuration: configurationLoaderReducer,
    features: featuresSwitchReducer,
    colorPalette: colorPaletteReducer
})
