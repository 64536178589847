import React from "react"
import styled from 'styled-components'


const Images = styled.div`
    display:flex;
    justify-content: space-around;
    align-items: center;
    flex:1;
`

export default function() {
    return (
        <Images>
            <a href="https://www.engie.com/" target="_blank">
                <img
                    src="/static/logo/Engie.png"
                    alt="Engie logo"
                    style={{
                        height: "50px"
                    }}
                />
            </a>
            <a href="https://www.list.lu/" target="_blank">
                <img
                    src="/static/logo/LIST.png"
                    alt="LIST logo"
                    style={{
                        height: "50px"
                    }}
                />
            </a>
        </Images>
    )
}