import { Agent } from "../generated/agent_pb";
import { Car } from "../generated/car_pb";
import { AgentsCarsParameters } from "../generated/agentsCarsParameters_pb";
import { AgentsParameters, AgentTimeSeries } from "../generated/agentsParameters_pb";
import { EnergiesParameters } from "../generated/energiesParameters_pb";
import { PowertrainsParameters } from '../generated/powertrainsParameters_pb'
import { PowertrainsCarsParameters } from "../generated/powertrainsCarsParameters_pb";
import { EnergiesPowertrainsCarsParameters } from "../generated/energiesPowertrainsCarsParameters_pb";
import { Mix } from "../generated/mix_pb";

export function agentTypeConverter(str) {
    switch(str) {
        case "COLLECTIVITES_BUS_12":
            return Agent.AgentType.COLLECTIVITES_BUS_12
        case "COLLECTIVITES_BUS_18":
            return Agent.AgentType.COLLECTIVITES_BUS_18
        case "COLLECTIVITES_BOM":
            return Agent.AgentType.COLLECTIVITES_BOM
        case "ENTREPRISES_VP":
            return Agent.AgentType.ENTREPRISES_VP
        case "ENTREPRISES_VU":
            return Agent.AgentType.ENTREPRISES_VU
        case "TAXIS_IND":
            return Agent.AgentType.TAXIS_IND
        case "TAXIS_COMPANY":
            return Agent.AgentType.TAXIS_COMPANY
        case "ARTISANS":
            return Agent.AgentType.ARTISANS
        default:
            console.error("UNKNOWN AGENT TYPE "+str)
    }
}

export function betasConverter(str){
    switch(str){
        case "BETA_PRICE":
            return AgentTimeSeries.AgentTimeSeriesAttribute.BETA_PRICE
        case "BETA_RANGE":
            return AgentTimeSeries.AgentTimeSeriesAttribute.BETA_RANGE
        case "BETA_GREEN":
            return AgentTimeSeries.AgentTimeSeriesAttribute.BETA_GREEN
        default:
            console.error("UNKNOWN BETA TYPE "+str)
    }
}

export function carTypeConverter(str) {
    switch(str) {
        case "BUS_12":
            return Car.CarType.BUS_12
        case "BUS_18":
            return Car.CarType.BUS_18
        case "BOM":
            return Car.CarType.BOM
        case "VP":
            return Car.CarType.VP
        case "VU":
            return Car.CarType.VU
        default:
            console.error("UNKNOWN CAR TYPE "+str)
    }
}

export function agentCarAttributeConverter(str) {
    switch(str) {
        case "DEMAND":
            return AgentsCarsParameters.AgentCarAttribute.DEMAND
        case "DEMAND_MIN":
            return AgentsCarsParameters.AgentCarAttribute.DEMAND_MIN
        case "DEMAND_MAX":
            return AgentsCarsParameters.AgentCarAttribute.DEMAND_MAX
        default:
            console.error("UNKNOWN AGENT CAR ATTRIBUTE "+str)
    }
}

export function carPowertrainAttributeConverter(str) {
    switch(str) {
        case "PRICE":
            return PowertrainsCarsParameters.PowertrainsCarsAttribute.PRICE
        case "RANGE":
            return PowertrainsCarsParameters.PowertrainsCarsAttribute.RANGE
        case "DISCOUNT":
            return PowertrainsCarsParameters.PowertrainsCarsAttribute.DISCOUNT
        default:
            console.error("UNKNOWN Powertrain CAR ATTRIBUTE "+str)
    }
}

export function energyCarPowertrainAttributeConverter(str) {
    switch(str) {
        case 'CONSUMPTION':
            return EnergiesPowertrainsCarsParameters.EnergiesPowertrainsCarsAttribute.CONSUMPTION
        default:
            console.error("UNKNOWN Energy Powertrain CAR ATTRIBUTE "+str)
    }
}


export function energyTypeConverter(str) {
    switch(str) {
        case "DIESEL":
            return EnergiesParameters.Energy.DIESEL
        case "ELECTRICITY":
            return EnergiesParameters.Energy.ELECTRICITY
        case "PETROL":
            return EnergiesParameters.Energy.PETROL
        case "GNC":
            return EnergiesParameters.Energy.CNG
        case "CNG":
            return EnergiesParameters.Energy.CNG
        case "H2":
            return EnergiesParameters.Energy.H2
        default:
            console.error("UNKNOWN energy TYPE "+str)
    }
}

export function powertrainTypeConverter(str) {
    switch(str) {
        case "H2":
            return Car.Powertrain.H2
        case "HYBRID_DIESEL":
            return Car.Powertrain.HYBRID_DIESEL
        case "HYBRID_PETROL":
            return Car.Powertrain.HYBRID_PETROL
        case "PLUG_IN_HYBRID_DIESEL":
            return Car.Powertrain.PLUG_IN_HYBRID_DIESEL
        case "PLUG_IN_HYBRID_PETROL":
            return Car.Powertrain.PLUG_IN_HYBRID_PETROL
        case "CNG":
            return Car.Powertrain.CNG
        case "GNC":
            return Car.Powertrain.CNG
        case "DIESEL":
            return Car.Powertrain.DIESEL
        case "ELECTRIC":
            return Car.Powertrain.ELECTRIC
        case "PETROL":
            return Car.Powertrain.PETROL
        default:
            console.error("UNKNOWN powertrain TYPE "+str)
    }
}


export function powertrainAttributeConverter(str) {
    switch(str) {
        case "SUBSIDY":
            return PowertrainsParameters.PowertrainsAttribute.SUBSIDY
        case "GREEN_SCORE":
            return PowertrainsParameters.PowertrainsAttribute.GREEN_SCORE
        default:
            console.error("UNKNOWN AGENT CAR ATTRIBUTE "+str)
    }
}

function mixElectricityConverter(str) {
    switch(str) {
        case "CHP_BIOGAS":
            return Mix.ElectricitySource.CHP_BIOGAS
        case "CHP_NGCC":
            return Mix.ElectricitySource.CHP_NGCC
        case "CHP_OIL":
            return Mix.ElectricitySource.CHP_OIL
        case "CHP_WOOD":
            return Mix.ElectricitySource.CHP_WOOD
        case "HARD_COAL":
            return Mix.ElectricitySource.HARD_COAL
        case "HYDRO_PUMPED":
            return Mix.ElectricitySource.HYDRO_PUMPED
        case "HYDRO_RESERVOIR":
            return Mix.ElectricitySource.HYDRO_RESERVOIR
        case "HYDRO_RUN_OF_RIVER":
            return Mix.ElectricitySource.HYDRO_RUN_OF_RIVER
        case "IMPORT_BE":
            return Mix.ElectricitySource.IMPORT_BE
        case "IMPORT_CH":
            return Mix.ElectricitySource.IMPORT_CH
        case "IMPORT_DE":
            return Mix.ElectricitySource.IMPORT_DE
        case "IMPORT_ES":
            return Mix.ElectricitySource.IMPORT_ES
        case "IMPORT_GB":
            return Mix.ElectricitySource.IMPORT_GB
        case "IMPORT_IT":
            return Mix.ElectricitySource.IMPORT_IT
        case "NGCC":
            return Mix.ElectricitySource.NGCC
        case "NG_CONV":
            return Mix.ElectricitySource.NG_CONV
        case "NUCLEAR_PWR":
            return Mix.ElectricitySource.NUCLEAR_PWR
        case "OIL":
            return Mix.ElectricitySource.OIL
        case "WIND_OFFSHORE_1_3MW":
            return Mix.ElectricitySource.WIND_OFFSHORE_1_3MW
        case "WIND_ONSHORE_1MW":
            return Mix.ElectricitySource.WIND_ONSHORE_1MW
        case "WIND_ONSHORE_1_3MW":
            return Mix.ElectricitySource.WIND_ONSHORE_1_3MW
        case "WIND_ONSHORE_3MW":
            return Mix.ElectricitySource.WIND_ONSHORE_3MW
        default:
            console.error("UNKNOWN ELECTRICITY TYPE "+str)
    }
}
export function getElectricitySourceFromIndex(index) {
    return getStringFromIndex(Mix.ElectricitySource, index)
}
export function getGasSourceFromIndex(index) {
    return getStringFromIndex(Mix.GasSource, index)
}

function mixGasConverter(str) {
    switch(str) {
        case "BIOGAS":
            return Mix.GasSource.BIOGAS
        case "NG":
            return Mix.GasSource.NG
        default:
            console.error("UNKNOWN ELECTRICITY TYPE "+str)
    }
}

export function mixSourceConverter(type, str){
    if(type === Mix.EnergyType.GAS){
        return mixGasConverter(str)
    }
    else if(type === Mix.EnergyType.ELECTRICITY){
        return mixElectricityConverter(str)
    }
    console.error("UNKNOWN SOURCE TYPE "+str)
}

export function mixAttributeConverter(str) {
    switch(str) {
        case "ELECTRICITY":
            return Mix.EnergyType.ELECTRICITY
        case "GAS":
            return Mix.EnergyType.GAS
        default:
            console.error("UNKNOWN MIX ATTRIBUTE")
    }
}

function getStringFromIndex(enumMap, index){
    return Object.keys(enumMap).filter(k => (index === enumMap[k]))[0]
}

export function getPowertrainFromIndex(index) {
    return getStringFromIndex(Car.Powertrain, index)
}
export function getEnergyFromIndex(index) {
    return getStringFromIndex(EnergiesParameters.Energy, index)
}
export function getCarFromIndex(index) {
    return getStringFromIndex(Car.CarType, index)
}
export function getAgentTypeFromIndex(index) {
    return getStringFromIndex(Agent.AgentType, index)
}
