/* eslint-disable */ /**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var agent_pb = require('./agent_pb.js');
var configuration_pb = require('./configuration_pb.js');
var date_pb = require('./date_pb.js');
var scenario_pb = require('./scenario_pb.js');
var environment_pb = require('./environment_pb.js');
goog.exportSymbol('proto.AdvanceTimeCommand', null, global);
goog.exportSymbol('proto.AgentApplyBehaviorCommand', null, global);
goog.exportSymbol('proto.AgentHelcarCommand', null, global);
goog.exportSymbol('proto.Command', null, global);
goog.exportSymbol('proto.CreateAgentCommand', null, global);
goog.exportSymbol('proto.MakeInitialStateCommand', null, global);
goog.exportSymbol('proto.MarkCarCommand', null, global);
goog.exportSymbol('proto.MoveCarCommand', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Command.oneofGroups_);
};
goog.inherits(proto.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Command.displayName = 'proto.Command';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Command.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.Command.CommandCase = {
  COMMAND_NOT_SET: 0,
  MAKEINITIALSTATECOMMAND: 1,
  AGENTAPPLYBEHAVIORCOMMAND: 2,
  ADVANCETIMECOMMAND: 3
};

/**
 * @return {proto.Command.CommandCase}
 */
proto.Command.prototype.getCommandCase = function() {
  return /** @type {proto.Command.CommandCase} */(jspb.Message.computeOneofCase(this, proto.Command.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Command.toObject = function(includeInstance, msg) {
  var f, obj = {
    makeinitialstatecommand: (f = msg.getMakeinitialstatecommand()) && proto.MakeInitialStateCommand.toObject(includeInstance, f),
    agentapplybehaviorcommand: (f = msg.getAgentapplybehaviorcommand()) && proto.AgentApplyBehaviorCommand.toObject(includeInstance, f),
    advancetimecommand: (f = msg.getAdvancetimecommand()) && proto.AdvanceTimeCommand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Command}
 */
proto.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Command;
  return proto.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Command}
 */
proto.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.MakeInitialStateCommand;
      reader.readMessage(value,proto.MakeInitialStateCommand.deserializeBinaryFromReader);
      msg.setMakeinitialstatecommand(value);
      break;
    case 2:
      var value = new proto.AgentApplyBehaviorCommand;
      reader.readMessage(value,proto.AgentApplyBehaviorCommand.deserializeBinaryFromReader);
      msg.setAgentapplybehaviorcommand(value);
      break;
    case 3:
      var value = new proto.AdvanceTimeCommand;
      reader.readMessage(value,proto.AdvanceTimeCommand.deserializeBinaryFromReader);
      msg.setAdvancetimecommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMakeinitialstatecommand();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.MakeInitialStateCommand.serializeBinaryToWriter
    );
  }
  f = message.getAgentapplybehaviorcommand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.AgentApplyBehaviorCommand.serializeBinaryToWriter
    );
  }
  f = message.getAdvancetimecommand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.AdvanceTimeCommand.serializeBinaryToWriter
    );
  }
};


/**
 * optional MakeInitialStateCommand makeInitialStateCommand = 1;
 * @return {?proto.MakeInitialStateCommand}
 */
proto.Command.prototype.getMakeinitialstatecommand = function() {
  return /** @type{?proto.MakeInitialStateCommand} */ (
    jspb.Message.getWrapperField(this, proto.MakeInitialStateCommand, 1));
};


/** @param {?proto.MakeInitialStateCommand|undefined} value */
proto.Command.prototype.setMakeinitialstatecommand = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.Command.oneofGroups_[0], value);
};


proto.Command.prototype.clearMakeinitialstatecommand = function() {
  this.setMakeinitialstatecommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Command.prototype.hasMakeinitialstatecommand = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AgentApplyBehaviorCommand agentApplyBehaviorCommand = 2;
 * @return {?proto.AgentApplyBehaviorCommand}
 */
proto.Command.prototype.getAgentapplybehaviorcommand = function() {
  return /** @type{?proto.AgentApplyBehaviorCommand} */ (
    jspb.Message.getWrapperField(this, proto.AgentApplyBehaviorCommand, 2));
};


/** @param {?proto.AgentApplyBehaviorCommand|undefined} value */
proto.Command.prototype.setAgentapplybehaviorcommand = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.Command.oneofGroups_[0], value);
};


proto.Command.prototype.clearAgentapplybehaviorcommand = function() {
  this.setAgentapplybehaviorcommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Command.prototype.hasAgentapplybehaviorcommand = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AdvanceTimeCommand advanceTimeCommand = 3;
 * @return {?proto.AdvanceTimeCommand}
 */
proto.Command.prototype.getAdvancetimecommand = function() {
  return /** @type{?proto.AdvanceTimeCommand} */ (
    jspb.Message.getWrapperField(this, proto.AdvanceTimeCommand, 3));
};


/** @param {?proto.AdvanceTimeCommand|undefined} value */
proto.Command.prototype.setAdvancetimecommand = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.Command.oneofGroups_[0], value);
};


proto.Command.prototype.clearAdvancetimecommand = function() {
  this.setAdvancetimecommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Command.prototype.hasAdvancetimecommand = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MakeInitialStateCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MakeInitialStateCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.MakeInitialStateCommand.displayName = 'proto.MakeInitialStateCommand';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MakeInitialStateCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.MakeInitialStateCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MakeInitialStateCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MakeInitialStateCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    configuration: (f = msg.getConfiguration()) && configuration_pb.Configuration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MakeInitialStateCommand}
 */
proto.MakeInitialStateCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MakeInitialStateCommand;
  return proto.MakeInitialStateCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MakeInitialStateCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MakeInitialStateCommand}
 */
proto.MakeInitialStateCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new configuration_pb.Configuration;
      reader.readMessage(value,configuration_pb.Configuration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MakeInitialStateCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MakeInitialStateCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MakeInitialStateCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MakeInitialStateCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      configuration_pb.Configuration.serializeBinaryToWriter
    );
  }
};


/**
 * optional Configuration configuration = 1;
 * @return {?proto.Configuration}
 */
proto.MakeInitialStateCommand.prototype.getConfiguration = function() {
  return /** @type{?proto.Configuration} */ (
    jspb.Message.getWrapperField(this, configuration_pb.Configuration, 1));
};


/** @param {?proto.Configuration|undefined} value */
proto.MakeInitialStateCommand.prototype.setConfiguration = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.MakeInitialStateCommand.prototype.clearConfiguration = function() {
  this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.MakeInitialStateCommand.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentApplyBehaviorCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.AgentApplyBehaviorCommand.oneofGroups_);
};
goog.inherits(proto.AgentApplyBehaviorCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.AgentApplyBehaviorCommand.displayName = 'proto.AgentApplyBehaviorCommand';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.AgentApplyBehaviorCommand.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.AgentApplyBehaviorCommand.BehaviorCase = {
  BEHAVIOR_NOT_SET: 0,
  AGENTHELCARCOMMAND: 1,
  MOVECARCOMMAND: 2,
  MARKCARCOMMAND: 3
};

/**
 * @return {proto.AgentApplyBehaviorCommand.BehaviorCase}
 */
proto.AgentApplyBehaviorCommand.prototype.getBehaviorCase = function() {
  return /** @type {proto.AgentApplyBehaviorCommand.BehaviorCase} */(jspb.Message.computeOneofCase(this, proto.AgentApplyBehaviorCommand.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentApplyBehaviorCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentApplyBehaviorCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentApplyBehaviorCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentApplyBehaviorCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    agenthelcarcommand: (f = msg.getAgenthelcarcommand()) && proto.AgentHelcarCommand.toObject(includeInstance, f),
    movecarcommand: (f = msg.getMovecarcommand()) && proto.MoveCarCommand.toObject(includeInstance, f),
    markcarcommand: (f = msg.getMarkcarcommand()) && proto.MarkCarCommand.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentApplyBehaviorCommand}
 */
proto.AgentApplyBehaviorCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentApplyBehaviorCommand;
  return proto.AgentApplyBehaviorCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentApplyBehaviorCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentApplyBehaviorCommand}
 */
proto.AgentApplyBehaviorCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AgentHelcarCommand;
      reader.readMessage(value,proto.AgentHelcarCommand.deserializeBinaryFromReader);
      msg.setAgenthelcarcommand(value);
      break;
    case 2:
      var value = new proto.MoveCarCommand;
      reader.readMessage(value,proto.MoveCarCommand.deserializeBinaryFromReader);
      msg.setMovecarcommand(value);
      break;
    case 3:
      var value = new proto.MarkCarCommand;
      reader.readMessage(value,proto.MarkCarCommand.deserializeBinaryFromReader);
      msg.setMarkcarcommand(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentApplyBehaviorCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentApplyBehaviorCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentApplyBehaviorCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentApplyBehaviorCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgenthelcarcommand();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.AgentHelcarCommand.serializeBinaryToWriter
    );
  }
  f = message.getMovecarcommand();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.MoveCarCommand.serializeBinaryToWriter
    );
  }
  f = message.getMarkcarcommand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.MarkCarCommand.serializeBinaryToWriter
    );
  }
};


/**
 * optional AgentHelcarCommand agentHelcarCommand = 1;
 * @return {?proto.AgentHelcarCommand}
 */
proto.AgentApplyBehaviorCommand.prototype.getAgenthelcarcommand = function() {
  return /** @type{?proto.AgentHelcarCommand} */ (
    jspb.Message.getWrapperField(this, proto.AgentHelcarCommand, 1));
};


/** @param {?proto.AgentHelcarCommand|undefined} value */
proto.AgentApplyBehaviorCommand.prototype.setAgenthelcarcommand = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.AgentApplyBehaviorCommand.oneofGroups_[0], value);
};


proto.AgentApplyBehaviorCommand.prototype.clearAgenthelcarcommand = function() {
  this.setAgenthelcarcommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentApplyBehaviorCommand.prototype.hasAgenthelcarcommand = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MoveCarCommand moveCarCommand = 2;
 * @return {?proto.MoveCarCommand}
 */
proto.AgentApplyBehaviorCommand.prototype.getMovecarcommand = function() {
  return /** @type{?proto.MoveCarCommand} */ (
    jspb.Message.getWrapperField(this, proto.MoveCarCommand, 2));
};


/** @param {?proto.MoveCarCommand|undefined} value */
proto.AgentApplyBehaviorCommand.prototype.setMovecarcommand = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.AgentApplyBehaviorCommand.oneofGroups_[0], value);
};


proto.AgentApplyBehaviorCommand.prototype.clearMovecarcommand = function() {
  this.setMovecarcommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentApplyBehaviorCommand.prototype.hasMovecarcommand = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MarkCarCommand markCarCommand = 3;
 * @return {?proto.MarkCarCommand}
 */
proto.AgentApplyBehaviorCommand.prototype.getMarkcarcommand = function() {
  return /** @type{?proto.MarkCarCommand} */ (
    jspb.Message.getWrapperField(this, proto.MarkCarCommand, 3));
};


/** @param {?proto.MarkCarCommand|undefined} value */
proto.AgentApplyBehaviorCommand.prototype.setMarkcarcommand = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.AgentApplyBehaviorCommand.oneofGroups_[0], value);
};


proto.AgentApplyBehaviorCommand.prototype.clearMarkcarcommand = function() {
  this.setMarkcarcommand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentApplyBehaviorCommand.prototype.hasMarkcarcommand = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AdvanceTimeCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AdvanceTimeCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.AdvanceTimeCommand.displayName = 'proto.AdvanceTimeCommand';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AdvanceTimeCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.AdvanceTimeCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AdvanceTimeCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AdvanceTimeCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    curentdate: (f = msg.getCurentdate()) && date_pb.Date.toObject(includeInstance, f),
    frequency: jspb.Message.getFieldWithDefault(msg, 2, 0),
    configuration: (f = msg.getConfiguration()) && configuration_pb.Configuration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AdvanceTimeCommand}
 */
proto.AdvanceTimeCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AdvanceTimeCommand;
  return proto.AdvanceTimeCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AdvanceTimeCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AdvanceTimeCommand}
 */
proto.AdvanceTimeCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setCurentdate(value);
      break;
    case 2:
      var value = /** @type {!proto.Date.Frequency} */ (reader.readEnum());
      msg.setFrequency(value);
      break;
    case 3:
      var value = new configuration_pb.Configuration;
      reader.readMessage(value,configuration_pb.Configuration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AdvanceTimeCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AdvanceTimeCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AdvanceTimeCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AdvanceTimeCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurentdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      configuration_pb.Configuration.serializeBinaryToWriter
    );
  }
};


/**
 * optional Date curentDate = 1;
 * @return {?proto.Date}
 */
proto.AdvanceTimeCommand.prototype.getCurentdate = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 1));
};


/** @param {?proto.Date|undefined} value */
proto.AdvanceTimeCommand.prototype.setCurentdate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.AdvanceTimeCommand.prototype.clearCurentdate = function() {
  this.setCurentdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AdvanceTimeCommand.prototype.hasCurentdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Date.Frequency frequency = 2;
 * @return {!proto.Date.Frequency}
 */
proto.AdvanceTimeCommand.prototype.getFrequency = function() {
  return /** @type {!proto.Date.Frequency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.Date.Frequency} value */
proto.AdvanceTimeCommand.prototype.setFrequency = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Configuration configuration = 3;
 * @return {?proto.Configuration}
 */
proto.AdvanceTimeCommand.prototype.getConfiguration = function() {
  return /** @type{?proto.Configuration} */ (
    jspb.Message.getWrapperField(this, configuration_pb.Configuration, 3));
};


/** @param {?proto.Configuration|undefined} value */
proto.AdvanceTimeCommand.prototype.setConfiguration = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.AdvanceTimeCommand.prototype.clearConfiguration = function() {
  this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AdvanceTimeCommand.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentHelcarCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentHelcarCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.AgentHelcarCommand.displayName = 'proto.AgentHelcarCommand';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentHelcarCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentHelcarCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentHelcarCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentHelcarCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    agent: (f = msg.getAgent()) && agent_pb.Agent.toObject(includeInstance, f),
    scenario: (f = msg.getScenario()) && scenario_pb.Scenario.toObject(includeInstance, f),
    demandagentkey: jspb.Message.getFieldWithDefault(msg, 3, 0),
    date: (f = msg.getDate()) && date_pb.Date.toObject(includeInstance, f),
    configuration: (f = msg.getConfiguration()) && configuration_pb.Configuration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentHelcarCommand}
 */
proto.AgentHelcarCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentHelcarCommand;
  return proto.AgentHelcarCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentHelcarCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentHelcarCommand}
 */
proto.AgentHelcarCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new agent_pb.Agent;
      reader.readMessage(value,agent_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 2:
      var value = new scenario_pb.Scenario;
      reader.readMessage(value,scenario_pb.Scenario.deserializeBinaryFromReader);
      msg.setScenario(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDemandagentkey(value);
      break;
    case 4:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 5:
      var value = new configuration_pb.Configuration;
      reader.readMessage(value,configuration_pb.Configuration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentHelcarCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentHelcarCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentHelcarCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentHelcarCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      agent_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getScenario();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      scenario_pb.Scenario.serializeBinaryToWriter
    );
  }
  f = message.getDemandagentkey();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      configuration_pb.Configuration.serializeBinaryToWriter
    );
  }
};


/**
 * optional Agent agent = 1;
 * @return {?proto.Agent}
 */
proto.AgentHelcarCommand.prototype.getAgent = function() {
  return /** @type{?proto.Agent} */ (
    jspb.Message.getWrapperField(this, agent_pb.Agent, 1));
};


/** @param {?proto.Agent|undefined} value */
proto.AgentHelcarCommand.prototype.setAgent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.AgentHelcarCommand.prototype.clearAgent = function() {
  this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentHelcarCommand.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Scenario scenario = 2;
 * @return {?proto.Scenario}
 */
proto.AgentHelcarCommand.prototype.getScenario = function() {
  return /** @type{?proto.Scenario} */ (
    jspb.Message.getWrapperField(this, scenario_pb.Scenario, 2));
};


/** @param {?proto.Scenario|undefined} value */
proto.AgentHelcarCommand.prototype.setScenario = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.AgentHelcarCommand.prototype.clearScenario = function() {
  this.setScenario(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentHelcarCommand.prototype.hasScenario = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 demandAgentkey = 3;
 * @return {number}
 */
proto.AgentHelcarCommand.prototype.getDemandagentkey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.AgentHelcarCommand.prototype.setDemandagentkey = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Date date = 4;
 * @return {?proto.Date}
 */
proto.AgentHelcarCommand.prototype.getDate = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 4));
};


/** @param {?proto.Date|undefined} value */
proto.AgentHelcarCommand.prototype.setDate = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.AgentHelcarCommand.prototype.clearDate = function() {
  this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentHelcarCommand.prototype.hasDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Configuration configuration = 5;
 * @return {?proto.Configuration}
 */
proto.AgentHelcarCommand.prototype.getConfiguration = function() {
  return /** @type{?proto.Configuration} */ (
    jspb.Message.getWrapperField(this, configuration_pb.Configuration, 5));
};


/** @param {?proto.Configuration|undefined} value */
proto.AgentHelcarCommand.prototype.setConfiguration = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.AgentHelcarCommand.prototype.clearConfiguration = function() {
  this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentHelcarCommand.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MarkCarCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MarkCarCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.MarkCarCommand.displayName = 'proto.MarkCarCommand';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MarkCarCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.MarkCarCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MarkCarCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MarkCarCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    agent: (f = msg.getAgent()) && agent_pb.Agent.toObject(includeInstance, f),
    demandagentkey: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MarkCarCommand}
 */
proto.MarkCarCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MarkCarCommand;
  return proto.MarkCarCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MarkCarCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MarkCarCommand}
 */
proto.MarkCarCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new agent_pb.Agent;
      reader.readMessage(value,agent_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDemandagentkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MarkCarCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MarkCarCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MarkCarCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MarkCarCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      agent_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getDemandagentkey();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Agent agent = 1;
 * @return {?proto.Agent}
 */
proto.MarkCarCommand.prototype.getAgent = function() {
  return /** @type{?proto.Agent} */ (
    jspb.Message.getWrapperField(this, agent_pb.Agent, 1));
};


/** @param {?proto.Agent|undefined} value */
proto.MarkCarCommand.prototype.setAgent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.MarkCarCommand.prototype.clearAgent = function() {
  this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.MarkCarCommand.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 demandAgentkey = 2;
 * @return {number}
 */
proto.MarkCarCommand.prototype.getDemandagentkey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.MarkCarCommand.prototype.setDemandagentkey = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MoveCarCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MoveCarCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.MoveCarCommand.displayName = 'proto.MoveCarCommand';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MoveCarCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.MoveCarCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MoveCarCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MoveCarCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    agent: (f = msg.getAgent()) && agent_pb.Agent.toObject(includeInstance, f),
    key: jspb.Message.getFieldWithDefault(msg, 2, 0),
    frequency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    configuration: (f = msg.getConfiguration()) && configuration_pb.Configuration.toObject(includeInstance, f),
    date: (f = msg.getDate()) && date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MoveCarCommand}
 */
proto.MoveCarCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MoveCarCommand;
  return proto.MoveCarCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MoveCarCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MoveCarCommand}
 */
proto.MoveCarCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new agent_pb.Agent;
      reader.readMessage(value,agent_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {!proto.Date.Frequency} */ (reader.readEnum());
      msg.setFrequency(value);
      break;
    case 4:
      var value = new configuration_pb.Configuration;
      reader.readMessage(value,configuration_pb.Configuration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 5:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MoveCarCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MoveCarCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MoveCarCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MoveCarCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      agent_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      configuration_pb.Configuration.serializeBinaryToWriter
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional Agent agent = 1;
 * @return {?proto.Agent}
 */
proto.MoveCarCommand.prototype.getAgent = function() {
  return /** @type{?proto.Agent} */ (
    jspb.Message.getWrapperField(this, agent_pb.Agent, 1));
};


/** @param {?proto.Agent|undefined} value */
proto.MoveCarCommand.prototype.setAgent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.MoveCarCommand.prototype.clearAgent = function() {
  this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.MoveCarCommand.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 key = 2;
 * @return {number}
 */
proto.MoveCarCommand.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.MoveCarCommand.prototype.setKey = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Date.Frequency frequency = 3;
 * @return {!proto.Date.Frequency}
 */
proto.MoveCarCommand.prototype.getFrequency = function() {
  return /** @type {!proto.Date.Frequency} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {!proto.Date.Frequency} value */
proto.MoveCarCommand.prototype.setFrequency = function(value) {
  jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Configuration configuration = 4;
 * @return {?proto.Configuration}
 */
proto.MoveCarCommand.prototype.getConfiguration = function() {
  return /** @type{?proto.Configuration} */ (
    jspb.Message.getWrapperField(this, configuration_pb.Configuration, 4));
};


/** @param {?proto.Configuration|undefined} value */
proto.MoveCarCommand.prototype.setConfiguration = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.MoveCarCommand.prototype.clearConfiguration = function() {
  this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.MoveCarCommand.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Date date = 5;
 * @return {?proto.Date}
 */
proto.MoveCarCommand.prototype.getDate = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 5));
};


/** @param {?proto.Date|undefined} value */
proto.MoveCarCommand.prototype.setDate = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.MoveCarCommand.prototype.clearDate = function() {
  this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.MoveCarCommand.prototype.hasDate = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateAgentCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateAgentCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.CreateAgentCommand.displayName = 'proto.CreateAgentCommand';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateAgentCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateAgentCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateAgentCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateAgentCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateAgentCommand}
 */
proto.CreateAgentCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateAgentCommand;
  return proto.CreateAgentCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateAgentCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateAgentCommand}
 */
proto.CreateAgentCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateAgentCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateAgentCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateAgentCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateAgentCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.CreateAgentCommand.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.CreateAgentCommand.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto);
