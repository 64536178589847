export const CHANGE_COLOR = "CHANGE_COLOR"
export const RESET_PALETTE = "RESET_PALETTE"

export const changeColor = (powertrain, color) => ({
    type: CHANGE_COLOR,
    powertrain,
    color
})

export const resetPalette = { type: RESET_PALETTE }

