import {Agent} from '../generated/agent_pb'
import {AgentsCarsParameters} from '../generated/agentsCarsParameters_pb'
import {Car} from '../generated/car_pb'
import {PowertrainsCarsParameters} from '../generated/powertrainsCarsParameters_pb'
import {EnergiesPowertrainsCarsParameters} from '../generated/energiesPowertrainsCarsParameters_pb'
import {EnergiesParameters} from '../generated/energiesParameters_pb';

const AgentType = Agent.AgentType
const CarType = Car.CarType
const Energy = EnergiesParameters.Energy
const Powertrain = Car.Powertrain

const AgentCarAttribute = AgentsCarsParameters.AgentCarAttribute
const PowertrainCarAttribute = PowertrainsCarsParameters.PowertrainsCarsAttribute
const EnergyPowertrainCarAttribute = EnergiesPowertrainsCarsParameters.EnergiesPowertrainsCarsAttribute

export const agentTypeStream = Object.keys(AgentType).filter(
    agent => agent !== "UNKNOWN" && agent !== "UNRECOGNIZE"
)

export const carTypeStream = Object.keys(CarType).filter(
    car => car !== "UNKNOWN_C" && car !== "UNRECOGNIZE"
)

export const energyStream = Object.keys(Energy).filter(
    energy => energy !== "UNKNOWN" && energy !== "UNRECOGNIZE"
)

export const powertrainStream = Object.keys(Powertrain).filter(
    powertrain => powertrain !== "UNKNOWN_P" && powertrain !== "UNRECOGNIZE"
)


export const agentCarAttributeStream = Object.keys(AgentCarAttribute).filter(
    agentCarAttribute => agentCarAttribute !== "UNKNOWN" && agentCarAttribute !== "UNRECOGNIZE"
)
export const powertrainCarAttributeStream = Object.keys(PowertrainCarAttribute).filter(
    powertrainCarAttribute => powertrainCarAttribute !== "UNKNOWN" && powertrainCarAttribute !== "UNRECOGNIZE"
)

export const energyPowertrainCarAttributeStream = Object.keys(EnergyPowertrainCarAttribute).filter(
    energyPowertrainCarAttribute => energyPowertrainCarAttribute !== "UNKNOWN" && energyPowertrainCarAttribute !== "UNRECOGNIZE"
)