import React, { useState, useEffect } from 'react'
import {useHistory} from "react-router"

import notify from '../components/VaadinNotificationUtil'
import ask from '../components/VaadinModal'

import {EuiInMemoryTable, EuiLink, EuiButton} from "@elastic/eui"

function deleteSimulation(id){
    fetch(`https://hermes.list.lu/lcia/delete_configuration?simulationid=${id}`)
    .then(response => {
        console.info(`Simulation ${id} deleted`)
        notify(`👻 Simulation ${id} deleted!`,  "It will disapear on next refresh.")
    })
    .catch(err => {
        console.error(err)
        notify(`Error ${err.code}:`,  `${err.message}`)
    })
}

function fetchSimulationsData(setItems, setIsLoading){
    // first fetchAllSimulationName
    setIsLoading(true)
    fetch(`https://hermes.list.lu/lcia/list`)
        .then(response => response.json())
        .then(simulations => {
            // wait for all simulation are here
            Promise.all(
                // fetch all simulation
                simulations.map(
                    id => fetch(`https://hermes.list.lu/lcia/configuration?simulationid=${id}`)
                            .then(response => response.json())
                            .catch(err => {
                                console.error(err)
                            })
                )
            )
            .then(
                simulations => {
                    setItems(
                        simulations.map(
                            s => s[0] 
                        )
                    )
                    setIsLoading(false)
                }
            )
            .catch(err => {
                console.error(err)
                setIsLoading(false)
            })
        })
        .catch(err => {
            console.error(err)
            console.info('Retrying fetching simulation simulations in 5 second')
            setTimeout(
                () => fetchSimulationsData(setItems),
                5000
            )
        })
}

function ConfigurationList(props) {
    const [items, setItems] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()

    const sorting = {
        sort: {
          field: 'time',
          direction: 'desc',
        },
      };

    useEffect(
        () => fetchSimulationsData(setItems, setIsLoading),
        []
    )

    const columns = [
        {
            field: 'configId',
            name: 'name',
            truncateText: true,
            sortable: true
        },
        {
            field: 'description',
            name: 'Description',
            truncateText: true,
        },
        {
            field: 'time',
            name: 'Launched at',
            dataType: 'date',
            sortable: true
        },
        {
            field: 'configId',
            name: 'Impact',
            render: (id) => (
                <EuiLink href={`/lca/${id}`} onClick={
                    e => {
                        e.preventDefault()
                        history.push(`/lca/${id}`)
                    }
                }>
                    <iron-icon slot="prefix" icon="vaadin:sun-down"></iron-icon>
                    See impacts
                </EuiLink>
            )
        },
        {
            field: 'configId',
            name: 'Delete',
            render: (id) => (
                <EuiButton
                    fill
                    color="danger"
                    onClick={
                        e => ask(`Do you really want to delete simulation ${id}?`,
                                () => {
                                    console.log(`Deleting ${id}`)
                                    deleteSimulation(id)
                                },
                                "error"
                            )
                    }
                >
                    <iron-icon slot="prefix" icon="vaadin:trash"></iron-icon>
                    Delete
            </EuiButton>
            )
        },
    ]

    const search = {
    }
    
    return (
        <EuiInMemoryTable
            loading={isLoading}
            items={items}
            columns={columns}
            sorting={sorting}
            search={search}
            pagination={props.pagination ? props.pagination : false }
      />
    )
}

export default ConfigurationList