import styled, {css} from 'styled-components'

const Bouboul = styled.div`
    border-radius: 50px;
    height: 6px;
    width: 64px;

    ${props => css`
        background-color: ${props.color}
    `}
`

export default Bouboul;