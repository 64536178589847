import React from 'react';
import ReactDOM from 'react-dom';
import Layout from './components/Layout';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import reducer from './reducers'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware, compose} from 'redux'
//import thunk from 'redux-thunk'

import {version} from "../package.json"
import './vaadin-imports'

import '@elastic/eui/dist/eui_theme_light.css';
//import '@elastic/eui/dist/eui_theme_amsterdam_light.css';
//import "./theme/theme.scss"

console.info(`Hermes UI v${version}`)

const middleware= [
    //thunk
]


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(...middleware)
));


ReactDOM.render( <Provider store={store} >
        <BrowserRouter>
            <Layout />
        </BrowserRouter>
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
