import React from 'react'

import Page from '../components/Page'
import ConfigurationList from './ConfigurationList'
import { EuiFlexGroup } from '@elastic/eui'
import { EuiFlexItem } from '@elastic/eui'
import { EuiHorizontalRule } from '@elastic/eui'
import { EuiText } from '@elastic/eui'
import { EuiLink } from '@elastic/eui'

// FIXME: DUPLICATE CODE IN NAVBAR
import {useHistory} from "react-router"
function goTo(event, history, path) {
    event.preventDefault();
    history.push(path);
} 

function getLinkProps(href, history) {
    return {
        href,
        onClick: event => goTo(event, history, href)
    }
}

export default function()  {
    const history = useHistory();
    return (
        <Page title="Welcome to Hermes web app">
            
            <EuiText>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <p>
                        This website is split in two parts:
                    </p>
                    <ul>
                        <li> In the first one you can use a scenario file to <EuiLink {...getLinkProps("/choiceAnalyzer", history)}>test agent choice</EuiLink> or <EuiLink {...getLinkProps("/contribution", history)}>make some contribution analysis</EuiLink>.</li>
                        <li> In the second one you can <EuiLink {...getLinkProps("/simulations", history)}>see the list of precalculated simulation</EuiLink> and <EuiLink {...getLinkProps("/lca", history)}> see the calculated impact</EuiLink>.</li>
                    </ul>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule/>

            <h2>Part one</h2>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <p>
                        List of availlable files for part one:
                    </p>
                </EuiFlexItem>
                
                <EuiFlexItem>
                    <ul>
                        <li><EuiLink href="/SCENARIOS MASTER/BAU_v4.2.xlsx">BAU v4.2</EuiLink></li>
                        <li><EuiLink href="/SCENARIOS MASTER/Pessimistic_v4.2.xlsx">Pessimist v4.2</EuiLink></li>
                        <li><EuiLink href="/SCENARIOS MASTER/ProH2_all_v4.2.xlsx">Pro H2 v4.2</EuiLink></li>
                    </ul>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule/>

            <h2>Part two</h2>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <p>
                        In this part you can see some already made simulations and life cycle assessment impact.
                    </p>
                </EuiFlexItem>
                
                <EuiFlexItem>
                    <ConfigurationList pagination={{
                        pageSizeOptions: [3, 5],
                    }}/>
                </EuiFlexItem>
            </EuiFlexGroup>

            </EuiText>

        </Page>
    )
}