const SET_CURRENT_CONFIGURATION = "SET_CURRENT_CONFIGURATION"

export const setConfigurationAction = (configuration) => ({
    type: SET_CURRENT_CONFIGURATION,
    value: configuration
})

export const configurationLoaderReducer = (state=null, action ) => {
    switch (action.type) {
        case SET_CURRENT_CONFIGURATION:
            return action.value
        default:
            return state;
    }
}