import React from "react"

export default class VaadinSelect extends React.Component {
    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
    }

    componentDidMount() {
        const props = this.props
        this.selectRef.current.renderer = function(root) {
            if (root.firstChild) {
                return;
            }
            // create the <vaadin-list-box>
            const listBox = window.document.createElement('vaadin-list-box');

            // appends <vaadin-item> elements
            props.items.forEach(function(name) {
                const item = window.document.createElement('vaadin-item');
                item.textContent = name;
                listBox.appendChild(item);
                item.onclick = props.onClickItem
            });
            // update the content
            root.appendChild(listBox);
        }
    }

    render() {
        return (
            <vaadin-select
                ref={this.selectRef}
                label={this.props.label}
                value={this.props.currentValue}
            >
                {this.props.children}
            </vaadin-select>
        )
    }
}