import React from 'react'
import XLSX from 'xlsx'
import {EuiButton} from '@elastic/eui'
import {saveAs} from "file-saver"

function downloadData(impactsData, fileName){
    const workbook = XLSX.utils.book_new()
    const worksheetName = "LCIA"
    const header = [
        'impact_category',
        'method',
        'carType',
        'powertrain',
        'score',
        'time',
        'unit',
    ]
    const worksheetData = []
    worksheetData.push(header)

    impactsData.forEach(impact => {
        impact['aggregation'].toArray().forEach(
            i => worksheetData.push(i)
        )
    });
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(worksheetData), worksheetName);
    
    const wopts = { bookType:'xlsx', bookSST:false, type:'array' };
    const wbout = XLSX.write(workbook, wopts);
    saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${fileName}.xlsx`);
}

export default function DownloadDataButton({impacts, fileName}){
    return <EuiButton onClick={e => downloadData(impacts, fileName)}>
        <iron-icon icon="vaadin:download"></iron-icon>
        Download raw data
    </EuiButton>
}