import React, {useState, useMemo} from 'react'
import {connect} from "react-redux"
import ResponsiveXYFrame from "semiotic/lib/ResponsiveXYFrame"
import { scaleTime } from 'd3-scale'
import superSort from "./carSort"
import {
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiFlexGroup,
  EuiComboBox,
  EuiEmptyPrompt,
  EuiTitle } from "@elastic/eui"

function mapStateToProps(state) {
  return ({
      colorPalette: state.colorPalette
  })
}

// fonce clair
// bom, 18m 12, vu, vp
// each type of energy has a color
const carTypeGreyScaleMap = {
  'BOM': palette => palette[4],
  'BUS_18': palette => palette[3],
  'BUS_12': palette => palette[2],
  'VU': palette => palette[1],
  'VP': palette => palette[0],
}

function generateColorMap(colorPalette){
  const colorMap = {}
  const defaulColor = "CNG"
  const defaulOpacity = "BUS_18"

  colorPalette.forEach(
      cp => {
          colorMap[cp.powertrain] = carTypeGreyScaleMap[defaulOpacity](cp.colors)
          Object.keys(carTypeGreyScaleMap).forEach(
              k => {
                  const key =  k+cp.powertrain
                  colorMap[key] = carTypeGreyScaleMap[k](cp.colors)
              }
          )
      }
  )

  Object.keys(carTypeGreyScaleMap).forEach(
      k => {
          const key =  k
          colorMap[key] = carTypeGreyScaleMap[k](colorPalette.filter(
              cp => cp.powertrain === defaulColor
          )[0].colors)
      }
  )

  return colorMap
}

function getLineStyle(d, colorPalette) {
  let {energy, carType} = d
  energy = energy ? energy : "" // default color
  carType = carType ? carType : "" // default opacity
  const color = colorPalette[carType+energy]
  
  return {
      stroke: color,
      strokeWidth: 1,
      fill: color,
      fillOpacity: 0.9
  }
}

function getPointStyle(d, colorPalette) {
  let {energy, carType} = d
  energy = energy ? energy : "" // default color
  carType = carType ? carType : "" // default opacity
  const color = colorPalette[carType+energy]
  return {
      fill: color,
      r:3
  }
}

const aggregationModeMap = [
  {
      label:"None"
  },
  {
      label:"Powertrain"
  },
  {
      label:"Vehicles type"
  }
]


function formatData(data, aggregationMode) {
  return  getGroupeDataframe(data, aggregationMode)
      .aggregate(
          g => g
              .groupBy('time')
              .aggregate(subgroup => subgroup.stat.sum('sum'))
              .rename('aggregation', 'sum')
              .toCollection()
      )
      .withColumn('id', r => r.carType+r.energy)
      .toCollection()
}

function getGroupeDataframe(df, mode){
  if(mode === aggregationModeMap[1]){
      return df.groupBy('energy')
  }
  else if(mode === aggregationModeMap[2]){
      return df.groupBy('carType')
  }
  else {
      return df.groupBy('energy', 'carType')
  }
}

function KmCharts({data, colorPalette}) {

  const endDiesel = new Date("2024-01-01T00:00:00Z").valueOf()
  const endPetrol = new Date("2030-01-01T00:00:00Z").valueOf()
  const endDieselDate = new Date(endDiesel)
  const endDieselString = `${endDieselDate.getDay()}/${endDieselDate.getMonth() + 1}/${endDieselDate.getFullYear()}`
  const endPetrolDate = new Date(endPetrol)
  const endPetrolString = `${endPetrolDate.getDay()}/${endPetrolDate.getMonth() + 1}/${endPetrolDate.getFullYear()}`
  
  const colorMap = generateColorMap(colorPalette)
  
  const [aggregationMode, setAggregationMode] = useState([aggregationModeMap[0]])
  const formattedData = useMemo(
      () => formatData(data, aggregationMode[0]),
      [data, aggregationMode]
  )
  return <div>
    <EuiForm>
      <EuiFlexGroup>
          <EuiFlexItem>
              <EuiFormRow label="Aggregation mode">
                  <EuiComboBox
                      singleSelection={{ asPlainText: true }}
                      isClearable={false}
                      options={aggregationModeMap}
                      selectedOptions={aggregationMode}
                      onChange={setAggregationMode}
                  />
              </EuiFormRow>
          </EuiFlexItem>
      </EuiFlexGroup>
  </EuiForm>
    <ResponsiveXYFrame
      lines={formattedData}
      lineDataAccessor="aggregation"
      canvasLines={true}
      canvasAreas={true}
      canvasPoints={true}
      lineType="line"
      title={ "Demands in km"}
      //canvasLines={true}
      //canvasAreas={true}
      //canvasPoints={true}
      /* --- Size --- */
      size={[0, 650]}
      margin={{ left: 75, top: 90, bottom: 75, right: 250 }}
      responsiveWidth={true}
      /* --- Process --- */
      xAccessor={d => new Date(d.time)}
      yAccessor="sum"
      showLinePoints={true}
      pointStyle={d => getPointStyle(d.parentLine, colorMap)}
      lineStyle={d => getLineStyle(d, colorMap)}
      //yExtent= {[0]}
      //xAccessor= "week"
      //yAccessor= "theaterCount"
      axes= {[
        {
            orient: "left",
            tickFormat: d => Number(d).toExponential(),
            label: {
                name: "km"
            }
        },
        {
            orient: "bottom",
            label: {
                name: "Time"
            },
            ticks: 5,
            tickFormat: d => new Date(d).getMonth() + 1 + "/" + new Date(d).getFullYear(),
        }
      ]}
      xScaleType={scaleTime()}
      matte= {true}
      lineIDAccessor={d => d.energy + d.carType}
      hoverAnnotation={[
        {
            type: "highlight",
            style: { strokeWidth: 3, r:5 }
        },
        { type: "frame-hover" },
      ]}
      tooltipContent={ d => (
          <div className="tooltip-content">
              {d.parentLine.carType ? <p>Vehicle: {d.parentLine.carType}</p> : null}
              {d.parentLine.energy ? <p>Powertrain: {d.parentLine.energy}</p> : null}
              <p>Date: {d.time.toString()}</p>
              <p>Value: {Number(d.sum).toExponential(3)}</p>
          </div>
      )}
      annotations={[
        {
            type: "x",
            time: endDieselString,
            note: {
                label: "End of diesel policy",
                align: "middle",
                lineType: null,
                wrap: 100
                },
            color: "#69707D",
            dy: -10,
            dx: 0,
            connector: { end: "none" }
        },
        {
            type: "x",
            time: endPetrolString,
            note: {
                label: "End of petrol policy",
                align: "middle",
                lineType: null,
                wrap: 100
                },
            color: "#69707D",
            dy: -10,
            dx: 0,
            connector: { end: "none" }
        },
    ]}
    foregroundGraphics={[
        <g  style={{
            transform:"translate(calc(100% - 210px), 100px)"
        }} key="legend">
            {
                formattedData
                    .sort((a,b) => -1 * superSort(a,b))
                    .map( (d,i) => {
                        let {energy, carType} = d
                        energy = energy ? energy : "" // default color
                        carType = carType ? carType : "" // default opacity
                        const color = colorMap[carType+energy]
                        return (
                            <g key={i}>
                                <rect width="12" height="12" x="0" y={i*20} rx="2" fill={color}></rect>
                                <text y={i*20 + 10}  x="16">
                                    {`${energy} ${carType}`}
                                </text>
                            </g>
                        )
                    })
            }
        </g>
      ]}
  />
  </div>
}


export default connect(mapStateToProps, null)(KmCharts)