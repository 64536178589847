/* eslint-disable */ /**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.Car', null, global);
goog.exportSymbol('proto.Car.CarType', null, global);
goog.exportSymbol('proto.Car.Powertrain', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Car = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Car, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Car.displayName = 'proto.Car';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Car.prototype.toObject = function(opt_includeInstance) {
  return proto.Car.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Car} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Car.toObject = function(includeInstance, msg) {
  var f, obj = {
    powertrain: jspb.Message.getFieldWithDefault(msg, 1, 0),
    age: jspb.Message.getFieldWithDefault(msg, 2, 0),
    kmcumulated: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    shouldbechanged: jspb.Message.getFieldWithDefault(msg, 4, false),
    kmnoncumulated: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    yearofconstruction: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Car}
 */
proto.Car.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Car;
  return proto.Car.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Car} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Car}
 */
proto.Car.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Car.Powertrain} */ (reader.readEnum());
      msg.setPowertrain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAge(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setKmcumulated(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShouldbechanged(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setKmnoncumulated(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearofconstruction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Car.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Car.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Car} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Car.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPowertrain();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getKmcumulated();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getShouldbechanged();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getKmnoncumulated();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getYearofconstruction();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.Car.Powertrain = {
  UNKNOWN_P: 0,
  DIESEL: 1,
  PETROL: 2,
  H2: 3,
  ELECTRIC: 4,
  CNG: 5,
  HYBRID_DIESEL: 6,
  HYBRID_PETROL: 7,
  PLUG_IN_HYBRID_DIESEL: 8,
  PLUG_IN_HYBRID_PETROL: 9
};

/**
 * @enum {number}
 */
proto.Car.CarType = {
  UNKNOWN_C: 0,
  VP: 1,
  VU: 2,
  BUS_12: 3,
  BUS_18: 4,
  BOM: 5
};

/**
 * optional Powertrain powerTrain = 1;
 * @return {!proto.Car.Powertrain}
 */
proto.Car.prototype.getPowertrain = function() {
  return /** @type {!proto.Car.Powertrain} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.Car.Powertrain} value */
proto.Car.prototype.setPowertrain = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 age = 2;
 * @return {number}
 */
proto.Car.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.Car.prototype.setAge = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double kmCumulated = 3;
 * @return {number}
 */
proto.Car.prototype.getKmcumulated = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.Car.prototype.setKmcumulated = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool shouldBeChanged = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.Car.prototype.getShouldbechanged = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.Car.prototype.setShouldbechanged = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional double kmNonCumulated = 5;
 * @return {number}
 */
proto.Car.prototype.getKmnoncumulated = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.Car.prototype.setKmnoncumulated = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 yearOfConstruction = 6;
 * @return {number}
 */
proto.Car.prototype.getYearofconstruction = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.Car.prototype.setYearofconstruction = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


goog.object.extend(exports, proto);
