import React from 'react'
import {connect} from 'react-redux'
import {useHistory} from "react-router"

import {
        EuiNavDrawer,
        EuiNavDrawerGroup
} from "@elastic/eui"


const mapFeaturesToProps = state => ({
    features: state.features
})

// FIXME: DUPLICATE CODE IN HOME
function goTo(event, history, path) {
    event.preventDefault();
    history.push(path);
} 

function makeNavObject(feature, history) {
    return {
        ...feature,
        href:feature.route,
        label:feature.label,
        iconType:feature.icon,
        onClick: event => goTo(event, history, feature.route)
    }
}
const additionnalRoutes = [
    {
        name: "grafana",
        href:"/grafana",
        target:"_blank",
        label:"Grafana",
        iconType:"dashboardApp",
        isEnable: false,
        canBeSwitched: true
    }
]

const Navbar = React.forwardRef(
    ({features}, ref) => {
        // FIXME: DUPLICATE CODE IN HOME
        const history = useHistory();

        const featureRoutes = features.map(f => makeNavObject(f, history))

        const concatRoutes = featureRoutes.slice(0, 1)
            .concat(additionnalRoutes)
            .concat(featureRoutes.slice(1))

        const routes = concatRoutes.filter(f => f.isEnable && f.href !== "/")

        return (
            <EuiNavDrawer ref={ref}>
                <EuiNavDrawerGroup listItems={routes} />
            </EuiNavDrawer>
        )
    }
)
    

export default connect(mapFeaturesToProps, null, null, { forwardRef: true })(Navbar)