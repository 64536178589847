import React from 'react'
import styled, {css} from 'styled-components'

const FluxContainer = styled.div`
    height:12px;
    padding-top: 12px;
    padding-bottom: 12px;
`
const Flux = styled.div`
    height:12px;
    border-radius: 50px;
    position: relative;
    
    ${props => css`
        width: ${props.size}%;
        left:${props.x}%;
        bottom:${props.y}px;
        background-color: ${props.color};
    `}
    
`

export default function() {
    return (
        <FluxContainer>
            <Flux color="#69AF23" size="5"  x="5"   y="0"/>
            <Flux color="#BECD00" size="25" x="10"  y="12"/>
            <Flux color="#00AAFF" size="22" x="35"  y="24"/>
            <Flux color="#FFC300" size="20" x="57"  y="36"/>
            <Flux color="#E62D87" size="17" x="78"  y="48"/>
            <Flux color="#008737" size="3"  x="8"   y="60"/>
            <Flux color="#005FAA" size="5"  x="32"  y="72"/>
            <Flux color="#008737" size="4"  x="55"  y="84"/>
            <Flux color="#E10019" size="5"  x="76"  y="96"/>
            <Flux color="#552382" size="3"  x="88"  y="108"/>
        </FluxContainer>
    )
}