import React from "react"

import { connect } from 'react-redux'
import PowertrainTCOBarChart from './ChoiceResultAnalyzer/PowertrainTCOBarChart'
import PowertrainStatsBarChart from './ChoiceResultAnalyzer/PowertrainStatsBarChart'

import PowertrainStatsDataDownload from './ChoiceResultAnalyzer/PowertrainStatsDataDownload'

function mapStateToProps(state) {
    return ({
        colorPalette: state.colorPalette
    })
}


function ChoiceResultAnalyzer({helcarStatistics, colorPalette}) {
    const powertrainStatsList = helcarStatistics.getPowertrainstatsList()
    return (
        <>
            <PowertrainStatsDataDownload powertrainStatsList={powertrainStatsList}/>
            <PowertrainTCOBarChart
                powertrainStatsList={powertrainStatsList} colorPalette={colorPalette}
            />
            <PowertrainStatsBarChart
                powertrainStatsList={powertrainStatsList} colorPalette={colorPalette}
            />
            
        </>
    )
}

export default connect(mapStateToProps, null)(ChoiceResultAnalyzer)