export default function ask(text, callback, theme="") {
    const dialog = window.document.createElement('vaadin-dialog');

    //dialog.setAttributeNode(document.createAttribute("no-close-on-esc"))
    //dialog.setAttributeNode(document.createAttribute("no-close-on-outside-click"))
    
    dialog.opened = true;
    dialog.renderer = function(root, dialog) {
        // Check if there is a DOM generated with the previous renderer call to update its content instead of recreation
        if (root.firstElementChild) {
            return;
        }
        const div = window.document.createElement('div');
        div.textContent = text;

        const br = window.document.createElement('br');

        const okButton = window.document.createElement('vaadin-button');
        okButton.setAttribute('theme', `primary ${theme}`);
        okButton.textContent = 'Confirm';
        okButton.setAttribute('style', 'margin-right: 1em');
        okButton.addEventListener('click', function() {
            callback()
            dialog.opened = false;
            window.document.body.removeChild(dialog)
        });

        const cancelButton = window.document.createElement('vaadin-button');
        cancelButton.textContent = 'Cancel';
        cancelButton.setAttribute('theme', theme);
        cancelButton.addEventListener('click', function() {
            dialog.opened = false;
            window.document.body.removeChild(dialog)
        });

        root.appendChild(div);
        root.appendChild(br);
        root.appendChild(okButton);
        root.appendChild(cancelButton);
    };
    window.document.body.appendChild(dialog)
}