import React from 'react'
import ResponsiveOrdinalFrame from "semiotic/lib/ResponsiveOrdinalFrame"

import {getPowertrainFromIndex} from '../../utils/stringConverters'
import {powertrainStream} from "../../utils/enumStream"

function getColorFromPalette(palette, type) {
    return palette.filter(c => c.powertrain === type)[0].color
}

export default function PowertrainStatsBarChart({
    powertrainStatsList,
    colorPalette
}){
    // compute probability for each powertrain
    const sum = year => powertrainStatsList
        .filter(ps => ps.getYear() === year)
        .reduce(
            (a, b) => a + b.getScore(),
            0.0
        )
    
    const data = powertrainStatsList.map(
        (ps, i) => {
            const score = ps.getScore()
            const year = ps.getYear()
            const type = getPowertrainFromIndex(ps.getPowertrain())
        return {
                type: type,
                proba: score/sum(year),
                color: getColorFromPalette(colorPalette, type),
                year
            }
        })

    const frameProps = { 
        /* --- Data --- */
        data: data,

        /* --- Size --- */
        size: [400, 300],
        responsiveWidth: true,
        margin:{ left: 75, top: 75, bottom: 75, right: 75 },
    
        /* --- Layout --- */
        type: "bar",
    
        /* --- Process --- */
        oAccessor: "year",
        rAccessor: "proba",
    
        /* --- Customize --- */
        style: function(e){
            return {
                fill:e.color,
                stroke:"white",
                strokeOpacity:.5
            }
        },
        title: (
            <text textAnchor="middle" style={{
                fontSize:"1,2rem"
            }}>
                Choice probability over time:{" "}
                {
                    powertrainStream.map(
                    i =>  <tspan key={i} fill={getColorFromPalette(colorPalette, i)}>
                        {i} {" "}
                    </tspan>
                    
                )
                }
            </text>
        ),
        tooltipContent: d => <div className="tooltip-content">
            <p style={{color: getColorFromPalette(colorPalette, d.type)}}>{d.type}</p>
            <p> {d.proba}</p>
            <p> {d.year}</p>
        </div> ,
        pieceHoverAnnotation: true,
    
        /* --- Annotate --- */
        oLabel: true,
        axes: [
            { orient: "left", label: "Probability" },
        ]
    }

    return <ResponsiveOrdinalFrame {...frameProps}/>
}