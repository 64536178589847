/* eslint-disable */ /**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var agent_pb = require('./agent_pb.js');
goog.exportSymbol('proto.AgentTimeSeries', null, global);
goog.exportSymbol('proto.AgentTimeSeries.AgentTimeSeriesAttribute', null, global);
goog.exportSymbol('proto.AgentsParameters', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentTimeSeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentTimeSeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.AgentTimeSeries.displayName = 'proto.AgentTimeSeries';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentTimeSeries.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentTimeSeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentTimeSeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentTimeSeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    attribute: jspb.Message.getFieldWithDefault(msg, 1, 0),
    equationMap: (f = msg.getEquationMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentTimeSeries}
 */
proto.AgentTimeSeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentTimeSeries;
  return proto.AgentTimeSeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentTimeSeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentTimeSeries}
 */
proto.AgentTimeSeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.AgentTimeSeries.AgentTimeSeriesAttribute} */ (reader.readEnum());
      msg.setAttribute(value);
      break;
    case 2:
      var value = msg.getEquationMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readDouble);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentTimeSeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentTimeSeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentTimeSeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentTimeSeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttribute();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEquationMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeDouble);
  }
};


/**
 * @enum {number}
 */
proto.AgentTimeSeries.AgentTimeSeriesAttribute = {
  UNKNOWN: 0,
  BETA_PRICE: 1,
  BETA_RANGE: 2,
  BETA_GREEN: 3
};

/**
 * optional AgentTimeSeriesAttribute attribute = 1;
 * @return {!proto.AgentTimeSeries.AgentTimeSeriesAttribute}
 */
proto.AgentTimeSeries.prototype.getAttribute = function() {
  return /** @type {!proto.AgentTimeSeries.AgentTimeSeriesAttribute} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.AgentTimeSeries.AgentTimeSeriesAttribute} value */
proto.AgentTimeSeries.prototype.setAttribute = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * map<int32, double> equation = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.AgentTimeSeries.prototype.getEquationMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


proto.AgentTimeSeries.prototype.clearEquationMap = function() {
  this.getEquationMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentsParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AgentsParameters.repeatedFields_, null);
};
goog.inherits(proto.AgentsParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.AgentsParameters.displayName = 'proto.AgentsParameters';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AgentsParameters.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentsParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentsParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentsParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentsParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    agenttype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    number: jspb.Message.getFieldWithDefault(msg, 2, 0),
    minnumbercar: jspb.Message.getFieldWithDefault(msg, 3, 0),
    modenumbercar: jspb.Message.getFieldWithDefault(msg, 4, 0),
    maxnumbercar: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ceilold: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    ceilkm: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    ratiochange: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    monthlyfrequency: +jspb.Message.getFieldWithDefault(msg, 9, 0.0),
    timeseriesList: jspb.Message.toObjectList(msg.getTimeseriesList(),
    proto.AgentTimeSeries.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentsParameters}
 */
proto.AgentsParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentsParameters;
  return proto.AgentsParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentsParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentsParameters}
 */
proto.AgentsParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.Agent.AgentType} */ (reader.readEnum());
      msg.setAgenttype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinnumbercar(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModenumbercar(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxnumbercar(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeilold(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeilkm(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRatiochange(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMonthlyfrequency(value);
      break;
    case 10:
      var value = new proto.AgentTimeSeries;
      reader.readMessage(value,proto.AgentTimeSeries.deserializeBinaryFromReader);
      msg.addTimeseries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentsParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentsParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentsParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentsParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgenttype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMinnumbercar();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getModenumbercar();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMaxnumbercar();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCeilold();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCeilkm();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getRatiochange();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getMonthlyfrequency();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTimeseriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.AgentTimeSeries.serializeBinaryToWriter
    );
  }
};


/**
 * optional Agent.AgentType agentType = 1;
 * @return {!proto.Agent.AgentType}
 */
proto.AgentsParameters.prototype.getAgenttype = function() {
  return /** @type {!proto.Agent.AgentType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.Agent.AgentType} value */
proto.AgentsParameters.prototype.setAgenttype = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 number = 2;
 * @return {number}
 */
proto.AgentsParameters.prototype.getNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.AgentsParameters.prototype.setNumber = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 minNumberCar = 3;
 * @return {number}
 */
proto.AgentsParameters.prototype.getMinnumbercar = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.AgentsParameters.prototype.setMinnumbercar = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 modeNumberCar = 4;
 * @return {number}
 */
proto.AgentsParameters.prototype.getModenumbercar = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.AgentsParameters.prototype.setModenumbercar = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 maxNumberCar = 5;
 * @return {number}
 */
proto.AgentsParameters.prototype.getMaxnumbercar = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.AgentsParameters.prototype.setMaxnumbercar = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double ceilOld = 6;
 * @return {number}
 */
proto.AgentsParameters.prototype.getCeilold = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.AgentsParameters.prototype.setCeilold = function(value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double ceilKm = 7;
 * @return {number}
 */
proto.AgentsParameters.prototype.getCeilkm = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.AgentsParameters.prototype.setCeilkm = function(value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double ratioChange = 8;
 * @return {number}
 */
proto.AgentsParameters.prototype.getRatiochange = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.AgentsParameters.prototype.setRatiochange = function(value) {
  jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double monthlyFrequency = 9;
 * @return {number}
 */
proto.AgentsParameters.prototype.getMonthlyfrequency = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.AgentsParameters.prototype.setMonthlyfrequency = function(value) {
  jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * repeated AgentTimeSeries timeSeries = 10;
 * @return {!Array.<!proto.AgentTimeSeries>}
 */
proto.AgentsParameters.prototype.getTimeseriesList = function() {
  return /** @type{!Array.<!proto.AgentTimeSeries>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.AgentTimeSeries, 10));
};


/** @param {!Array.<!proto.AgentTimeSeries>} value */
proto.AgentsParameters.prototype.setTimeseriesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.AgentTimeSeries=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentTimeSeries}
 */
proto.AgentsParameters.prototype.addTimeseries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.AgentTimeSeries, opt_index);
};


proto.AgentsParameters.prototype.clearTimeseriesList = function() {
  this.setTimeseriesList([]);
};


goog.object.extend(exports, proto);
