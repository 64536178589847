import React from "react"

import { EuiButton } from "@elastic/eui";
import {saveAs} from "file-saver"
import XLSX from 'xlsx'
import {getPowertrainFromIndex} from '../../utils/stringConverters'

function mapListToDataframe(data) {
    return data.map(
        d => ({
            year: d.getYear(),
            powertrain: getPowertrainFromIndex(d.getPowertrain()),
            "score.green": d.getScoregreen(),
            "score.price": d.getScoreprice(),
            "score.range": d.getScorerange(),
            "scoreTotal": d.getScore(),
            "tco.decote": d.getTco().getDecote(),
            "tco.energyPriceByKm": d.getTco().getEnergypricebykm(),
            "tco.insurance": d.getTco().getInsurance(),
            "tco.maintenance": d.getTco().getMaintenance(),
            "tco.netCarCost": d.getTco().getNetcarcost(),
            "tco.subsidy": d.getTco().getSubsidy()
        })
    )
} 

function download(data) {
    const dataTable = mapListToDataframe(data)
    const workbook = XLSX.utils.book_new()
    const worksheetName = "choice output"
    const header = Object.keys(dataTable[0])
    const worksheetData = []
    worksheetData.push(header)

    dataTable.forEach(d => worksheetData.push(Object.values(d)) );
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.aoa_to_sheet(worksheetData), worksheetName);
    
    const wopts = { bookType:'xlsx', bookSST:false, type:'array' };
    const wbout = XLSX.write(workbook, wopts);
    saveAs(new Blob([wbout],{type:"application/octet-stream"}), "choiceOutput.xlsx");
}


export default function({
    powertrainStatsList
}) {
    return (
        <EuiButton onClick={e => download(powertrainStatsList)}>
            <iron-icon icon="vaadin:download"></iron-icon>
            Download choice data
        </EuiButton>
    )
}