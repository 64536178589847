export default function notify(title, text) {
    const notify = window.document.createElement('vaadin-notification');
    notify.renderer = function(root) {
        // Check if there is a content generated with the previous renderer call not to recreate it.
        if (root.firstElementChild) {
            return;
        }

        const container = window.document.createElement('div');
        const boldText = window.document.createElement('b');
        boldText.textContent = title;

        const br = window.document.createElement('br');
        const plainText = window.document.createTextNode(text);

        container.appendChild(boldText);
        container.appendChild(br);
        container.appendChild(plainText);

        root.appendChild(container);
    };

    window.document.body.appendChild(notify);

    notify.position = 'top-end';
    notify.duration = '4000';
    notify.opened = true;

    notify.addEventListener('opened-changed', function() {
        window.document.body.removeChild(notify);
    });
}