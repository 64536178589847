import React, {useState} from "react"

import CircularSankey from "./CircularSankey"
import { ParentSize } from '@vx/responsive';
import { sankeyJustify, sankeyLeft, sankeyRight, sankeyCenter } from 'd3-sankey-circular';
import VaadinSelect from "./VaadinSelect"

const layoutMap = {
    "justify": sankeyJustify,
    "left": sankeyLeft,
    "center": sankeyCenter,
    "right": sankeyRight
}


export default function({data}) {
    const [currentLayout, setCurrentLayout] = useState("justify")
    const [nodeWidth, setNodeWidth] = useState(15)
    function changeLayout(l) {
        setCurrentLayout(l)
    }

    if (data.length === 0){
        return <div style={{textAlign:"center"}}>First start sankey chart computation</div>
    }
    else {
        return (
            <div>
                <VaadinSelect
                    items={Object.keys(layoutMap)}
                    label="Alignement"
                    value={currentLayout}
                    onClickItem={ (i) => {
                        changeLayout(i.target.textContent)
                    }}
                />
                <label>Node width:</label>
                <input
                    type="range"
                    min={1}
                    max={50}
                    value={nodeWidth}
                    onChange={v => setNodeWidth(Number(v.target.value))}
                />
                <div
                    style={{
                        height:"85vh"
                    }}
                >
                    <ParentSize>
                        {
                            parent => (
                                <CircularSankey
                                    data={{
                                        nodes: data.nodes,
                                        links: data.edges.map(
                                            e => {
                                                if(e.impact > 0) {
                                                    return {
                                                        source: e.source_id,
                                                        target: e.target_id,
                                                        value: e.impact
                                                    }
                                                }
                                                else {
                                                    return {
                                                        source: e.target_id,
                                                        target: e.source_id,
                                                        value: -e.impact
                                                    }
                                                }
                                                
                                            }
                                            )
                                        }}
                                    margin={{
                                        top:0,
                                        right:200,
                                        left:0,
                                        bottom:0
                                    }}
                                    width={parent.width - 200}
                                    height={parent.height}
                                    nodeAlign={layoutMap[currentLayout]}
                                    nodeWidth={nodeWidth}
                                />
                        )}
                    </ParentSize>
                </div>
            </div>
        )
    }
}