import React from "react"
import {connect} from 'react-redux'
import { EuiSwitch } from "@elastic/eui";

import {switchFeature} from "../../../features/featureSwitch/actions"

function mapDispatchToProps(dispatch) {
    return ({
        switchFeature2: featureName => dispatch(switchFeature(featureName))
    })
}

function FeatureSwitch({feature, switchFeature2}){
    return <EuiSwitch
                label={feature.label}
                checked={feature.isEnable}
                onChange={() => switchFeature2(feature.name)}
            />
}

export default connect(null, mapDispatchToProps)(FeatureSwitch)