/* eslint-disable */ // package: 
// file: services.proto

var services_pb = require("./services_pb");
var configuration_pb = require("./configuration_pb");
var commands_pb = require("./commands_pb");
var events_pb = require("./events_pb");
var environment_pb = require("./environment_pb");
var debug_pb = require("./debug_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var InitialEnvironmentCreator = (function () {
  function InitialEnvironmentCreator() {}
  InitialEnvironmentCreator.serviceName = "InitialEnvironmentCreator";
  return InitialEnvironmentCreator;
}());

InitialEnvironmentCreator.createInitialEvents = {
  methodName: "createInitialEvents",
  service: InitialEnvironmentCreator,
  requestStream: false,
  responseStream: true,
  requestType: configuration_pb.Configuration,
  responseType: events_pb.Event
};

exports.InitialEnvironmentCreator = InitialEnvironmentCreator;

function InitialEnvironmentCreatorClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

InitialEnvironmentCreatorClient.prototype.createInitialEvents = function createInitialEvents(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(InitialEnvironmentCreator.createInitialEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.InitialEnvironmentCreatorClient = InitialEnvironmentCreatorClient;

var SimulationRunner = (function () {
  function SimulationRunner() {}
  SimulationRunner.serviceName = "SimulationRunner";
  return SimulationRunner;
}());

SimulationRunner.runSimulation = {
  methodName: "runSimulation",
  service: SimulationRunner,
  requestStream: false,
  responseStream: true,
  requestType: configuration_pb.Configuration,
  responseType: environment_pb.Environment
};

exports.SimulationRunner = SimulationRunner;

function SimulationRunnerClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SimulationRunnerClient.prototype.runSimulation = function runSimulation(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(SimulationRunner.runSimulation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.SimulationRunnerClient = SimulationRunnerClient;

var DateCommandHandler = (function () {
  function DateCommandHandler() {}
  DateCommandHandler.serviceName = "DateCommandHandler";
  return DateCommandHandler;
}());

DateCommandHandler.nextDate = {
  methodName: "nextDate",
  service: DateCommandHandler,
  requestStream: false,
  responseStream: true,
  requestType: commands_pb.AdvanceTimeCommand,
  responseType: events_pb.Event
};

exports.DateCommandHandler = DateCommandHandler;

function DateCommandHandlerClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DateCommandHandlerClient.prototype.nextDate = function nextDate(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(DateCommandHandler.nextDate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.DateCommandHandlerClient = DateCommandHandlerClient;

var AgentCommandHandler = (function () {
  function AgentCommandHandler() {}
  AgentCommandHandler.serviceName = "AgentCommandHandler";
  return AgentCommandHandler;
}());

AgentCommandHandler.generateAgent = {
  methodName: "generateAgent",
  service: AgentCommandHandler,
  requestStream: false,
  responseStream: true,
  requestType: commands_pb.CreateAgentCommand,
  responseType: events_pb.Event
};

AgentCommandHandler.applyBehavior = {
  methodName: "applyBehavior",
  service: AgentCommandHandler,
  requestStream: false,
  responseStream: true,
  requestType: commands_pb.AgentApplyBehaviorCommand,
  responseType: events_pb.Event
};

exports.AgentCommandHandler = AgentCommandHandler;

function AgentCommandHandlerClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AgentCommandHandlerClient.prototype.generateAgent = function generateAgent(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(AgentCommandHandler.generateAgent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

AgentCommandHandlerClient.prototype.applyBehavior = function applyBehavior(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(AgentCommandHandler.applyBehavior, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.AgentCommandHandlerClient = AgentCommandHandlerClient;

var HelcarCommandHandler = (function () {
  function HelcarCommandHandler() {}
  HelcarCommandHandler.serviceName = "HelcarCommandHandler";
  return HelcarCommandHandler;
}());

HelcarCommandHandler.getChoice = {
  methodName: "getChoice",
  service: HelcarCommandHandler,
  requestStream: false,
  responseStream: false,
  requestType: commands_pb.AgentHelcarCommand,
  responseType: events_pb.AgentHelcarEvent
};

HelcarCommandHandler.getStats = {
  methodName: "getStats",
  service: HelcarCommandHandler,
  requestStream: false,
  responseStream: false,
  requestType: debug_pb.DebugHelcarCommand,
  responseType: debug_pb.HelcarStatistics
};

exports.HelcarCommandHandler = HelcarCommandHandler;

function HelcarCommandHandlerClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

HelcarCommandHandlerClient.prototype.getChoice = function getChoice(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HelcarCommandHandler.getChoice, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

HelcarCommandHandlerClient.prototype.getStats = function getStats(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(HelcarCommandHandler.getStats, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.HelcarCommandHandlerClient = HelcarCommandHandlerClient;

var DatabaseSaverLauncher = (function () {
  function DatabaseSaverLauncher() {}
  DatabaseSaverLauncher.serviceName = "DatabaseSaverLauncher";
  return DatabaseSaverLauncher;
}());

DatabaseSaverLauncher.runSimulation = {
  methodName: "runSimulation",
  service: DatabaseSaverLauncher,
  requestStream: false,
  responseStream: false,
  requestType: configuration_pb.Configuration,
  responseType: services_pb.Simulation
};

exports.DatabaseSaverLauncher = DatabaseSaverLauncher;

function DatabaseSaverLauncherClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

DatabaseSaverLauncherClient.prototype.runSimulation = function runSimulation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(DatabaseSaverLauncher.runSimulation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.DatabaseSaverLauncherClient = DatabaseSaverLauncherClient;
