import React, {useState, useRef} from 'react'

import Page from '../components/Page'
import {connect} from 'react-redux'
import HermesUploadConverter from '../components/HermesUploadConverter';
import ConfigurationInspector from '../components/ConfigurationInspector';
import SubmitButton from '../components/ConfigurationForm/SubmitButton';

function ConfigurationForm(props) {
    const [buttonEnable, setButtonEnable] = useState(false)
    const [configId, setConfigId] = useState(null)
    function disableButton(){
        setButtonEnable(false)
    }
    function enableButton(){
        setButtonEnable(true)
    }

    const descriptionRef = useRef(null)


    return (
        <Page title="Excel scenario loader">
            <HermesUploadConverter onUpload={enableButton} />
            
            {
                (props.configuration === null || props.configuration === undefined)? null : (
                    <>
                        <vaadin-form-layout>
                            <vaadin-text-area ref={descriptionRef} label="Write a description" placeholder="Add an explanation"></vaadin-text-area>
                            <div className="text-center">
                                <SubmitButton
                                    descriptionRef={descriptionRef}
                                    enable={buttonEnable}
                                    configId={configId}
                                    disableButton={disableButton}
                                    setConfigId={setConfigId}
                                    configuration={props.configuration}
                                />
                            </div>
                        </vaadin-form-layout>
                {/*{props.inspectorFeature.isEnable?  <ConfigurationInspector configuration={props.configuration}/> : null} */}
                    </>
                )
            }
        </Page>
    )
}

function mapStateToProps(state) {
    return {
        configuration: state.configuration,
        inspectorFeature: state.features.filter(f => f.name === 'configurationViewer')[0]
    }
}

export default connect(mapStateToProps, null)(ConfigurationForm);