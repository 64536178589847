import React, {useState, useEffect} from "react"

import { hlciApiUrl } from "../client/urls";

function fetchSimulation(setSimulation){
    fetch(`${hlciApiUrl}/list`)
        .then(response => response.json())
        .then(results => {
            setSimulation(results)
        })
        .catch(err => {
            console.error(err)
            console.info('Retrying fetch simulations in 5 second')
            setTimeout(
                () => fetchSimulation(setSimulation),
                5000
            )
        })
}
export default function simulationListProvider(WrappedComponent) {
    return (props) => {
        const [simulationList, setSimulationList] = useState(null)

        useEffect(
            () => {
                fetchSimulation(setSimulationList)
            },
            []
        )
        if(simulationList === null){
            return (<div>Listing simulation ...</div>)
        }
        return <WrappedComponent {...props} simulationList={simulationList}/>
    }
}

