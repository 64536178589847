import React, {useState} from "react"

import {EuiComboBox, EuiRange} from "@elastic/eui"
import {ResponsiveBar} from "@nivo/bar"
import { EuiFormRow } from "@elastic/eui"


function middleMan(f, d) {
    f(d)
    return d
}

const look = (d)  => middleMan(console.log, d)
const unspecifiedLocation = (l) => {
    console.log(l)
    return "others"
}

function formatData(data, isTechno, isPercent, isAggregatedByCountry){
    return data.map(
            d => {
                const contributions = isTechno ? d.technocontributions : d.biocontributions
                const formattedProcessName = d.process.split("{FR} | HERMES, U")[0].trim()
                return ({
                    process: formattedProcessName,
                    responsibles: contributions.map(t => t.responsible === d.process ? "direct" : isAggregatedByCountry ? t.location === "" ? unspecifiedLocation(t.responsible) : t.location : t.responsible),
                    contributions: contributions.map(
                            t => ({
                                responsible: t.responsible === d.process ? "direct" : isAggregatedByCountry ? t.location === "" ? unspecifiedLocation(t.responsible) : t.location : t.responsible,
                                value: isPercent ? (t.score / d.total_score) : t.score

                            })
                        ).reduce(
                            (a,b) => {
                                if(a.hasOwnProperty(b.responsible)) {
                                    a[b.responsible] = b.value + a[b.responsible]
                                    return a
                                }
                                else {
                                    a[b.responsible] = b.value
                                    return a
                                }
                            },
                            {}
                        ),
                    total:d.total_score,
                })
            }
        )
}


export default function({data}) {

    const unit = data ? data[0] ? data[0].unit ? data[0].unit : "" : "" : ""

    const options = [
        {label:"Transport, bus 12m"},
        {label:"Transport, bus 18m"},
        {label:"Transport, garbage truck"},
        {label:"Transport, light commercial"},
        {label:"Transport, passenger car"},
    ]
    const defaultOptions = [
        {label: "Transport, light commercial"}
    ]
    const [selectedOptions, setOptions] = useState(defaultOptions)

    const individualOptions = [
        {label:"Transport, light commercial vehicle, petrol"},
        {label:"Transport, light commercial vehicle, electric"},
        {label:"Transport, passenger car, medium size, CNG"},
        {label:"Transport, light commercial vehicle, hydrogen, hybrid"},
        {label:"Transport, passenger car, petrol"},
        {label:"Transport, light commercial vehicle, diesel"},
        {label:"Transport, bus 18m, diesel"},
        {label:"Transport, passenger car, diesel"},
        {label:"Transport, bus 12m, electric"},
        {label:"Transport, bus 18m, hydrogen, full H2"},
        {label:"Transport, passenger car, medium size, hybrid petrol"},
        {label:"Transport, light commercial vehicle, CNG"},
        {label:"Transport, passenger car, large size, electric"},
        {label:"Transport, bus 18m, electric"},
        {label:"Transport, garbage truck, electric"},
        {label:"Transport, bus 18m, CNG"},
        {label:"Transport, bus 12m, CNG"},
        {label:"Transport, bus 12m, diesel"},
        {label:"Transport, bus 12m, hybrid diesel"},
        {label:"Transport, garbage truck, CNG"},
        {label:"Transport, garbage truck, diesel"},
        {label:"Transport, bus 12m, hydrogen, full H2"},
        {label:"Transport, passenger car, medium size, plug-in hybrid petrol"},
        {label:"Transport, garbage truck, hydrogen, full H2"},
        {label:"Transport, passenger car, full H2"},
        {label:"Transport, bus 18m, hybrid diesel"},
    ].sort( (a,b) => a.label.localeCompare(b.label))

    const defaultIndividualOptions = []
    const [selectedIndividualOptions, setIndividualOptions] = useState(defaultIndividualOptions)
    const [isTechno, setIsTechno] = useState(false)
    const [isPercent, setIsPercent] = useState(false)
    const [isAggregatedByCountry, setIsAggregatedByCountry] = useState(false)
    const [isHorizontal, setIsHorizontal] = useState(true)
    const [isRotateVehicleName, setIsRotateVehicleName] = useState(false)

    function toogleTechno() {
        setIsTechno(!isTechno)
        if (isAggregatedByCountry) {
            setIsAggregatedByCountry(false)
        }
    }
    function tooglePercent() {
        setIsPercent(!isPercent)
    }
    function toogleAggregatedByCountry() {
        setIsAggregatedByCountry(!isAggregatedByCountry)
        if (!isTechno) {
            setIsTechno(true)
        }
    }

    function toogleHorizontal() {
        setIsHorizontal(!isHorizontal)
    }
    function toogleRotateVehicleName() {
        setIsRotateVehicleName(!isRotateVehicleName)
    }

    const [labelSkipWidth, setLabelSkipWidth] = useState(107)
    const [labelSkipHeight, setLabelSkipHeight] = useState(25)
    
    const defaultMarginLeft = isHorizontal ? isRotateVehicleName ? 200 : 250 : 50
    const [marginLeft, setMarginLeft] = useState(defaultMarginLeft)

    const formatedData =  formatData(
        data,
        isTechno,
        isPercent,
        isAggregatedByCountry
    )
    const headers = [...new Set(formatedData.map(d => d.responsibles).flat())]
    
    const values = formatedData
        .filter(d => selectedOptions.concat(selectedIndividualOptions)
                .map( o => d.process.includes(o.label))
                .reduce((a,b) => a || b, false)
        )
        .map(d => ({
        id: d.process,
        ...headers.map( h => ({[h]:0})).reduce( (a,b) => Object.assign(a,b)),
        ...d.contributions,
        total: d.total,
        unit: d.unit
        
    }))

    const valueAxis = {format: v => isPercent ? `${Math.abs(v*100)}%` : `${Number(v).toExponential(2)} `}
    const sumAxis = {
        format: v => {
            const value = values.find( d => d.id === v)
            const total =  value ? value.total.toExponential(2) : 0
            return `${total} ${unit}`
        }
    }
    
    const legendAxis={
        tickRotation: isRotateVehicleName ? isHorizontal ? -45 : -90 : 0
    }
    const axisBottom = isHorizontal ? {} : legendAxis
    const axisRight = isHorizontal ? sumAxis : null
    const axisTop = !isHorizontal ?  sumAxis : null
    const axisLeft = isHorizontal ? legendAxis : valueAxis
    const properties = {
        layout: isHorizontal ? "horizontal" : "vertical",
        groupMode: "stacked",
        colors:{ scheme: 'category10' },
        margin:{
            top: 50,
            right: isHorizontal ? 150 : 20,
            bottom: !isHorizontal && isRotateVehicleName ? 250 : 40,
            left: marginLeft,
        },

        labelTextColor: 'inherit:darker(1.4)',
        labelSkipWidth,
        labelSkipHeight,
        label: d => `${d.id}: ${ isPercent ? `${(d.value*100).toFixed(2)}%` : d.value.toExponential(2) }`,

        axisRight,
        axisBottom ,
        axisLeft,
        axisTop,
        enableGridX : isHorizontal ? true: false,
        enableGridY: !isHorizontal ? true : false
    }

    return data.length === 0 ? <div style={{textAlign:"center"}}>First start bar chart computation</div> : <div>
        {
            isAggregatedByCountry ? <vaadin-checkbox checked={isAggregatedByCountry} onClick={toogleTechno} >
                Aggregate by countries
            </vaadin-checkbox> :
            <vaadin-checkbox onClick={toogleAggregatedByCountry} >
                Aggregate by countries
            </vaadin-checkbox>
        }
        {
            isTechno ? <vaadin-checkbox checked={isTechno} onClick={toogleTechno} >
                Use technosphere instead of biosphere
            </vaadin-checkbox> :
            <vaadin-checkbox onClick={toogleTechno} >
                Use technosphere instead of biosphere
            </vaadin-checkbox>
        }
        {
            isPercent ? <vaadin-checkbox checked={isPercent} onClick={tooglePercent} >
                Use percent instead of absolute value
            </vaadin-checkbox> :
            <vaadin-checkbox onClick={tooglePercent} >
                Use percent instead of absolute value
            </vaadin-checkbox>
        }
        {
            isHorizontal ? <vaadin-checkbox checked={isHorizontal} onClick={toogleHorizontal} >
                Use horizontal bar ?
            </vaadin-checkbox> :
            <vaadin-checkbox onClick={toogleHorizontal} >
                Use horizontal bar ?
            </vaadin-checkbox>
        }
        {
            isRotateVehicleName ? <vaadin-checkbox checked={isRotateVehicleName} onClick={toogleRotateVehicleName} >
                Use rotated vehicle name ?
            </vaadin-checkbox> :
            <vaadin-checkbox onClick={toogleRotateVehicleName} >
                Use rotated vehicle name ?
            </vaadin-checkbox>
        }
        
        <EuiComboBox
            placeholder="Select collections of vehicules"
            options={options}
            selectedOptions={selectedOptions}
            onChange={newOptions => setOptions(newOptions)}
            isClearable={true}
        />
        <EuiComboBox
            placeholder="AND/OR select individuals vehicles"
            options={individualOptions}
            selectedOptions={selectedIndividualOptions}
            onChange={newOptions => setIndividualOptions(newOptions)}
            isClearable={true}
        />
        <EuiFormRow label="Minimum width for label to show (in px)">

            <EuiRange
                value={labelSkipWidth}
                onChange={ e => setLabelSkipWidth(e.target.value)}
                max={250}
                showInput
            />
        </EuiFormRow>
        
        <EuiFormRow label="Minimum height for label to show (in px)">
            <EuiRange
                value={labelSkipHeight}
                onChange={ e => setLabelSkipHeight(e.target.value)}
                max={250}
                showInput
            />
        </EuiFormRow>

        <EuiFormRow label="Margin Left (in px): Space to display name on the left of the chart">
            <EuiRange
                value={marginLeft}
                onChange={ e => setMarginLeft(e.target.value)}
                max={500}
                min={defaultMarginLeft}
                showInput
            />
        </EuiFormRow>
            <div style={{
                display:"flex",
                height:"90vh",
            }}>

                <ResponsiveBar
                    {...properties}
                    keys={headers}
                    data={values}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'inherit:lighter(1.4)',
                            size: 4,
                            padding: 1,
                            stagger: true
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'inherit:lighter(1.4)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                        }
                    ]}
                    fill={[
                        {
                            match: {
                                id: 'others'
                            },
                            id: 'lines'
                        },
                        
                    ]}
                />
        </div>
    </div>
}