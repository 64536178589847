/* eslint-disable */ /**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var agent_pb = require('./agent_pb.js');
var agentsCarsParameters_pb = require('./agentsCarsParameters_pb.js');
var car_pb = require('./car_pb.js');
var date_pb = require('./date_pb.js');
var energiesParameters_pb = require('./energiesParameters_pb.js');
var powertrainsCarsParameters_pb = require('./powertrainsCarsParameters_pb.js');
var powertrainsParameters_pb = require('./powertrainsParameters_pb.js');
var energiesPowertrainsCarsParameters_pb = require('./energiesPowertrainsCarsParameters_pb.js');
var agentsParameters_pb = require('./agentsParameters_pb.js');
var scenario_pb = require('./scenario_pb.js');
var mix_pb = require('./mix_pb.js');
goog.exportSymbol('proto.Configuration', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Configuration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Configuration.repeatedFields_, null);
};
goog.inherits(proto.Configuration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Configuration.displayName = 'proto.Configuration';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Configuration.repeatedFields_ = [7,8,9,10,11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Configuration.prototype.toObject = function(opt_includeInstance) {
  return proto.Configuration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Configuration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Configuration.toObject = function(includeInstance, msg) {
  var f, obj = {
    startdate: (f = msg.getStartdate()) && date_pb.Date.toObject(includeInstance, f),
    enddate: (f = msg.getEnddate()) && date_pb.Date.toObject(includeInstance, f),
    seed: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updatefrequency: jspb.Message.getFieldWithDefault(msg, 4, 0),
    endofdiesel: (f = msg.getEndofdiesel()) && date_pb.Date.toObject(includeInstance, f),
    endofgasoline: (f = msg.getEndofgasoline()) && date_pb.Date.toObject(includeInstance, f),
    energiesparametersList: jspb.Message.toObjectList(msg.getEnergiesparametersList(),
    energiesParameters_pb.EnergiesParameters.toObject, includeInstance),
    powertrainscarsparametersList: jspb.Message.toObjectList(msg.getPowertrainscarsparametersList(),
    powertrainsCarsParameters_pb.PowertrainsCarsParameters.toObject, includeInstance),
    agentscarsparametersList: jspb.Message.toObjectList(msg.getAgentscarsparametersList(),
    agentsCarsParameters_pb.AgentsCarsParameters.toObject, includeInstance),
    energiespowertrainscarsparametersList: jspb.Message.toObjectList(msg.getEnergiespowertrainscarsparametersList(),
    energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters.toObject, includeInstance),
    agentsparametersList: jspb.Message.toObjectList(msg.getAgentsparametersList(),
    agentsParameters_pb.AgentsParameters.toObject, includeInstance),
    powertrainsparametersList: jspb.Message.toObjectList(msg.getPowertrainsparametersList(),
    powertrainsParameters_pb.PowertrainsParameters.toObject, includeInstance),
    mixesList: jspb.Message.toObjectList(msg.getMixesList(),
    mix_pb.Mix.toObject, includeInstance),
    title: jspb.Message.getFieldWithDefault(msg, 15, ""),
    description: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Configuration}
 */
proto.Configuration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Configuration;
  return proto.Configuration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Configuration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Configuration}
 */
proto.Configuration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setStartdate(value);
      break;
    case 2:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setEnddate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeed(value);
      break;
    case 4:
      var value = /** @type {!proto.Date.Frequency} */ (reader.readEnum());
      msg.setUpdatefrequency(value);
      break;
    case 5:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setEndofdiesel(value);
      break;
    case 6:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setEndofgasoline(value);
      break;
    case 7:
      var value = new energiesParameters_pb.EnergiesParameters;
      reader.readMessage(value,energiesParameters_pb.EnergiesParameters.deserializeBinaryFromReader);
      msg.addEnergiesparameters(value);
      break;
    case 8:
      var value = new powertrainsCarsParameters_pb.PowertrainsCarsParameters;
      reader.readMessage(value,powertrainsCarsParameters_pb.PowertrainsCarsParameters.deserializeBinaryFromReader);
      msg.addPowertrainscarsparameters(value);
      break;
    case 9:
      var value = new agentsCarsParameters_pb.AgentsCarsParameters;
      reader.readMessage(value,agentsCarsParameters_pb.AgentsCarsParameters.deserializeBinaryFromReader);
      msg.addAgentscarsparameters(value);
      break;
    case 10:
      var value = new energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters;
      reader.readMessage(value,energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters.deserializeBinaryFromReader);
      msg.addEnergiespowertrainscarsparameters(value);
      break;
    case 11:
      var value = new agentsParameters_pb.AgentsParameters;
      reader.readMessage(value,agentsParameters_pb.AgentsParameters.deserializeBinaryFromReader);
      msg.addAgentsparameters(value);
      break;
    case 12:
      var value = new powertrainsParameters_pb.PowertrainsParameters;
      reader.readMessage(value,powertrainsParameters_pb.PowertrainsParameters.deserializeBinaryFromReader);
      msg.addPowertrainsparameters(value);
      break;
    case 13:
      var value = new mix_pb.Mix;
      reader.readMessage(value,mix_pb.Mix.deserializeBinaryFromReader);
      msg.addMixes(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Configuration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Configuration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Configuration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Configuration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEnddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSeed();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUpdatefrequency();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getEndofdiesel();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEndofgasoline();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEnergiesparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      energiesParameters_pb.EnergiesParameters.serializeBinaryToWriter
    );
  }
  f = message.getPowertrainscarsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      powertrainsCarsParameters_pb.PowertrainsCarsParameters.serializeBinaryToWriter
    );
  }
  f = message.getAgentscarsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      agentsCarsParameters_pb.AgentsCarsParameters.serializeBinaryToWriter
    );
  }
  f = message.getEnergiespowertrainscarsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters.serializeBinaryToWriter
    );
  }
  f = message.getAgentsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      agentsParameters_pb.AgentsParameters.serializeBinaryToWriter
    );
  }
  f = message.getPowertrainsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      powertrainsParameters_pb.PowertrainsParameters.serializeBinaryToWriter
    );
  }
  f = message.getMixesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      mix_pb.Mix.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional Date startDate = 1;
 * @return {?proto.Date}
 */
proto.Configuration.prototype.getStartdate = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 1));
};


/** @param {?proto.Date|undefined} value */
proto.Configuration.prototype.setStartdate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.Configuration.prototype.clearStartdate = function() {
  this.setStartdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Configuration.prototype.hasStartdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Date endDate = 2;
 * @return {?proto.Date}
 */
proto.Configuration.prototype.getEnddate = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 2));
};


/** @param {?proto.Date|undefined} value */
proto.Configuration.prototype.setEnddate = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.Configuration.prototype.clearEnddate = function() {
  this.setEnddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Configuration.prototype.hasEnddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 seed = 3;
 * @return {number}
 */
proto.Configuration.prototype.getSeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.Configuration.prototype.setSeed = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Date.Frequency updateFrequency = 4;
 * @return {!proto.Date.Frequency}
 */
proto.Configuration.prototype.getUpdatefrequency = function() {
  return /** @type {!proto.Date.Frequency} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.Date.Frequency} value */
proto.Configuration.prototype.setUpdatefrequency = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Date endOfDiesel = 5;
 * @return {?proto.Date}
 */
proto.Configuration.prototype.getEndofdiesel = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 5));
};


/** @param {?proto.Date|undefined} value */
proto.Configuration.prototype.setEndofdiesel = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.Configuration.prototype.clearEndofdiesel = function() {
  this.setEndofdiesel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Configuration.prototype.hasEndofdiesel = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Date endOfGasoline = 6;
 * @return {?proto.Date}
 */
proto.Configuration.prototype.getEndofgasoline = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 6));
};


/** @param {?proto.Date|undefined} value */
proto.Configuration.prototype.setEndofgasoline = function(value) {
  jspb.Message.setWrapperField(this, 6, value);
};


proto.Configuration.prototype.clearEndofgasoline = function() {
  this.setEndofgasoline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Configuration.prototype.hasEndofgasoline = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated EnergiesParameters energiesParameters = 7;
 * @return {!Array.<!proto.EnergiesParameters>}
 */
proto.Configuration.prototype.getEnergiesparametersList = function() {
  return /** @type{!Array.<!proto.EnergiesParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, energiesParameters_pb.EnergiesParameters, 7));
};


/** @param {!Array.<!proto.EnergiesParameters>} value */
proto.Configuration.prototype.setEnergiesparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.EnergiesParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EnergiesParameters}
 */
proto.Configuration.prototype.addEnergiesparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.EnergiesParameters, opt_index);
};


proto.Configuration.prototype.clearEnergiesparametersList = function() {
  this.setEnergiesparametersList([]);
};


/**
 * repeated PowertrainsCarsParameters powertrainsCarsParameters = 8;
 * @return {!Array.<!proto.PowertrainsCarsParameters>}
 */
proto.Configuration.prototype.getPowertrainscarsparametersList = function() {
  return /** @type{!Array.<!proto.PowertrainsCarsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, powertrainsCarsParameters_pb.PowertrainsCarsParameters, 8));
};


/** @param {!Array.<!proto.PowertrainsCarsParameters>} value */
proto.Configuration.prototype.setPowertrainscarsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.PowertrainsCarsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PowertrainsCarsParameters}
 */
proto.Configuration.prototype.addPowertrainscarsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.PowertrainsCarsParameters, opt_index);
};


proto.Configuration.prototype.clearPowertrainscarsparametersList = function() {
  this.setPowertrainscarsparametersList([]);
};


/**
 * repeated AgentsCarsParameters agentsCarsParameters = 9;
 * @return {!Array.<!proto.AgentsCarsParameters>}
 */
proto.Configuration.prototype.getAgentscarsparametersList = function() {
  return /** @type{!Array.<!proto.AgentsCarsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, agentsCarsParameters_pb.AgentsCarsParameters, 9));
};


/** @param {!Array.<!proto.AgentsCarsParameters>} value */
proto.Configuration.prototype.setAgentscarsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.AgentsCarsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentsCarsParameters}
 */
proto.Configuration.prototype.addAgentscarsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.AgentsCarsParameters, opt_index);
};


proto.Configuration.prototype.clearAgentscarsparametersList = function() {
  this.setAgentscarsparametersList([]);
};


/**
 * repeated EnergiesPowertrainsCarsParameters energiesPowertrainsCarsParameters = 10;
 * @return {!Array.<!proto.EnergiesPowertrainsCarsParameters>}
 */
proto.Configuration.prototype.getEnergiespowertrainscarsparametersList = function() {
  return /** @type{!Array.<!proto.EnergiesPowertrainsCarsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters, 10));
};


/** @param {!Array.<!proto.EnergiesPowertrainsCarsParameters>} value */
proto.Configuration.prototype.setEnergiespowertrainscarsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.EnergiesPowertrainsCarsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EnergiesPowertrainsCarsParameters}
 */
proto.Configuration.prototype.addEnergiespowertrainscarsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.EnergiesPowertrainsCarsParameters, opt_index);
};


proto.Configuration.prototype.clearEnergiespowertrainscarsparametersList = function() {
  this.setEnergiespowertrainscarsparametersList([]);
};


/**
 * repeated AgentsParameters agentsParameters = 11;
 * @return {!Array.<!proto.AgentsParameters>}
 */
proto.Configuration.prototype.getAgentsparametersList = function() {
  return /** @type{!Array.<!proto.AgentsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, agentsParameters_pb.AgentsParameters, 11));
};


/** @param {!Array.<!proto.AgentsParameters>} value */
proto.Configuration.prototype.setAgentsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.AgentsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentsParameters}
 */
proto.Configuration.prototype.addAgentsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.AgentsParameters, opt_index);
};


proto.Configuration.prototype.clearAgentsparametersList = function() {
  this.setAgentsparametersList([]);
};


/**
 * repeated PowertrainsParameters powertrainsParameters = 12;
 * @return {!Array.<!proto.PowertrainsParameters>}
 */
proto.Configuration.prototype.getPowertrainsparametersList = function() {
  return /** @type{!Array.<!proto.PowertrainsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, powertrainsParameters_pb.PowertrainsParameters, 12));
};


/** @param {!Array.<!proto.PowertrainsParameters>} value */
proto.Configuration.prototype.setPowertrainsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.PowertrainsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PowertrainsParameters}
 */
proto.Configuration.prototype.addPowertrainsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.PowertrainsParameters, opt_index);
};


proto.Configuration.prototype.clearPowertrainsparametersList = function() {
  this.setPowertrainsparametersList([]);
};


/**
 * repeated Mix mixes = 13;
 * @return {!Array.<!proto.Mix>}
 */
proto.Configuration.prototype.getMixesList = function() {
  return /** @type{!Array.<!proto.Mix>} */ (
    jspb.Message.getRepeatedWrapperField(this, mix_pb.Mix, 13));
};


/** @param {!Array.<!proto.Mix>} value */
proto.Configuration.prototype.setMixesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.Mix=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Mix}
 */
proto.Configuration.prototype.addMixes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.Mix, opt_index);
};


proto.Configuration.prototype.clearMixesList = function() {
  this.setMixesList([]);
};


/**
 * optional string title = 15;
 * @return {string}
 */
proto.Configuration.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/** @param {string} value */
proto.Configuration.prototype.setTitle = function(value) {
  jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string description = 16;
 * @return {string}
 */
proto.Configuration.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/** @param {string} value */
proto.Configuration.prototype.setDescription = function(value) {
  jspb.Message.setProto3StringField(this, 16, value);
};


goog.object.extend(exports, proto);
