import React from 'react';

import {
  EuiPageContentBody,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiTitle,
} from '@elastic/eui';

import Title from "./Title"

export default ({
    title,
    children
}) => (
  <>
    <EuiPageContentHeader>
      <EuiPageContentHeaderSection>
        <EuiTitle>
          <Title color="#006BB4" >{title}</Title>
          {/* <Title color="#3DAAFF" >{title}</Title> */}
        </EuiTitle>
      </EuiPageContentHeaderSection>
    </EuiPageContentHeader>
    <EuiPageContentBody>
        {children}
    </EuiPageContentBody>
  </>
      
);