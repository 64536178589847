import React from "react"
import {connect} from 'react-redux'

import {EuiFormRow} from "@elastic/eui"
import FeatureSwitch from './FeaturesList/FeatureSwitch'


function mapStateToProps(state) {
    return ({
        features: state.features.filter(f => f.canBeSwitched)
    })
}

function FeaturesList({features}){
    return (
        <>
            {
                features.map(
                    (f, index) => <EuiFormRow key={index}>
                        <FeatureSwitch feature={f}/>
                    </EuiFormRow>
                )
            }
        </>
    )
}

export default connect(mapStateToProps, null)(FeaturesList)