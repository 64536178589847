import {sortByPowertrain} from "../../features/colorPalette/reducer"

export function sortByCarType( a, b, accessor=x=>x) {
    const carTypeToIndex =  {
        'BOM': 0,
        'BUS_18': 1,
        'BUS_12': 2,
        'VU': 3,
        'VP': 4
    }
    return carTypeToIndex[accessor(a)] -  carTypeToIndex[accessor(b)]
}

export default function(a,b) {
    if (a.energy && a.carType) {
        const  order = sortByPowertrain(a,b, d=> d.energy)
        if (order === 0) {
            return sortByCarType(a,b, d=> d.carType)
        }
    }
    if (a.energy) {
        return sortByPowertrain(a,b, d=> d.energy)
    }
    else {
        return sortByCarType(a,b, d=> d.carType)
    }
}
