import ConfigurationForm from "../../routes/ConfigurationForm"
import ConfigurationList from "../../routes/ConfigurationList"
import Faq from '../../routes/Faq';
import Attributions from "../../routes/Attributions"
import ImpactViewerRoute from "../../routes/ImpactViewerRoute"
import ChoiceAnalyzer from '../../routes/ChoiceAnalyzer';
import Contribution from "../../routes/Contribution"
//import Playground from "../../routes/Playground";
import Home from "../../routes/Home";

export default [
    {
        name: "Home",
        component: Home,
        route: "/",
        isExact: true,
        label:"Home",
        icon:"grid",
        isEnable: true,
        canBeSwitched: false
    },

    {
        name: "choiceAnalyzer",
        component: ChoiceAnalyzer,
        route: "/choiceAnalyzer",
        isExact: true,
        label: "Choice analyzer",
        icon: "branch",
        isEnable: true,
        canBeSwitched: false
    },
    {
        name: "Contribution analysis",
        component: Contribution,
        route: "/contribution",
        isExact: true,
        label: "Contribution analysis",
        icon: "visPie",
        isEnable: true,
        canBeSwitched: false
    },

    {
        name: "simulationLauncher",
        component: ConfigurationForm,
        route: "/launchSimulation",
        isExact: true, // should the route exactly match
        label:"New simulation",
        icon:"plusInCircle",
        isEnable: false, // does this feature is enable by default ?
        canBeSwitched: true // can this feature be activate/deactivate ?
    },
    {
        name: "simulationsList",
        component: ConfigurationList,
        route: "/simulations",
        isExact:true,
        label: "Simulation list",
        icon:"list",
        isEnable: true,
        canBeSwitched: false
    },
    {
        name: "LCA of simulations",
        component: ImpactViewerRoute,
        route: "/lca",
        isExact: false,
        label: "Lca results viewer",
        icon: "cloudSunny",
        isEnable:true ,
        canBeSwitched: false
    },
    
    {
        name: "Software disclosure",
        component:Attributions,
        route: "/attributions",
        isExact: true,
        label: "Attributions",
        icon: "ip",
        isEnable:false,
        canBeSwitched:true
    },
    {
        name: "FAQ",
        component: Faq,
        route: "/faq",
        isExact: true,
        label: "FAQ",
        icon: "questionInCircle",
        isEnable: false,
        canBeSwitched: true
    },

    /*{
        name: "playground",
        component: Playground,
        route: "/playground",
        isExact: true,
        label:"Playground",
        icon:"",
        isEnable: false,
        canBeSwitched: false
    },*/

]