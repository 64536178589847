/* eslint-disable */ /**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var car_pb = require('./car_pb.js');
var demand_pb = require('./demand_pb.js');
goog.exportSymbol('proto.Agent', null, global);
goog.exportSymbol('proto.Agent.AgentType', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Agent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Agent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Agent.displayName = 'proto.Agent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Agent.prototype.toObject = function(opt_includeInstance) {
  return proto.Agent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Agent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Agent.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    agenttype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    demandMap: (f = msg.getDemandMap()) ? f.toObject(includeInstance, proto.Demand.toObject) : [],
    demandresponseMap: (f = msg.getDemandresponseMap()) ? f.toObject(includeInstance, proto.Car.toObject) : [],
    greenconsciousness: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    ratiochange: +jspb.Message.getFieldWithDefault(msg, 6, 0.0),
    ceilkm: +jspb.Message.getFieldWithDefault(msg, 7, 0.0),
    monthlyfrequency: +jspb.Message.getFieldWithDefault(msg, 8, 0.0),
    ceilold: +jspb.Message.getFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Agent}
 */
proto.Agent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Agent;
  return proto.Agent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Agent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Agent}
 */
proto.Agent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.Agent.AgentType} */ (reader.readEnum());
      msg.setAgenttype(value);
      break;
    case 3:
      var value = msg.getDemandMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Demand.deserializeBinaryFromReader);
         });
      break;
    case 4:
      var value = msg.getDemandresponseMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.Car.deserializeBinaryFromReader);
         });
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGreenconsciousness(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRatiochange(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeilkm(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMonthlyfrequency(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCeilold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Agent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Agent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Agent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Agent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAgenttype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDemandMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Demand.serializeBinaryToWriter);
  }
  f = message.getDemandresponseMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.Car.serializeBinaryToWriter);
  }
  f = message.getGreenconsciousness();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRatiochange();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getCeilkm();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getMonthlyfrequency();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCeilold();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.Agent.AgentType = {
  UNKNOWN: 0,
  COLLECTIVITES_BUS_12: 1,
  COLLECTIVITES_BUS_18: 2,
  COLLECTIVITES_BOM: 3,
  ENTREPRISES_VP: 4,
  ENTREPRISES_VU: 5,
  TAXIS_IND: 6,
  TAXIS_COMPANY: 7,
  ARTISANS: 8
};

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.Agent.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.Agent.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AgentType agentType = 2;
 * @return {!proto.Agent.AgentType}
 */
proto.Agent.prototype.getAgenttype = function() {
  return /** @type {!proto.Agent.AgentType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.Agent.AgentType} value */
proto.Agent.prototype.setAgenttype = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * map<int32, Demand> demand = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Demand>}
 */
proto.Agent.prototype.getDemandMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Demand>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.Demand));
};


proto.Agent.prototype.clearDemandMap = function() {
  this.getDemandMap().clear();
};


/**
 * map<int32, Car> demandResponse = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.Car>}
 */
proto.Agent.prototype.getDemandresponseMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.Car>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.Car));
};


proto.Agent.prototype.clearDemandresponseMap = function() {
  this.getDemandresponseMap().clear();
};


/**
 * optional double greenConsciousness = 5;
 * @return {number}
 */
proto.Agent.prototype.getGreenconsciousness = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.Agent.prototype.setGreenconsciousness = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double ratioChange = 6;
 * @return {number}
 */
proto.Agent.prototype.getRatiochange = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 6, 0.0));
};


/** @param {number} value */
proto.Agent.prototype.setRatiochange = function(value) {
  jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double ceilKm = 7;
 * @return {number}
 */
proto.Agent.prototype.getCeilkm = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 7, 0.0));
};


/** @param {number} value */
proto.Agent.prototype.setCeilkm = function(value) {
  jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double monthlyFrequency = 8;
 * @return {number}
 */
proto.Agent.prototype.getMonthlyfrequency = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 8, 0.0));
};


/** @param {number} value */
proto.Agent.prototype.setMonthlyfrequency = function(value) {
  jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double ceilOld = 9;
 * @return {number}
 */
proto.Agent.prototype.getCeilold = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 9, 0.0));
};


/** @param {number} value */
proto.Agent.prototype.setCeilold = function(value) {
  jspb.Message.setProto3FloatField(this, 9, value);
};


goog.object.extend(exports, proto);
