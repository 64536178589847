import React, { useRef, useEffect } from 'react'

import {agentTypeStream} from "../utils/enumStream"
import {agentTypeConverter, getAgentTypeFromIndex} from "../utils/stringConverters"

export default function AgentEditor({agent}) {
    
    const agentTypeRef = useRef(null)
    const greenConsciousnessRef = useRef(null)
    const monthlyFrequencyRef = useRef(null)
    const ceilOldRef = useRef(null)
    useEffect(
        () =>{
            // setup select
            agentTypeRef.current.renderer = function(root) {
                if (root.firstChild) {
                    return;
                }
                // create the <vaadin-list-box>
                const listBox = window.document.createElement('vaadin-list-box');
    
                // appends <vaadin-item> elements
                agentTypeStream.forEach(function(name) {
                    const item = window.document.createElement('vaadin-item');
                    item.textContent = name;
                    listBox.appendChild(item);
                });
                // update the content
                root.appendChild(listBox);
            }

            // form callback
            agentTypeRef.current.addEventListener('change', (e) => agent.setAgenttype( agentTypeConverter(e.target.value) ));
            //greenConsciousnessRef.current.addEventListener('change', (e) => agent.setGreenconsciousness(Number(e.target.value)));
            monthlyFrequencyRef.current.addEventListener('change', (e) => agent.setMonthlyfrequency(Number(e.target.value)));
            ceilOldRef.current.addEventListener('change', (e) => agent.setCeilold(Number(e.target.value)));
        },
        [
            greenConsciousnessRef,
            agentTypeRef,
            monthlyFrequencyRef,
            ceilOldRef,
            agent
        ]
    )
    
    return (
        <vaadin-form-layout>
            {/* agentType" */}
            <vaadin-select
                ref={agentTypeRef}
                label="Select a type of agent"
                value={getAgentTypeFromIndex(agent.getAgenttype())}
            />
            {/* greenConsciousness" */}
            {/*<vaadin-number-field ref={greenConsciousnessRef} label="Agent green consciousness" has-controls  min="0" max="1" step="0.01" value={agent.getGreenconsciousness()}></vaadin-number-field>*/}
            
            {/* monthlyFrequency" */}
            <vaadin-number-field ref={monthlyFrequencyRef} label="Number of working day in a month" has-controls min="1" max="31" step="1" value={agent.getMonthlyfrequency()}  ></vaadin-number-field>
            {/* ceilOld" */}
            <vaadin-number-field ref={ceilOldRef} label="How many years the fleet will be kept?" has-controls  min="1" max="20" step="0.1" value={agent.getCeilold()}></vaadin-number-field>
        </vaadin-form-layout>
    )
}