import React, { useState } from "react"
import {
    EuiComboBox,
    EuiForm,
    EuiFormRow
} from "@elastic/eui";
import {withRouter} from "react-router"
import simulationListProvider from "../client/simulationListProvider"

function getIdFromPath(path){
    return path.split('lca/')[1]
}

function ConfigurationSelector({simulationList, location, history}) {
    const [id, setId] = useState([{
        label: "" && getIdFromPath(location.pathname)
    }])
    return (
        <EuiForm>
            <EuiFormRow label="Select a simulation">
                <EuiComboBox
                    singleSelection={{ asPlainText: true }}
                    isClearable={false}
                    options={
                        simulationList.map(s => ({label:s}))
                    }
                    selectedOptions={id}
                    onChange={(e) => {
                        if (e && e[0] && e[0].label) {
                            setId(e)
                            history.push(`/lca/${e[0].label}`)
                        }
                    }}
                />
            </EuiFormRow>
        </EuiForm>
        
    )
}

const WrappedConfigurationSelector = simulationListProvider(ConfigurationSelector)
export default withRouter(WrappedConfigurationSelector)