import React, {useState} from "react"

import {connect} from 'react-redux'

import HermesUploadConverter from '../components/HermesUploadConverter';
import {helcarTester} from '../client/configurationLauncher'
import { Agent } from "../generated/agent_pb";
import AgentEditor from "../components/AgentEditor";
import BetasEditor from "../components/BetasEditor";
import { DebugHelcarCommand } from "../generated/debug_pb";
import notify from '../components/VaadinNotificationUtil'
import ChoiceResultAnalyzer from "../components/ChoiceResultAnalyzer";

import Page from '../components/Page'

import {EuiButton} from "@elastic/eui"

function mapStateToProps(state) {
    return {
        configuration: state.configuration
    }
}

function sendTest(configuration, agent, betas, setServerResponse){
    if (configuration != null){
        const command = new DebugHelcarCommand()
        command.setAgent(agent)
        command.setConfiguration(configuration)
        command.setBetagreen(betas.green)
        command.setBetaprice(betas.price)
        command.setBetarange(betas.range)


        helcarTester.getStats(command, null, (err, response) => {
            if (err) {
                console.log(err.code);
                console.log(err.message);
                notify(`Error ${err.code}:`,  `${err.message}`)
            } else {
                console.log(response)
                setServerResponse(response)
            }
        })
    }
}

function getInitialAgent(){
    const agent = new Agent()
    agent.setGreenconsciousness( Number( Math.random().toFixed(2) ) )
    agent.setMonthlyfrequency(22)
    agent.setCeilold(4)
    agent.setAgenttype(1)
    return agent
}

const data = {
    agent: getInitialAgent(),
    betas: {
        price: -1.8,
        green: -2.0,
        range: 0.07
    }
}

function ChoiceAnalyzer(props){

    const [serverResponse, setServerResponse] = useState(null)
    
    return (
        <Page title="Choice Analyzer">
            <HermesUploadConverter />
            <AgentEditor agent={data.agent}/>
            <BetasEditor betas={data.betas}/>
            <EuiButton
                fill
                onClick={ () => sendTest(props.configuration, data.agent, data.betas, setServerResponse)}
            >
                See agent choice
            </EuiButton>
            {
                serverResponse !== null ? <ChoiceResultAnalyzer helcarStatistics={serverResponse} />: null 
            }
        </Page>
    )
}

export default connect(mapStateToProps, null)(ChoiceAnalyzer);
