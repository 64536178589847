/* eslint-disable */ /**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var agentsCarsParameters_pb = require('./agentsCarsParameters_pb.js');
var energiesParameters_pb = require('./energiesParameters_pb.js');
var powertrainsCarsParameters_pb = require('./powertrainsCarsParameters_pb.js');
var powertrainsParameters_pb = require('./powertrainsParameters_pb.js');
var energiesPowertrainsCarsParameters_pb = require('./energiesPowertrainsCarsParameters_pb.js');
var agentsParameters_pb = require('./agentsParameters_pb.js');
goog.exportSymbol('proto.Scenario', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Scenario = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Scenario.repeatedFields_, null);
};
goog.inherits(proto.Scenario, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Scenario.displayName = 'proto.Scenario';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Scenario.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Scenario.prototype.toObject = function(opt_includeInstance) {
  return proto.Scenario.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Scenario} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Scenario.toObject = function(includeInstance, msg) {
  var f, obj = {
    energiesparametersList: jspb.Message.toObjectList(msg.getEnergiesparametersList(),
    energiesParameters_pb.EnergiesParameters.toObject, includeInstance),
    powertrainscarsparametersList: jspb.Message.toObjectList(msg.getPowertrainscarsparametersList(),
    powertrainsCarsParameters_pb.PowertrainsCarsParameters.toObject, includeInstance),
    agentscarsparametersList: jspb.Message.toObjectList(msg.getAgentscarsparametersList(),
    agentsCarsParameters_pb.AgentsCarsParameters.toObject, includeInstance),
    energiespowertrainscarsparametersList: jspb.Message.toObjectList(msg.getEnergiespowertrainscarsparametersList(),
    energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters.toObject, includeInstance),
    agentsparametersList: jspb.Message.toObjectList(msg.getAgentsparametersList(),
    agentsParameters_pb.AgentsParameters.toObject, includeInstance),
    powertrainsparametersList: jspb.Message.toObjectList(msg.getPowertrainsparametersList(),
    powertrainsParameters_pb.PowertrainsParameters.toObject, includeInstance),
    isgasolineforbidden: jspb.Message.getFieldWithDefault(msg, 7, false),
    isdieselforbidden: jspb.Message.getFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Scenario}
 */
proto.Scenario.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Scenario;
  return proto.Scenario.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Scenario} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Scenario}
 */
proto.Scenario.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new energiesParameters_pb.EnergiesParameters;
      reader.readMessage(value,energiesParameters_pb.EnergiesParameters.deserializeBinaryFromReader);
      msg.addEnergiesparameters(value);
      break;
    case 2:
      var value = new powertrainsCarsParameters_pb.PowertrainsCarsParameters;
      reader.readMessage(value,powertrainsCarsParameters_pb.PowertrainsCarsParameters.deserializeBinaryFromReader);
      msg.addPowertrainscarsparameters(value);
      break;
    case 3:
      var value = new agentsCarsParameters_pb.AgentsCarsParameters;
      reader.readMessage(value,agentsCarsParameters_pb.AgentsCarsParameters.deserializeBinaryFromReader);
      msg.addAgentscarsparameters(value);
      break;
    case 4:
      var value = new energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters;
      reader.readMessage(value,energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters.deserializeBinaryFromReader);
      msg.addEnergiespowertrainscarsparameters(value);
      break;
    case 5:
      var value = new agentsParameters_pb.AgentsParameters;
      reader.readMessage(value,agentsParameters_pb.AgentsParameters.deserializeBinaryFromReader);
      msg.addAgentsparameters(value);
      break;
    case 6:
      var value = new powertrainsParameters_pb.PowertrainsParameters;
      reader.readMessage(value,powertrainsParameters_pb.PowertrainsParameters.deserializeBinaryFromReader);
      msg.addPowertrainsparameters(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsgasolineforbidden(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsdieselforbidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Scenario.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Scenario.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Scenario} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Scenario.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnergiesparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      energiesParameters_pb.EnergiesParameters.serializeBinaryToWriter
    );
  }
  f = message.getPowertrainscarsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      powertrainsCarsParameters_pb.PowertrainsCarsParameters.serializeBinaryToWriter
    );
  }
  f = message.getAgentscarsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      agentsCarsParameters_pb.AgentsCarsParameters.serializeBinaryToWriter
    );
  }
  f = message.getEnergiespowertrainscarsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters.serializeBinaryToWriter
    );
  }
  f = message.getAgentsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      agentsParameters_pb.AgentsParameters.serializeBinaryToWriter
    );
  }
  f = message.getPowertrainsparametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      powertrainsParameters_pb.PowertrainsParameters.serializeBinaryToWriter
    );
  }
  f = message.getIsgasolineforbidden();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsdieselforbidden();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * repeated EnergiesParameters energiesParameters = 1;
 * @return {!Array.<!proto.EnergiesParameters>}
 */
proto.Scenario.prototype.getEnergiesparametersList = function() {
  return /** @type{!Array.<!proto.EnergiesParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, energiesParameters_pb.EnergiesParameters, 1));
};


/** @param {!Array.<!proto.EnergiesParameters>} value */
proto.Scenario.prototype.setEnergiesparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EnergiesParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EnergiesParameters}
 */
proto.Scenario.prototype.addEnergiesparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EnergiesParameters, opt_index);
};


proto.Scenario.prototype.clearEnergiesparametersList = function() {
  this.setEnergiesparametersList([]);
};


/**
 * repeated PowertrainsCarsParameters powertrainsCarsParameters = 2;
 * @return {!Array.<!proto.PowertrainsCarsParameters>}
 */
proto.Scenario.prototype.getPowertrainscarsparametersList = function() {
  return /** @type{!Array.<!proto.PowertrainsCarsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, powertrainsCarsParameters_pb.PowertrainsCarsParameters, 2));
};


/** @param {!Array.<!proto.PowertrainsCarsParameters>} value */
proto.Scenario.prototype.setPowertrainscarsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.PowertrainsCarsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PowertrainsCarsParameters}
 */
proto.Scenario.prototype.addPowertrainscarsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.PowertrainsCarsParameters, opt_index);
};


proto.Scenario.prototype.clearPowertrainscarsparametersList = function() {
  this.setPowertrainscarsparametersList([]);
};


/**
 * repeated AgentsCarsParameters agentsCarsParameters = 3;
 * @return {!Array.<!proto.AgentsCarsParameters>}
 */
proto.Scenario.prototype.getAgentscarsparametersList = function() {
  return /** @type{!Array.<!proto.AgentsCarsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, agentsCarsParameters_pb.AgentsCarsParameters, 3));
};


/** @param {!Array.<!proto.AgentsCarsParameters>} value */
proto.Scenario.prototype.setAgentscarsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.AgentsCarsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentsCarsParameters}
 */
proto.Scenario.prototype.addAgentscarsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.AgentsCarsParameters, opt_index);
};


proto.Scenario.prototype.clearAgentscarsparametersList = function() {
  this.setAgentscarsparametersList([]);
};


/**
 * repeated EnergiesPowertrainsCarsParameters energiesPowertrainsCarsParameters = 4;
 * @return {!Array.<!proto.EnergiesPowertrainsCarsParameters>}
 */
proto.Scenario.prototype.getEnergiespowertrainscarsparametersList = function() {
  return /** @type{!Array.<!proto.EnergiesPowertrainsCarsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, energiesPowertrainsCarsParameters_pb.EnergiesPowertrainsCarsParameters, 4));
};


/** @param {!Array.<!proto.EnergiesPowertrainsCarsParameters>} value */
proto.Scenario.prototype.setEnergiespowertrainscarsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.EnergiesPowertrainsCarsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EnergiesPowertrainsCarsParameters}
 */
proto.Scenario.prototype.addEnergiespowertrainscarsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.EnergiesPowertrainsCarsParameters, opt_index);
};


proto.Scenario.prototype.clearEnergiespowertrainscarsparametersList = function() {
  this.setEnergiespowertrainscarsparametersList([]);
};


/**
 * repeated AgentsParameters agentsParameters = 5;
 * @return {!Array.<!proto.AgentsParameters>}
 */
proto.Scenario.prototype.getAgentsparametersList = function() {
  return /** @type{!Array.<!proto.AgentsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, agentsParameters_pb.AgentsParameters, 5));
};


/** @param {!Array.<!proto.AgentsParameters>} value */
proto.Scenario.prototype.setAgentsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.AgentsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AgentsParameters}
 */
proto.Scenario.prototype.addAgentsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.AgentsParameters, opt_index);
};


proto.Scenario.prototype.clearAgentsparametersList = function() {
  this.setAgentsparametersList([]);
};


/**
 * repeated PowertrainsParameters powertrainsParameters = 6;
 * @return {!Array.<!proto.PowertrainsParameters>}
 */
proto.Scenario.prototype.getPowertrainsparametersList = function() {
  return /** @type{!Array.<!proto.PowertrainsParameters>} */ (
    jspb.Message.getRepeatedWrapperField(this, powertrainsParameters_pb.PowertrainsParameters, 6));
};


/** @param {!Array.<!proto.PowertrainsParameters>} value */
proto.Scenario.prototype.setPowertrainsparametersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.PowertrainsParameters=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PowertrainsParameters}
 */
proto.Scenario.prototype.addPowertrainsparameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.PowertrainsParameters, opt_index);
};


proto.Scenario.prototype.clearPowertrainsparametersList = function() {
  this.setPowertrainsparametersList([]);
};


/**
 * optional bool isGasolineForbidden = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.Scenario.prototype.getIsgasolineforbidden = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.Scenario.prototype.setIsgasolineforbidden = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool isDieselForbidden = 8;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.Scenario.prototype.getIsdieselforbidden = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 8, false));
};


/** @param {boolean} value */
proto.Scenario.prototype.setIsdieselforbidden = function(value) {
  jspb.Message.setProto3BooleanField(this, 8, value);
};


goog.object.extend(exports, proto);
