// inspiration from https://codesandbox.io/s/njvoqwj24?from-embed
import React from 'react';
import { Group } from '@vx/group';
import { Text } from '@vx/text';
import { scaleOrdinal } from 'd3-scale';
import { sankeyJustify } from 'd3-sankey-circular';
import {schemeCategory10} from 'd3-scale-chromatic'

import Sankey from './CircularSankey';
import { withTooltip, TooltipWithBounds } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import { LegendOrdinal } from '@vx/legend';

import "./hoverStyles.css" 


const color = scaleOrdinal(schemeCategory10);

function handleMouseOver(event, datum, showTooltip) {
  const coords = localPoint(event.target.ownerSVGElement, event);
  const isEdge = datum.source && datum.target

  showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: {
        ...datum,
        isNode: !isEdge
      }
  });
}

function Chart({
  data,
  width,
  height,
  margin = {
    top: 0,
    left: 0,
    right: 50,
    bottom: 0
  },
  nodeAlign,
  nodePadding,
  nodeWidth,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  tooltipOpen,
  showTooltip,
  hideTooltip
}) {

    if (width < 10) return null;

    return (
      <>
       <LegendOrdinal
          scale={color}
          domain={[ ...new Set(data.nodes.map(
            n => n.class
          ))]}
          direction="row-reverse"
        />
        <svg width={width + margin.left + margin.right} height={height}>
          <Sankey
            top={margin.top}
            left={margin.left}
            data={data}
            size={[width, height]}
            nodeWidth={nodeWidth ? nodeWidth : 15}
            nodePadding={nodePadding ? nodePadding : 40}
            nodePaddingRatio={0.1}
            nodeId={d => d.id}
            nodeAlign={nodeAlign ? nodeAlign : sankeyJustify}
            iterations={50}
          >
            {({ data }) => (
              <Group>
                {data.nodes.map((node, i) => (
                  <Group top={node.y0} left={node.x0} key={`node-${i}`}>
                    <rect
                      onMouseOver={(event) => handleMouseOver(event, node, showTooltip)}
                      onMouseOut={hideTooltip}
                      id={`rect-${i}`}
                      width={node.x1 - node.x0}
                      height={node.y1 - node.y0}
                      fill={color(node.class)}
                      opacity={0.7}
                      stroke="white"
                      strokeWidth={1}
                    />

                    <Text
                      x={18}
                      y={((node.y1 - node.y0) / 2)}
                      verticalAnchor="middle"
                      style={{
                        font: '10px sans-serif'
                      }}
                    >
                      {node.name}
                    </Text>

                  </Group>
                ))}

                <Group strokeOpacity={.2}>
                  {data.links.map((link, i) => (
                    <path
                      onMouseOver={(event) => handleMouseOver(event, link, showTooltip)}
                      onMouseOut={hideTooltip}
                      key={`link-${i}`}
                      d={link.path}
                      stroke={link.circular ? 'green' : 'black'}
                      //stroke={color(link.target.depth)}
                      strokeWidth={Math.max(1, link.width)}
                      opacity={0.7}
                      fill="none"
                    />
                  ))}
                </Group>
              </Group>
            )}
          </Sankey>
        </svg>
        {tooltipOpen && (
          <TooltipWithBounds
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
          >
            {tooltipData.isNode ? <div>
              <span style={{color: color(tooltipData.class)}}>{tooltipData.name}</span>
            </div> :
            <div style={{display:"flex", flexDirection: "column" }}>
              <span style={{color: color(tooltipData.source.class)}}>from: {tooltipData.source.name} </span>
              <span style={{color: color(tooltipData.target.class)}}>to: {tooltipData.target.name}</span>
              <span> {Number(tooltipData.value).toExponential(3)} {tooltipData.source.LCIA_unit} </span>
            </div>
            }
          </TooltipWithBounds>
        )}
      </>
    );
  }

export default withTooltip(Chart)