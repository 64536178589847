import React, {useState, useEffect} from "react"
import {connect} from "react-redux"

import HermesUploadConverter from "../components/HermesUploadConverter"
import { getElectricitySourceFromIndex, getGasSourceFromIndex, getEnergyFromIndex, getCarFromIndex, getPowertrainFromIndex } from "../utils/stringConverters"
import { EnergiesPowertrainsCarsParameters } from "../generated/energiesPowertrainsCarsParameters_pb"
import VaadinSelect from "../components/VaadinSelect"

import ContributionCharts from "../components/ContributionBarCharts"
import ContributionSankeyCharts from "../components/ContributionSankeyCharts"

import Page from '../components/Page'

import {EuiButton} from "@elastic/eui"
//import barSample from "./barSample.json"

import {hlciApiUrl} from '../client/urls'

function sendParams(params, setBarResults, setButtonEnable, transports) {
    setButtonEnable(false)
    setBarResults([])
    Promise.all(
        transports.map(transport => {
            return fetch(`${hlciApiUrl}/contrib`, {
                method:"POST",
                headers: new Headers({
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                    ...params,
                    process: transport
                })
            }).then(response => response.json())
            .catch(err => {
                console.error(err)
            })
        })
    )
    .then(
        d => {
            setBarResults(d.sort(
                (a,b) => a.process.localeCompare(b.process)
            ))
            setButtonEnable(true)
        }
    )
    .catch(err => {
        console.error(err)
        setButtonEnable(true)
    })
}

function sendSankeyParams(params, setResults, setButtonEnable) {
    setButtonEnable(false)
    fetch(`${hlciApiUrl}/sankey`, {
            method:"POST",
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(params)
        }).then(response => response.json())
        .then(results => {
            setResults(results)
        })
        .catch(err => {
            console.error(err)
        })
        .finally(
            () => setButtonEnable(true)
        )
}


function getParamsFromConfiguration(configuration, year) {
    const eMixes = configuration.getMixesList()
        .filter( (x) => x.hasElectricitysource())
        .map(
            (x) => {
                const sourceName = getElectricitySourceFromIndex( x.getElectricitysource() )
                const value = x.getEquationMap().get(year)
                return {
                    [sourceName]: value
                }
            }
        )

    const gMixes = configuration.getMixesList()
        .filter( (x) => x.hasGassource())
        .map(
            (x) => {
                const sourceName = getGasSourceFromIndex( x.getGassource() )
                const value = x.getEquationMap().get(year)
                return {
                    [sourceName]: value
                }
            }
        )

    const consumptions = configuration.getEnergiespowertrainscarsparametersList()
            .filter(
                (x) => x.getAttribute() == EnergiesPowertrainsCarsParameters.EnergiesPowertrainsCarsAttribute.CONSUMPTION
            )
            .map(
                (x) => {
                    const energy = getEnergyFromIndex( x.getEnergy() )
                    const car = getCarFromIndex(x.getCartype())
                    const powertrain = getPowertrainFromIndex(x.getPowertrain())
                    const key = `CONSUMPTION_${powertrain}_${car}_${energy}`
                    const value = x.getEquationMap().get(year)
                    return {
                        [key]: value
                    }
                }
            )

    const enableParams = {
        // Gas
        "BIOGAS": true,
        "NG": true,

        // Electricity
        "CHP_NGCC": true,
        "HARD_COAL": true,
        "HYDRO_PUMPED": true,
        "HYDRO_RESERVOIR": true,
        "HYDRO_RUN_OF_RIVER": true,
        "NG_CONV": true,
        "NUCLEAR_PWR": true,
        "WIND_ONSHORE_1MW": true,
        "WIND_ONSHORE_3MW": true,
        "WIND_OFFSHORE_1_3MW": true,
        "WIND_ONSHORE_1_3MW": true,
        "CONSUMPTION_PLUG_IN_HYBRID_PETROL_VP_PETROL": true,
        "CONSUMPTION_PLUG_IN_HYBRID_PETROL_VP_ELECTRICITY": true,
        "CONSUMPTION_DIESEL_VP_DIESEL": true,
        "CONSUMPTION_DIESEL_VU_DIESEL": true,
        "CONSUMPTION_DIESEL_BUS_12_DIESEL": true,
        "CONSUMPTION_DIESEL_BUS_18_DIESEL": true,
        "CONSUMPTION_DIESEL_BOM_DIESEL": true,
        "CONSUMPTION_HYBRID_DIESEL_BUS_12_DIESEL": true,
        "CONSUMPTION_PETROL_VP_PETROL": true,
        "CONSUMPTION_PETROL_VU_PETROL": true,
        "CONSUMPTION_HYBRID_PETROL_VP_PETROL": true,
        "CONSUMPTION_H2_VP_H2": true,
        "CONSUMPTION_H2_VU_H2": true,
        "CONSUMPTION_H2_BUS_12_H2": true,
        "CONSUMPTION_H2_BUS_18_H2": true,
        "CONSUMPTION_H2_BOM_H2": true,
        "CONSUMPTION_ELECTRIC_VP_ELECTRICITY": true,
        "CONSUMPTION_ELECTRIC_VU_ELECTRICITY": true,
        "CONSUMPTION_ELECTRIC_BUS_12_ELECTRICITY": true,
        "CONSUMPTION_ELECTRIC_BUS_18_ELECTRICITY": true,
        "CONSUMPTION_ELECTRIC_BOM_ELECTRICITY": true,
        "CONSUMPTION_CNG_VU_CNG": true,
        "CONSUMPTION_CNG_BUS_12_CNG": true,
        "CONSUMPTION_CNG_BUS_18_CNG": true,
        "CONSUMPTION_CNG_BOM_CNG": true,
        "CONSUMPTION_HYBRID_DIESEL_BUS_18_DIESEL": true,
        "CONSUMPTION_H2_VU_ELECTRICITY": true,
        "NGCC":false,
        "OIL":false,
        "IMPORT_BE":false,
        "IMPORT_CH":false,
        "IMPORT_DE":false,
        "IMPORT_ES":false,
        "IMPORT_GB":false,
        "IMPORT_IT":false,
        "CHP_BIOGAS":false,
        "CHP_OIL":false,
        "CHP_WOOD":false,

    }
    
    const allParams = eMixes
        .concat(consumptions)
        .concat(gMixes)
        .filter(x => enableParams[
                Object.keys(x)[0]
            ]
        )
        .reduce(
            (a, b) => Object.assign(a,b)
        )

    return allParams
}


function Contribution(props) {

    const [year, setYear] = useState(2020)
    const [barResults, setBarResults] = useState([])
    const [buttonBarEnable, setButtonBarEnable] = useState(false)
    
    const [sankeyResults, setSankeyResults] = useState([])
    const [buttonSankeyEnable, setButtonSankeyEnable] = useState(false)

    const [methods, setMethods] = useState([])
    const [method, setMethod] = useState({})

    const cutoff = 0.005
    const maxcalc = 500

    const [transports, setTransports] = useState([])
    const [transport, setTransport] = useState({})
    const [selectedTab, setSelectedTab] = useState("sankey")

    useEffect(
        () => {
            fetch("https://hermes.list.lu/lcia/methods")
                .then( r => r.json())
                .then( d => setMethods(
                    d.sort((a,b) => a.category.localeCompare(b.category))
                ))
                .catch(e => console.log(`Methods error ${e}`))

            fetch("https://hermes.list.lu/lcia/transports")
                .then( r => r.json())
                .then( d => setTransports(d.sort((a,b) => a.localeCompare(b))))
                .catch(e => console.log(`Transports error ${e}`))
        },
        []
    )

    const disableButtons = () => {
        setButtonBarEnable(true)
        setButtonSankeyEnable(true)
    }

    return (
        <Page title="Contribution analysis">
            <HermesUploadConverter onUpload={disableButtons}/>
            <VaadinSelect 
                items={
                    new Array(2031 - 2017).fill(2017).map((i, index) => index+2017)
                }
                label="Year"
                value={year}
                onClickItem={ (i) => {
                    setYear(Number(i.target.textContent))
                }}
            />

            {
                (transports.length === 0 || methods.length === 0 ) ? <div>
                    Loading methods and transport ...
                </div> : <>
                <VaadinSelect
                    label="Select a method"
                    items={methods.map(impact => impact.subcategory)}
                    onClickItem={ (e) => setMethod(methods.find( i => i.subcategory === e.target.textContent)) }
                    currentValue={method.subcategory}
                />
                <VaadinSelect
                    label="Select a transport"
                    items={transports}
                    onClickItem={ (e) => setTransport(e.target.textContent)}
                    currentValue={transport}
                />
                <br/>
                <EuiButton
                    isDisabled={!buttonSankeyEnable}
                    onClick={
                        () => sendSankeyParams( {
                            ... getParamsFromConfiguration(props.configuration, year),
                            method: method.method,
                            category: method.category,
                            subcategory: method.subcategory,
                            process: transport,
                            cutoff,
                            maxcalc
                        }, setSankeyResults, setButtonSankeyEnable)
                    }
                >
                    Start sankey contribution (require year, method and transport)
                </EuiButton>
                </>
            }
            <EuiButton
                isDisabled={!buttonBarEnable}
                onClick={
                    () => sendParams(
                        {
                            ...getParamsFromConfiguration(props.configuration, year),
                            method: method.method,
                            category: method.category,
                            subcategory: method.subcategory,
                            cutoff,
                            maxcalc
                        },
                        setBarResults,
                        setButtonBarEnable,
                        transports
                    )
                }
            >
                Start bar contribution (require year, method)
            </EuiButton>
            
            <vaadin-tabs theme="centered">
                <vaadin-tab onClick={() => setSelectedTab("sankey")}>Sankey chart</vaadin-tab>
                <vaadin-tab onClick={() => setSelectedTab("bar")}>Bar charts</vaadin-tab>
            </vaadin-tabs>

            {
                selectedTab === "bar" ? <ContributionCharts data={barResults}/> :
                selectedTab === "sankey" ? <ContributionSankeyCharts data={sankeyResults}/> :
                null
            }
        </Page>
    )
}

function mapStateToProps(state) {
    return {
        configuration: state.configuration
    }
}

export default connect(mapStateToProps, null)(Contribution)