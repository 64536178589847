import React from "react"
import {Route} from "react-router"

import ConfigurationSelector from "../components/ConfigurationSelector"
import ImpactViewer from "../components/ImpactViewer"
import Page from "../components/Page"

export default function ImpactViewerRoute(props){
    return (
        <Page title="Impact viewer">
            <ConfigurationSelector />
            <Route exact path="/lca/:id" component={ImpactViewer}/>
        </Page>
    )
}