import React from 'react'
import ResponsiveOrdinalFrame from "semiotic/lib/ResponsiveOrdinalFrame"
import tinycolor from "tinycolor2"

import {getPowertrainFromIndex} from '../../utils/stringConverters'
import {powertrainStream} from "../../utils/enumStream"

function getColorFromPalette(palette, type) {
    return palette.filter(c => c.powertrain === type)[0].color
}

export default function PowertrainTCOBarChart({powertrainStatsList, colorPalette}){
    const colorHash = {
        netCost: '#00afdc',
        insuranceCost: tinycolor('#00afdc').spin(1/6 * 360).toString(),
        maintenance: tinycolor('#00afdc').spin(2/6 * 360).toString(),
        decote: tinycolor('#00afdc').spin(3/6 * 360).toString(),
        energyPriceByKm: tinycolor('#00afdc').spin(4/6 * 360).toString(),
        subsidy: tinycolor('#00afdc').spin(5/6 * 360).toString(),
    }

    const data = powertrainStatsList.map(
        ps => {
            const tco = ps.getTco()

            return {
                    type: getPowertrainFromIndex(ps.getPowertrain()),
                    netCost: Math.floor(tco.getNetcarcost()),
                    insuranceCost: Math.floor(tco.getInsurance()),
                    maintenance: Math.floor(tco.getMaintenance()),
                    decote: Math.floor(tco.getDecote()),
                    energyPriceByKm: Math.floor(tco.getEnergypricebykm()),
                    subsidy: - Math.floor(tco.getSubsidy()),
                    year: ps.getYear(),
                    total: Math.floor(tco.getNetcarcost() + tco.getInsurance() + tco.getMaintenance() + tco.getDecote() + tco.getEnergypricebykm() - tco.getSubsidy()) 
                }
        }
    )
    console.log("---TCO---")
    console.log(data)

    console.log("---FIN-TCO---")
    const frameProps = { 
        /* --- Data --- */
        data: data,
    
        /* --- Size --- */
        size: [400, 300],
        responsiveWidth: true,
        
        margin:{ left: 75, top: 75, bottom: 75, right: 75 },
    
        /* --- Layout --- */
        type: "clusterbar",
    
        /* --- Process --- */
        oAccessor: "year",
        rAccessor: "total",
    
        /* --- Customize --- */
        style: d => ({ fill: getColorFromPalette(colorPalette, d.type), stroke: "white" }),
        title: (
            <text textAnchor="middle" style={{
                fontSize:"1,2rem"
            }}>
                TCO evolution over time:{" "}
                {
                    powertrainStream.map(
                    i =>  <tspan key={i} fill={getColorFromPalette(colorPalette, i)}>
                        {i} {" "}
                    </tspan>
                    
                )
                }
            </text>
        ),
        pieceHoverAnnotation: true,
        tooltipContent: d => <div className="tooltip-content">
            <p>
                type: {d.type}
            </p>
            <p>
                <span style={{color:colorHash.netCost}}>netCost</span>: {d.netCost}
            </p>
            <p>
                <span style={{color:colorHash.insuranceCost}}>insuranceCost</span>: {d.insuranceCost}
            </p>
            <p>
                <span style={{color:colorHash.maintenance}}>maintenance</span>: {d.maintenance}
            </p>
            <p>
                <span style={{color:colorHash.decote}}>decote</span>: {d.decote}
            </p>
            <p>
                <span style={{color:colorHash.energyPriceByKm}}>energyPriceByKm</span>: {d.energyPriceByKm}
            </p>
            <p>
                <span style={{color:colorHash.subsidy}}>subsidy</span>: {d.subsidy}
            </p>
            <p>
                total: { d.total} 
            </p>
        </div>,
    
        /* --- Annotate --- */
        oLabel: true,

        axes: [
            { orient: "left", label: "Net cost (in 1000€)", tickFormat: d =>((d/1000).toLocaleString())},
        ]
    }

    return <ResponsiveOrdinalFrame {...frameProps}/>
}