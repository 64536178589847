import React from "react"
import {connect} from 'react-redux'

import {
    EuiFormRow,
    EuiColorPicker,
    EuiBadge,
    EuiFlexGroup,
    EuiFlexItem
} from "@elastic/eui"
import { changeColor, resetPalette } from "../actions"
import { EuiButton } from "@elastic/eui"

function mapStateToProps(state) {
    return ({
        colorPalette: state.colorPalette
    })
}

function mapDispatchToProps(dispatch) {
    return ({
        setColor: (powertrain, color) => dispatch(changeColor(powertrain, color)),
        reset: () => dispatch(resetPalette)
    })
}

const indexToVehicle = [
    'BOM',
    'BUS_18',
    'BUS_12',
    'VU',
    'VP'
]

function Palette({colorPalette, setColor, reset}){
    return (
        <>
            {
                colorPalette
                .map(
                    (p, index) => <EuiFormRow key={index} label={p.powertrain} display="rowCompressed">
                        <>
                            {/*<EuiColorPicker
                                compressed={true}
                                color={p.color}
                                //onChange={c => setColor(p.powertrain,c)}
                            />*/}
                            
                            <EuiFlexGroup gutterSize="none">

                                {p.colors.map((hexCode,i) => (
                                    <EuiFlexItem key={i} style={{ backgroundColor: hexCode}}>
                                        {hexCode}
                                    </EuiFlexItem>
                                ))}
                            </EuiFlexGroup>
                        </>
                    </EuiFormRow>
                )
            }
            {
                /*
                    <EuiButton 
                        size="s"
                        onClick={reset}>
                        Reset
                    </EuiButton>
                */
            }
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Palette)