import XLSX from 'xlsx'

import {
    agentTypeConverter,
    carTypeConverter,
    agentCarAttributeConverter,
    energyTypeConverter,
    powertrainTypeConverter,
    powertrainAttributeConverter,
    carPowertrainAttributeConverter,
    energyCarPowertrainAttributeConverter,
    mixAttributeConverter,
    mixSourceConverter,
    betasConverter
    } from "../utils/stringConverters"

import { Configuration } from '../generated/configuration_pb'
import { AgentsParameters, AgentTimeSeries } from '../generated/agentsParameters_pb';
import { AgentsCarsParameters } from '../generated/agentsCarsParameters_pb';
import { EnergiesParameters } from '../generated/energiesParameters_pb';
import { PowertrainsCarsParameters } from '../generated/powertrainsCarsParameters_pb';
import { PowertrainsParameters} from '../generated/powertrainsParameters_pb'
import { EnergiesPowertrainsCarsParameters } from '../generated/energiesPowertrainsCarsParameters_pb';
import { Date } from '../generated/date_pb'
import { Mix } from '../generated/mix_pb';

function addEquation(object, values) {
    Object.keys(values).map(
        k => object.getEquationMap().set(k, values[k])
    )
}

// This function aim to parse an excel file and generate associated map
export default function createConfig(f, setConfiguration) {

    const reader = new FileReader();
    
    reader.onload = function(e) {
        const data = e.target.result;

        /* if binary string, read with type 'binary' */
        const workbook = XLSX.read(data, {type: 'binary'});

        // create configuration
        const configuration = new Configuration()
        configuration.setTitle(f.name)
        configuration.setUpdatefrequency(Date.Frequency.MONTHLY)

        const startDate = new Date()
        startDate.setDay(1)
        startDate.setMonth(1)
        startDate.setYear(2017)
        configuration.setStartdate(startDate)

        const endDate = new Date()
        endDate.setDay(1)
        endDate.setMonth(11)
        endDate.setYear(2031)
        configuration.setEnddate(endDate)

        console.log("Parsing ...")

        const otherParameters = XLSX.utils.sheet_to_json(workbook.Sheets["Others"])
        otherParameters.map(
            row => {
                const parameter = row['PARAMETER']
                switch(parameter) {
                    case 'SEED':
                        configuration.setSeed(row['VALUE'])
                    break;
                    case 'END_GAS':
                        const date1 = String(row['VALUE'])
                        const endGasDate = new Date()
                        const [day1, month1, year1] = date1.split('/')
                        endGasDate.setDay(Number(day1))
                        endGasDate.setMonth(Number(month1))
                        endGasDate.setYear(Number(year1))
                        configuration.setEndofgasoline(
                            endGasDate
                        )
                    break;
                    case 'END_DIESEL':
                        const date2 = String(row['VALUE'])
                        const endDieselDate = new Date()
                        const [day2, month2, year2] = date2.split('/')
                        endDieselDate.setDay(Number(day2))
                        endDieselDate.setMonth(Number(month2))
                        endDieselDate.setYear(Number(year2))
                        configuration.setEndofdiesel(
                            endDieselDate
                        )
                    break;
                    default:
                        console.error('UNKNOWN OTHER PARAMETER')
                }

            }
        )

        const agentsParametersList = XLSX.utils.sheet_to_json(workbook.Sheets["Agents"])
        const betas = XLSX.utils.sheet_to_json(workbook.Sheets["AgentsTimeSeries"])
        agentsParametersList.map(
            row => {
                const agentParameter = new AgentsParameters()
                agentParameter.setAgenttype(agentTypeConverter(row['AGENT_TYPE']))
                agentParameter.setNumber(row['TOTAL_VALUE'])
                agentParameter.setMinnumbercar(row['NB_CARS_MIN'])
                agentParameter.setModenumbercar(row['NB_CARS_MODE'])
                agentParameter.setMaxnumbercar(row['NB_CARS_MAX'])
                agentParameter.setCeilold(row['CEIL_OLD'])
                agentParameter.setCeilkm(row['CEIL_KM'])
                agentParameter.setRatiochange(row['RATIO_CHANGE'])
                agentParameter.setMonthlyfrequency(row['MONTHLY_FREQUENCY'])
               
                // FIXME: get ENUM instead of raw value
                new Array(
                    "BETA_PRICE",
                    "BETA_RANGE",
                    "BETA_GREEN"
                    ).map(
                    beta => {
                        // create time series
                        const timeSeries = new AgentTimeSeries()
                        timeSeries.setAttribute(betasConverter(beta))

                        // filter correct agent
                        betas.filter(
                            r => r['AGENT_TYPE'] === row['AGENT_TYPE']
                        )
                        .forEach(
                            r => timeSeries.getEquationMap().set(String(r['YEAR']), r[beta])
                        )
                        agentParameter.addTimeseries(timeSeries)
                    }
                )
                

                configuration.addAgentsparameters(agentParameter)
            }
        )


        // Agent Car parameters
        const agentsCarsParametersList = XLSX.utils.sheet_to_json(workbook.Sheets["AgentsCars"])
        const agentsCarsParameters = {}
        agentsCarsParametersList.reduce(
            (r, o) => {
                const key = `${o['ATTRIBUTE']}_${o['AGENT_TYPE']}_${o['CAR_TYPE']}`
                if (!agentsCarsParameters[key]){
                    agentsCarsParameters[key] = Object.assign({}, {
                        agentType: agentTypeConverter(o['AGENT_TYPE']),
                        carType: carTypeConverter(o['CAR_TYPE']),
                        attribute: agentCarAttributeConverter(o['ATTRIBUTE']),
                        years: Object.assign({}, {
                            [o['YEAR']]: o['VALUE']
                        })
                    })
                } else {
                    agentsCarsParameters[key].years = Object.assign(agentsCarsParameters[key].years, {
                        [o['YEAR']]: o['VALUE']
                    })
                }
            },
            {}
        )
        
        Object.keys(agentsCarsParameters).map( k => agentsCarsParameters[k])
            .map(
                o => {
                    const agentCarParameterEquation = new AgentsCarsParameters()
                    agentCarParameterEquation.setAgenttype(o.agentType)
                    agentCarParameterEquation.setAttribute(o.attribute)
                    agentCarParameterEquation.setCartype(o.carType)
                    addEquation( agentCarParameterEquation, o.years)
                    configuration.addAgentscarsparameters(agentCarParameterEquation)
                }
            )

        // Energy parameter
        const energiesParametersList = XLSX.utils.sheet_to_json(workbook.Sheets["Energies"])
        const energiesParameters = {}
        energiesParametersList.reduce(
            (r, o) => {
                const key = `${o['ATTRIBUTE']}_${o['ENERGY']}}`
                if (!energiesParameters[key]){
                    energiesParameters[key] = Object.assign({}, {
                        energyType: energyTypeConverter(o['ENERGY']),
                        years: Object.assign({}, {
                            [o['YEAR']]: o['VALUE']
                        })
                    })
                } else {
                    energiesParameters[key].years = Object.assign(energiesParameters[key].years, {
                        [o['YEAR']]: o['VALUE']
                    })
                }
            },
            {}
        )
        Object.keys(energiesParameters).map( k => energiesParameters[k])
            .map(
                o => {
                    const energyParameterEquation = new EnergiesParameters()
                    energyParameterEquation.setEnergy(o.energyType)
                    addEquation( energyParameterEquation, o.years)
                    configuration.addEnergiesparameters(energyParameterEquation)
                }
            )

        // Powertrain parameter
        const powertrainsParametersList = XLSX.utils.sheet_to_json(workbook.Sheets["Powertrains"])
        const powertrainsParameters = {}
        powertrainsParametersList.reduce(
            (r, o) => {
                const key = `${o['ATTRIBUTE']}_${o['POWERTRAIN']}}`
                if (!powertrainsParameters[key]){
                    powertrainsParameters[key] = Object.assign({}, {
                        powertrainType: powertrainTypeConverter(o['POWERTRAIN']),
                        attribute: powertrainAttributeConverter(o['ATTRIBUTE']),
                        years: Object.assign({}, {
                            [o['YEAR']]: o['VALUE']
                        })
                    })
                } else {
                    powertrainsParameters[key].years = Object.assign(powertrainsParameters[key].years, {
                        [o['YEAR']]: o['VALUE']
                    })
                }
            },
            {}
        )
        Object.keys(powertrainsParameters).map( k => powertrainsParameters[k])
            .map(
                o => {
                    const powertrainParameterEquation = new PowertrainsParameters()
                    powertrainParameterEquation.setPowertrain(o.powertrainType)
                    powertrainParameterEquation.setAttribute(o.attribute)
                    addEquation( powertrainParameterEquation, o.years)
                    configuration.addPowertrainsparameters(powertrainParameterEquation)
                }
            )

        // Energy powertrain car parameters
        const energyCarPowertrainParametersList = XLSX.utils.sheet_to_json(workbook.Sheets["CarsPowertrainsEnergies"])
        const energyCarPowertrainParameters = {}
        energyCarPowertrainParametersList.reduce(
            (r, o) => {
                const key = `${o['ATTRIBUTE']}_${o['POWERTRAIN']}_${o['CAR_TYPE']}_${o['ENERGY']}`
                if (!energyCarPowertrainParameters[key]){
                    energyCarPowertrainParameters[key] = Object.assign({}, {
                        powertrainType: powertrainTypeConverter(o['POWERTRAIN']),
                        carType: carTypeConverter(o['CAR_TYPE']),
                        energyType: energyTypeConverter(o['ENERGY']),
                        attribute: energyCarPowertrainAttributeConverter(o['ATTRIBUTE']),
                        years: Object.assign({}, {
                            [o['YEAR']]: o['VALUE']
                        })
                    })
                } else {
                    energyCarPowertrainParameters[key].years = Object.assign(energyCarPowertrainParameters[key].years, {
                        [o['YEAR']]: o['VALUE']
                    })
                }
            },
            {}
        )
        Object.keys(energyCarPowertrainParameters).map( k => energyCarPowertrainParameters[k])
            .map(
                o => {
                    const energyPowertrainCarParameterEquation = new EnergiesPowertrainsCarsParameters()
                    energyPowertrainCarParameterEquation.setPowertrain(o.powertrainType)
                    energyPowertrainCarParameterEquation.setCartype(o.carType)
                    energyPowertrainCarParameterEquation.setAttribute(o.attribute)
                    energyPowertrainCarParameterEquation.setEnergy(o.energyType)
                    addEquation( energyPowertrainCarParameterEquation, o.years)
                    configuration.addEnergiespowertrainscarsparameters(energyPowertrainCarParameterEquation)
                }
            )

        // add mixes
        const mixesList = XLSX.utils.sheet_to_json(workbook.Sheets["Mixes"])
        const mixesParameters = {}
        mixesList.reduce(
            (r, o) => {
                const key = `${o['ATTRIBUTE']}_${o['VOLTAGE']}_${o['SOURCE']}`
                if (!mixesParameters[key]){
                    const attribute = mixAttributeConverter(o['ATTRIBUTE'])
                    mixesParameters[key] = Object.assign({}, {
                        type: attribute,
                        source: mixSourceConverter(attribute, o['SOURCE']),
                        years: Object.assign({}, {
                            [o['YEAR']]: o['VALUE']
                        })
                    })
                } else {
                    mixesParameters[key].years = Object.assign(mixesParameters[key].years, {
                        [o['YEAR']]: o['VALUE']
                    })
                }
            },
            {}
        )
        Object.keys(mixesParameters).map( k => mixesParameters[k])
            .map(
                o => {
                    const mix = new Mix()
                    mix.setType(o.type)
                    if(o.type === Mix.EnergyType.ELECTRICITY) {
                        mix.setElectricitysource(o.source)
                    }
                    else if (o.type === Mix.EnergyType.GAS){
                        mix.setGassource(o.source)
                    }
                    addEquation( mix, o.years)
                    configuration.addMixes(mix)
                }
            )

        // Car Powertrain parameters
        const carPowertrainParametersList = XLSX.utils.sheet_to_json(workbook.Sheets["CarsPowertrains"])
        const carPowertrainParameters = {}
        carPowertrainParametersList.reduce(
            (r, o) => {
                const key = `${o['ATTRIBUTE']}_${o['POWERTRAIN']}_${o['CAR_TYPE']}`
                if (!carPowertrainParameters[key]){
                    carPowertrainParameters[key] = Object.assign({}, {
                        powertrainType: powertrainTypeConverter(o['POWERTRAIN']),
                        carType: carTypeConverter(o['CAR_TYPE']),
                        attribute: carPowertrainAttributeConverter(o['ATTRIBUTE']),
                        years: Object.assign({}, {
                            [o['YEAR']]: o['VALUE']
                        })
                    })
                } else {
                    carPowertrainParameters[key].years = Object.assign(carPowertrainParameters[key].years, {
                        [o['YEAR']]: o['VALUE']
                    })
                }
            },
            {}
        )
        Object.keys(carPowertrainParameters).map( k => carPowertrainParameters[k])
            .map(
                o => {
                    const carPowertrainParametersEquation = new PowertrainsCarsParameters()
                    carPowertrainParametersEquation.setPowertrain(o.powertrainType)
                    carPowertrainParametersEquation.setCartype(o.carType)
                    carPowertrainParametersEquation.setAttribute(o.attribute)
                    addEquation( carPowertrainParametersEquation, o.years)
                    configuration.addPowertrainscarsparameters(carPowertrainParametersEquation)
                }
            )
        console.log("parsing done")
        console.log(configuration)
        setConfiguration(configuration)
    };
    
    reader.readAsBinaryString(f);
}
