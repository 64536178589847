import React from 'react'
import ResponsiveMinimapXYFrame from "semiotic/lib/ResponsiveMinimapXYFrame"
import { scaleTime } from 'd3-scale'
import { connect } from 'react-redux'

import superSort from "./carSort"

function mapStateToProps(state) {
    return ({
        colorPalette: state.colorPalette
    })
}


// fonce clair
// bom, 18m 12, vu, vp
// each type of energy has a color
const carTypeGreyScaleMap = {
    'BOM': palette => palette[4],
    'BUS_18': palette => palette[3],
    'BUS_12': palette => palette[2],
    'VU': palette => palette[1],
    'VP': palette => palette[0],
}

function generateColorMap(colorPalette){
    const colorMap = {}
    const defaulColor = "CNG"
    const defaulOpacity = "BUS_18"

    colorPalette.forEach(
        cp => {
            colorMap[cp.powertrain] = carTypeGreyScaleMap[defaulOpacity](cp.colors)
            Object.keys(carTypeGreyScaleMap).forEach(
                k => {
                    const key =  k+cp.powertrain
                    colorMap[key] = carTypeGreyScaleMap[k](cp.colors)
                }
            )
        }
    )

    Object.keys(carTypeGreyScaleMap).forEach(
        k => {
            const key =  k
            colorMap[key] = carTypeGreyScaleMap[k](colorPalette.filter(
                cp => cp.powertrain === defaulColor
            )[0].colors)
        }
    )

    return colorMap
}

function getLineStyle(d, colorPalette) {
    let {energy, carType} = d
    energy = energy ? energy : "" // default color
    carType = carType ? carType : "" // default opacity
    const color = colorPalette[carType+energy]
    
    return {
        stroke: color,
        strokeWidth: 1,
        fill: color,
        fillOpacity: 0.9
    }
}

function getPointStyle(d, colorPalette) {
    let {energy, carType} = d
    energy = energy ? energy : "" // default color
    carType = carType ? carType : "" // default opacity
    const color = colorPalette[carType+energy]
    return {
        fill: color,
        r:3
    }
}

const MemoizedResponsiveMinimapXYFrame = React.memo(ResponsiveMinimapXYFrame)

function Chart({
    lineType,
    xExtent,
    data,
    name,
    unit,
    changeExtent,
    endDiesel,
    endPetrol,
    colorPalette,
    maxY

    }) {
        const colorMap = generateColorMap(colorPalette)
        const endDieselDate = new Date(endDiesel)
        const endDieselString = `${endDieselDate.getDay()}/${endDieselDate.getMonth() + 1}/${endDieselDate.getFullYear()}`
        const endPetrolDate = new Date(endPetrol)
        const endPetrolString = `${endPetrolDate.getDay()}/${endPetrolDate.getMonth() + 1}/${endPetrolDate.getFullYear()}`
    
        return <MemoizedResponsiveMinimapXYFrame
            /* --- Layout --- */
            lineType={lineType}
            canvasLines={true}
            canvasAreas={true}
            canvasPoints={true}
            /* --- Size --- */
            size={[0, 650]}
            margin={{ left: 75, top: 90, bottom: 75, right: 250 }}
            responsiveWidth={true}
            /* --- Process --- */
            xAccessor={d => new Date(d.time)}
            yAccessor="score"
            xExtent={xExtent}
            yExtent={[0, maxY]}
            lineDataAccessor="aggregation"

            showLinePoints={lineType === "line" || lineType === "cumulative"}
            pointStyle={d => getPointStyle(d.parentLine, colorMap)}

            /* --- Customize --- */
            lineStyle={d => getLineStyle(d, colorMap)}
            
            hoverAnnotation={[
                {
                    type: "highlight",
                    style: { strokeWidth: 3, r:5 }
                },
                { type: "frame-hover" },
            ]}
            lineIDAccessor={d => d.energy + d.carType}

            title={ name.charAt(0).toUpperCase() + name.slice(1)+" in "+unit}
            axes= {[
                {
                    orient: "left",
                    tickFormat: d => Number(d).toExponential(),
                    label: {
                        name: unit
                    }
                },
                {
                    orient: "bottom",
                    label: {
                        name: "Time"
                    },
                    ticks: 5,
                    tickFormat: d => new Date(d).getMonth() + 1 + "/" + new Date(d).getFullYear(),
                }
            ]}
            xScaleType={scaleTime()}
            matte= {true}
            tooltipContent={ d => (
                <div className="tooltip-content">
                    {d.parentLine.carType ? <p>Vehicle: {d.parentLine.carType}</p> : null}
                    {d.parentLine.energy ? <p>Powertrain: {d.parentLine.energy}</p> : null}
                    <p>Date: {d.time.toString()}</p>
                    <p>Value: {Number(d.score).toExponential(3)}</p>
                </div>
            )}
            lines={data}
            minimap={{
                brushEnd: e => changeExtent(e),
                yBrushable: false,
                xBrushExtent: xExtent,
                margin: { left: 75, right: 40, top: 0, bottom: 30}
            }}
            annotations={[
                {
                    type: "x",
                    time: endDieselString,
                    note: {
                        label: "End of diesel policy",
                        align: "middle",
                        lineType: null,
                        wrap: 100
                        },
                    color: "#69707D",
                    dy: -10,
                    dx: 0,
                    connector: { end: "none" }
                },
                {
                    type: "x",
                    time: endPetrolString,
                    note: {
                        label: "End of petrol policy",
                        align: "middle",
                        lineType: null,
                        wrap: 100
                        },
                    color: "#69707D",
                    dy: -10,
                    dx: 0,
                    connector: { end: "none" }
                },
            ]}
            foregroundGraphics={[
                <g  style={{
                    transform:"translate(calc(100% - 210px), 100px)"
                }} key="legend">
                    {
                        data
                            .sort((a,b) => -1 * superSort(a,b))
                            .map( (d,i) => {
                                let {energy, carType} = d
                                energy = energy ? energy : "" // default color
                                carType = carType ? carType : "" // default opacity
                                const color = colorMap[carType+energy]
                                return (
                                    <g key={i}>
                                        <rect width="12" height="12" x="0" y={i*20} rx="2" fill={color}></rect>
                                        <text y={i*20 + 10}  x="16">
                                            {`${energy} ${carType}`}
                                        </text>
                                    </g>
                                )
                            })
                    }
                </g>
              ]}
    />
}

export default connect(mapStateToProps, null)(Chart)