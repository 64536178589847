import React from 'react'
import {useHistory} from "react-router-dom" 

import {
    EuiHeader,
    EuiHeaderSectionItem,
    EuiHeaderSection,
    EuiHeaderLogo,
    EuiShowFor,
    EuiHeaderSectionItemButton,
    EuiIcon
} from "@elastic/eui"

import hermesSvgLogo from "./hermes.svg"

import HeaderMenu from "./HeaderMenu"
import Palette from '../../features/colorPalette/components/Palette'
import FeaturesList from "./FeaturesList"

export default function HermesHeader({toogleMenu}){
    const history = useHistory()

    return (
        <>
            <EuiHeader>
                <EuiHeaderSection >
                    <EuiHeaderSectionItem border="right">
                        <EuiHeaderLogo 
                            iconType={hermesSvgLogo}
                            href="/"
                            onClick={
                                e => {
                                    e.preventDefault()
                                    history.push("/")
                                }
                            }
                        />
                    </EuiHeaderSectionItem>
                </EuiHeaderSection>
            </EuiHeader>
        
            <EuiHeader style={{
                position: "fixed",
                bottom:"0px",
                right:"0px"
            }}>

                <EuiHeaderSection side="right">

                    <HeaderMenu icon="brush">
                        <Palette/>
                    </HeaderMenu>

                    <HeaderMenu icon="beaker">
                        <FeaturesList/>
                    </HeaderMenu>

                    <EuiShowFor sizes={['xs', 's']}>
                        <EuiHeaderSectionItem border="right">
                            <EuiHeaderSectionItemButton
                                aria-label="Open nav"
                                onClick={toogleMenu}>
                                <EuiIcon type="apps" size="m" />
                            </EuiHeaderSectionItemButton>
                        </EuiHeaderSectionItem>
                    </EuiShowFor>
                    
                </EuiHeaderSection>
            </EuiHeader>
        </>
        
    )
}