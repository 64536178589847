import React, {useEffect, useState} from "react"
import {withRouter} from "react-router"
import AreaTimelinePlot from "./ImpactViewer/AreaTimelinePlot";
import DataFrame from 'dataframe-js';

import DownloadDataButton from "./ImpactViewer/DownloadDataButton";
import SimulationDetailsProvider from "../client/SimulationDetailsProvider";

import KmCharts from '../components/ImpactViewer/KmCharts'

import {
    EuiForm,
    EuiFormRow,
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingChart
} from "@elastic/eui";

import {carTypeStream, powertrainStream} from '../utils/enumStream'
import { EuiEmptyPrompt } from "@elastic/eui";
import { EuiTitle } from "@elastic/eui";

function getIdFromPath(path){
    return path.split('lca/')[1]
}

function fetchValue(id, setImpact, setKm, setError) {
    fetch(`https://hermes.list.lu/lcia/demands?simulationid=${id}`)
        .then(response => response.json())
        .then( results => {
            setKm(
                new DataFrame(results, [
                    "powertrain",
                    "type",
                    "time",
                    "sum",
                ])
                .rename("powertrain", "energy")
                .rename("type", "carType")
            )
        })
        .catch(
            error =>{
                setError(error)
                console.error(error)
            }
        )

    fetch(`https://hermes.list.lu/lcia/lcia?simulationid=${id}`)
        .then(response => response.json())
        .then(results => {
            
            const df = new DataFrame(results, [
                "impact_category",
                "method",
                "car_type",
                "powertrain",
                "score",
                "time",
                "unit",
            ])
            .rename("powertrain", "energy")
            .rename("car_type", "carType")
            //console.log(df)
            const impacts = df.groupBy('impact_category', "unit")
                .aggregate(g=>g)
                .toCollection()

            setImpact(impacts)
        })
        .catch(
            error =>{
                setError(error)
                console.error(error)
            }
        )
    console.info(`Fetching lcia result for ${id}`)

    return () => {
        // cancel promise
    }
}

function carTypesFilter(selectedCarTypesOptions) {
    return selectedCarTypesOptions.length === 0 ? x => true : x => selectedCarTypesOptions.map(l => l.label).includes(x.get("carType")) 
}
function energiesFilter(selectedEnergiesOptions) {
    return selectedEnergiesOptions.length === 0 ? x => true : x => selectedEnergiesOptions.map(l => l.label).includes(x.get("energy"))
}

function getCurrentImpactChart(method, impacts, endDiesel, endPetrol, selectedCarTypesOptions, selectedEnergiesOptions) {
    if (method == null || impacts == null) {
        return null
    }
    const impactData = impacts.find(
        impact => impact["impact_category"] === method
    )
    const carTypeF = carTypesFilter(selectedCarTypesOptions)
    const energyF = energiesFilter(selectedEnergiesOptions)
    const filteredData = impactData["aggregation"]
        .filter(
            x => carTypeF(x) && energyF(x)
        )
    return filteredData.count() === 0 ? (
        <EuiEmptyPrompt
            title={<EuiTitle size="m"><p>
                Nothing to show, check the combination of filters
            </p></EuiTitle>}
        />
        ) : (
        <AreaTimelinePlot
            name={impactData["impact_category"]}
            unit={impactData["unit"]}
            data={filteredData}
            endDiesel={endDiesel}
            endPetrol={endPetrol}
        />
    )
}

function chart({method, data, simulationDetails, selectedTypes, selectedEnergies}){
    const endDiesel = simulationDetails.endDiesel ? simulationDetails.endDiesel : new Date("2024-01-01T00:00:00Z").valueOf()
    const endPetrol = simulationDetails.endPetrol ? simulationDetails.endPetrol : new Date("2030-01-01T00:00:00Z").valueOf()
    return getCurrentImpactChart(method, data, endDiesel, endPetrol, selectedTypes, selectedEnergies)
}


const initialImpacts = null
const initialKm = null
const initialMethod = [{label: 'climate change total'}]
const initialError = null

function ImpactViewer(props) {
    const simulationId  = getIdFromPath(props.location.pathname)
    const [impacts, setImpact] = useState(initialImpacts)
    const [km, setKm] = useState(initialKm)
    const [method, setMethod] = useState(initialMethod)
    const [error, setError] = useState(initialError)

    const carTypesOptions = carTypeStream
        .map(
            t => ({label:t})
        )
        .sort( (a,b) => a.label.localeCompare(b.label))

    const defaultCarTypesOptions = []
    const [selectedCarTypesOptions, setCarTypesOptions] = useState(defaultCarTypesOptions)

    const energiesOptions = powertrainStream
        .map(
            t => ({label:t})
        )
        .sort( (a,b) => a.label.localeCompare(b.label))
    const defaultEnergiesOptions = []
    const [selectedEnergiesOptions, setEnergiesOptions] = useState(defaultEnergiesOptions)
    
    useEffect(
        () => fetchValue(simulationId, setImpact, setKm, setError),
        [simulationId]
    )
    
    if(error) {
        return <div> An error happened: {error.message}</div>
    }

    if(impacts == null || km == null){
        return <EuiLoadingChart size="xl" />
    }

    if (impacts.length === 0){
        return <div> No data availlable </div>
    }
    
    return <>
        <EuiForm>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow  label="Select a method">
                        <EuiComboBox
                            singleSelection={{ asPlainText: true }}
                            isClearable={false}
                            options={
                                impacts.map(
                                    i => ({
                                        label: i["impact_category"]
                                    })
                                )
                                .sort(
                                    (a,b) => a.label.localeCompare(b.label)
                                )
                            }
                            selectedOptions={method}
                            onChange={setMethod}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow hasEmptyLabelSpace >
                        <DownloadDataButton impacts={impacts} fileName={simulationId}/>
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiComboBox
                        placeholder="Select a car types to show"
                        options={carTypesOptions}
                        selectedOptions={selectedCarTypesOptions}
                        onChange={newOptions => setCarTypesOptions(newOptions)}
                        isClearable={true}
                    />
                    <EuiComboBox
                        placeholder="Select powertrains to show"
                        options={energiesOptions}
                        selectedOptions={selectedEnergiesOptions}
                        onChange={newOptions => setEnergiesOptions(newOptions)}
                        isClearable={true}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            
        </EuiForm>

        <KmCharts data={km} />

        { method === null ? null : <SimulationDetailsProvider
            simulationId={simulationId}
            as={chart}
            selectedTypes={selectedCarTypesOptions}
            selectedEnergies={selectedEnergiesOptions}
            method={method[0].label}
            data={impacts}
            />}
    </>
}

export default withRouter(ImpactViewer)