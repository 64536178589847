import React, {useRef} from 'react'
import Partners from './Layout/Partners'
import Flux from './Layout/Flux'
import Navbar from './Layout/Navbar';
import HermesHeader from './Layout/HermesHeader';
import HermesRouter from './Layout/HermesRouter';

import {
    EuiPage,
    EuiPageBody,
    EuiPageContent
  } from '@elastic/eui';
  
export default function App(){
    const navRef = useRef(null)
    const toogleMenu = () => navRef.current.toggleOpen()

    return (
        <>
            <HermesHeader toogleMenu={toogleMenu}/>
            <Navbar ref={navRef}/>
            <EuiPage className="euiNavDrawerPage">
                <EuiPageBody className="euiNavDrawerPage__pageBody">
                    <EuiPageContent>
                        <HermesRouter />
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>
            <EuiPage className="euiNavDrawerPage">
                <EuiPageBody className="euiNavDrawerPage__pageBody">
                    <Flux />
                </EuiPageBody>
            </EuiPage>
            <EuiPage className="euiNavDrawerPage">
                <EuiPageBody className="euiNavDrawerPage__pageBody">
                    <Partners />
                </EuiPageBody>
            </EuiPage>
            
        </>
    )
}