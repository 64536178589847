import {
    ENABLE_FEATURE,
    DISABLE_FEATURE,
    SWITCH_FEATURE,
} from "./actions"

import initialState from "./initialState";

export default function featuresSwitchReducer(state=initialState, action) {
    switch (action.type) {
        case ENABLE_FEATURE:
            return state.map(
                f => f.name === action.value && f.canBeSwitched ? {...f, isEnable: true} : f
            )
        case DISABLE_FEATURE:
            return state.map(
                f => f.name === action.value && f.canBeSwitched ? {...f, isEnable: false} : f
            )
        case SWITCH_FEATURE:
            return state.map(
                f => f.name === action.value && f.canBeSwitched ? {...f, isEnable: !f.isEnable} : f
            )
        default:
            return state;
    }
}