import React from 'react'
import {connect} from 'react-redux'
import {Route, Switch} from "react-router-dom" 

import Error404 from '../../routes/Error404';


const mapFeaturesToProps = state => ({
    features: state.features
})

function HermesRouter({features}){
    return (
        <Switch>
            {
                features
                    .filter( f => f.isEnable)
                    .map(
                        (f, i) => <Route
                            key={i}
                            exact={f.isExact}
                            path={f.route}
                            component={f.component}
                        />
                    )
            }
            <Route component={Error404}/>

            
        </Switch>
    )
}

export default connect(mapFeaturesToProps, null)(HermesRouter)