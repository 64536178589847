import React, {useState, useMemo} from "react"

import Chart from "./Chart"
import { EuiDatePicker,
    EuiDatePickerRange, 
    EuiForm,
    EuiFormRow,
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFieldNumber,
    EuiCheckbox
} from '@elastic/eui';

import moment from 'moment';

import superSort from './carSort'

function changeExtentFull(e, setStartDate, setEndDate) {
    if (e !== null) {
        setStartDate( moment(e[0]) )

        setEndDate( moment(e[1]) )
    }
}

const aggregationModeMap = [
    {
        label:"None"
    },
    {
        label:"Powertrain"
    },
    {
        label:"Vehicles type"
    }
]

const lineTypesMap = [
    {
        label: "Line",
        value: "line"
    },
    {
        label: "Stacked Area",
        value: {
            type: "stackedarea",
            sort:superSort
        }
    },
    {
        label: "Cumulative",
        value: "cumulative"
    },
    {
        label: "Bump Area",
        value: "bumparea"
    },
]

function getGroupeDataframe(df, mode){
    if(mode === aggregationModeMap[1]){
        return df.groupBy('energy')
    }
    else if(mode === aggregationModeMap[2]){
        return df.groupBy('carType')
    }
    else {
        return df.groupBy('energy', 'carType')
    }
}

function formatData(data, aggregationMode) {
    return  getGroupeDataframe(data, aggregationMode)
        .aggregate(
            g => g
                .groupBy('time')
                .aggregate(subgroup => subgroup.stat.sum('score'))
                .rename('aggregation', 'score')
                .toCollection()
        )
        .withColumn('id', r => r.carType+r.energy)
        .toCollection()
}

function AreaTimelinePlot(props) {

    const [startDate, setStartDate] = useState(moment(new Date("2020-01-01T00:00:00Z")))
    const [endDate, setEndDate] = useState(moment(new Date("2025-01-01T00:00:00Z")))

    const [aggregationMode, setAggregationMode] = useState([aggregationModeMap[0]])
    const [lineType, setLineType] = useState([lineTypesMap[0]])

    const [maxY, setMaxY] = useState(1000)
    const [shouldUseMaxY, setShouldUseMaxY] = useState(false)
    const data = useMemo(
        () => formatData(props.data, aggregationMode[0]),
        [props.data, aggregationMode]
    )
    
    return <>
        <EuiForm>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow label="Aggregation mode">
                        <EuiComboBox
                            singleSelection={{ asPlainText: true }}
                            isClearable={false}
                            options={aggregationModeMap}
                            selectedOptions={aggregationMode}
                            onChange={setAggregationMode}
                        />
                    </EuiFormRow>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiFormRow label="Line type">
                        <EuiComboBox
                            singleSelection={{ asPlainText: true }}
                            isClearable={false}
                            options={lineTypesMap}
                            selectedOptions={lineType}
                            onChange={setLineType}
                        />
                    </EuiFormRow>
                </EuiFlexItem>

            </EuiFlexGroup>

            <EuiFormRow label="Start and end dates">
                <EuiDatePickerRange
                    startDateControl={
                        <EuiDatePicker
                            selected={startDate}
                            onChange={setStartDate}
                            startDate={startDate}
                            endDate={endDate}
                            isInvalid={startDate > endDate}
                            aria-label="Start date"
                        />
                    }
                    
                    endDateControl={
                        <EuiDatePicker
                            selected={endDate}
                            onChange={setEndDate}
                            startDate={startDate}
                            endDate={endDate}
                            isInvalid={startDate > endDate}
                            aria-label="End date"
                        />
                    }
                />
            </EuiFormRow>

            <EuiFormRow>
                <EuiCheckbox
                    label="Use custom max Y"
                    checked={shouldUseMaxY}
                    onChange={ ()=> setShouldUseMaxY(!shouldUseMaxY)}
                />
            </EuiFormRow> 
            {
                shouldUseMaxY ? (
                    <EuiFormRow>
                        <EuiFieldNumber
                            placeholder="Max Y axe"
                            value={maxY}
                            onChange={ (v) => setMaxY(v.target.value)}
                            aria-label="Set the max on y axes"
                        />
                    </EuiFormRow> 
                ) : null
            }

            

            

        </EuiForm>

        <Chart 
            lineType={lineType[0].value}
            xExtent={[startDate, endDate]}
            data={data}
            name={props.name}
            unit={props.unit}
            changeExtent={v => changeExtentFull(v, setStartDate, setEndDate)}
            endDiesel={props.endDiesel}
            endPetrol={props.endPetrol}
            maxY={shouldUseMaxY ? maxY: undefined}
        />
    </>
}

export default AreaTimelinePlot