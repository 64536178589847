import {CHANGE_COLOR, RESET_PALETTE} from "./actions"
import { powertrainStream } from "../../utils/enumStream"
import { euiPaletteColorBlind } from "@elastic/eui"

// Bus 12, 18, VU, VP, BOM
const NUMBER_OF_VARIATION = 5

function sortByPowertrainForColorInitialization( a, b, accessor=x=>x) {
    const powertrainToIndex =  {
        ELECTRIC: 0, // vert 
        CNG: 1, //bleu,
        PLUG_IN_HYBRID_DIESEL:2,  // rose vif
        H2:3, // violet
        HYBRID_DIESEL:4,// rose pale
        PETROL: 5, // jaune
        HYBRID_PETROL:6,// taupe
        DIESEL:7, // orange
        PLUG_IN_HYBRID_PETROL:8 //rouge briquer
    }
    return powertrainToIndex[accessor(a)] -  powertrainToIndex[accessor(b)]
}
export function sortByPowertrain( a, b, accessor=x=>x) {
    const powertrainToIndex =  {
        DIESEL:0,
        PETROL: 1,
        CNG: 2,
        HYBRID_DIESEL:3,
        HYBRID_PETROL:4,
        PLUG_IN_HYBRID_DIESEL:5,
        PLUG_IN_HYBRID_PETROL:6,
        ELECTRIC: 7,
        H2:8,
    }
    return powertrainToIndex[accessor(a)] -  powertrainToIndex[accessor(b)]
}

const initialColors = euiPaletteColorBlind(NUMBER_OF_VARIATION, 'group', 'both')
const initialState = powertrainStream
    .sort(sortByPowertrainForColorInitialization)
    .map(
        (p, i) => ({
            powertrain: p,
            color: initialColors[i * NUMBER_OF_VARIATION],
            colors: initialColors.slice(i*NUMBER_OF_VARIATION, i* NUMBER_OF_VARIATION +5)
        }) 
    )
    .sort(sortByPowertrain)


export default function featuresSwitchReducer(state=initialState, action) {
    switch (action.type) {
        case CHANGE_COLOR:
            return state.map(
                p => p.powertrain === action.powertrain ? {...p, color: action.color} : p
            )
        case RESET_PALETTE: {
            return initialState
        }
        default:
            return state;
    }
}