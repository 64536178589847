import React, {useState, useEffect} from "react"

import {hlciApiUrl} from './urls'

function fetchSimulationDetails(id, setSimulation){
    fetch(`${hlciApiUrl}/configuration?simulationid=${id}`)
        .then(response => response.json())
        .then(results => setSimulation(results[0]))
        .catch(err => {
            console.error(err)
            //console.info('Retrying fetch details in 5 second')
            //setTimeout(
            //    () => fetchSimulationDetails(id, setSimulation),
            //    5000
            //)
        })
}
export default function SimulationDetailsProvider(props){
    const [simulationDetails, setSimulationDetails] = useState(null)
    useEffect(
        () => {
            fetchSimulationDetails(props.simulationId, setSimulationDetails)
        },
        [props.simulationId]
    )
    if(simulationDetails === null){
        return null
    }
    const Element = props.as
    return <Element {...props} simulationDetails={simulationDetails}/>
}