import React from 'react'
import styled, {css} from 'styled-components';
import Bouboul from './Title/Bouboul'

const Title = styled.h1`
    margin: 0.83em;
    ${props => css`
        color: ${props.color}
    `}
`

export default (props) => <div style={{
    marginTop: "0.83em"
}}>
    <Bouboul color={props.color}/>
        <Title color={props.color}>
            {props.children}
        </Title>
    <Bouboul color={props.color}/>
    <br/>
</div>