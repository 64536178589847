export const ENABLE_FEATURE = "ENABLE_FEATURE"
export const DISABLE_FEATURE = "DISABLE_FEATURE"
export const SWITCH_FEATURE = "SWITCH_FEATURE"

export const enableFeature = (featureName) => ({
    type: ENABLE_FEATURE,
    value: featureName
})

export const disableFeature = (featureName) => ({
    type: DISABLE_FEATURE,
    value: featureName
})


export const switchFeature = (featureName) => ({
    type: SWITCH_FEATURE,
    value: featureName
})