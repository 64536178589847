/* eslint-disable */ /**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var agent_pb = require('./agent_pb.js');
var car_pb = require('./car_pb.js');
var date_pb = require('./date_pb.js');
var energiesParameters_pb = require('./energiesParameters_pb.js');
var scenario_pb = require('./scenario_pb.js');
var environment_pb = require('./environment_pb.js');
var configuration_pb = require('./configuration_pb.js');
goog.exportSymbol('proto.AgentCreatedEvent', null, global);
goog.exportSymbol('proto.AgentHelcarEvent', null, global);
goog.exportSymbol('proto.AgentHelcarEvent.HelcarDecision', null, global);
goog.exportSymbol('proto.CarMarkedEvent', null, global);
goog.exportSymbol('proto.EndOfSimulationEvent', null, global);
goog.exportSymbol('proto.Event', null, global);
goog.exportSymbol('proto.ForbidDieselEvent', null, global);
goog.exportSymbol('proto.ForbidGasolineEvent', null, global);
goog.exportSymbol('proto.InitialDateEvent', null, global);
goog.exportSymbol('proto.InitialEnvCreatedEvent', null, global);
goog.exportSymbol('proto.InitialScenarioEvent', null, global);
goog.exportSymbol('proto.MoveCarEvent', null, global);
goog.exportSymbol('proto.TimeAdvancedEvent', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.Event.oneofGroups_);
};
goog.inherits(proto.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.Event.displayName = 'proto.Event';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.Event.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11]];

/**
 * @enum {number}
 */
proto.Event.EventCase = {
  EVENT_NOT_SET: 0,
  INITIALENVCREATEDEVENT: 1,
  INITIALDATEEVENT: 2,
  TIMEADVANCEDEVENT: 3,
  ENDOFSIMULATIONEVENT: 4,
  AGENTCREATEDEVENT: 5,
  MOVECAREVENT: 6,
  AGENTHELCAREVENT: 7,
  CARMARKEDEVENT: 8,
  INITIALSCENARIOEVENT: 9,
  FORBIDDIESEL: 10,
  FORBIDGASOLINE: 11
};

/**
 * @return {proto.Event.EventCase}
 */
proto.Event.prototype.getEventCase = function() {
  return /** @type {proto.Event.EventCase} */(jspb.Message.computeOneofCase(this, proto.Event.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialenvcreatedevent: (f = msg.getInitialenvcreatedevent()) && proto.InitialEnvCreatedEvent.toObject(includeInstance, f),
    initialdateevent: (f = msg.getInitialdateevent()) && proto.InitialDateEvent.toObject(includeInstance, f),
    timeadvancedevent: (f = msg.getTimeadvancedevent()) && proto.TimeAdvancedEvent.toObject(includeInstance, f),
    endofsimulationevent: (f = msg.getEndofsimulationevent()) && proto.EndOfSimulationEvent.toObject(includeInstance, f),
    agentcreatedevent: (f = msg.getAgentcreatedevent()) && proto.AgentCreatedEvent.toObject(includeInstance, f),
    movecarevent: (f = msg.getMovecarevent()) && proto.MoveCarEvent.toObject(includeInstance, f),
    agenthelcarevent: (f = msg.getAgenthelcarevent()) && proto.AgentHelcarEvent.toObject(includeInstance, f),
    carmarkedevent: (f = msg.getCarmarkedevent()) && proto.CarMarkedEvent.toObject(includeInstance, f),
    initialscenarioevent: (f = msg.getInitialscenarioevent()) && proto.InitialScenarioEvent.toObject(includeInstance, f),
    forbiddiesel: (f = msg.getForbiddiesel()) && proto.ForbidDieselEvent.toObject(includeInstance, f),
    forbidgasoline: (f = msg.getForbidgasoline()) && proto.ForbidGasolineEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Event}
 */
proto.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Event;
  return proto.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Event}
 */
proto.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.InitialEnvCreatedEvent;
      reader.readMessage(value,proto.InitialEnvCreatedEvent.deserializeBinaryFromReader);
      msg.setInitialenvcreatedevent(value);
      break;
    case 2:
      var value = new proto.InitialDateEvent;
      reader.readMessage(value,proto.InitialDateEvent.deserializeBinaryFromReader);
      msg.setInitialdateevent(value);
      break;
    case 3:
      var value = new proto.TimeAdvancedEvent;
      reader.readMessage(value,proto.TimeAdvancedEvent.deserializeBinaryFromReader);
      msg.setTimeadvancedevent(value);
      break;
    case 4:
      var value = new proto.EndOfSimulationEvent;
      reader.readMessage(value,proto.EndOfSimulationEvent.deserializeBinaryFromReader);
      msg.setEndofsimulationevent(value);
      break;
    case 5:
      var value = new proto.AgentCreatedEvent;
      reader.readMessage(value,proto.AgentCreatedEvent.deserializeBinaryFromReader);
      msg.setAgentcreatedevent(value);
      break;
    case 6:
      var value = new proto.MoveCarEvent;
      reader.readMessage(value,proto.MoveCarEvent.deserializeBinaryFromReader);
      msg.setMovecarevent(value);
      break;
    case 7:
      var value = new proto.AgentHelcarEvent;
      reader.readMessage(value,proto.AgentHelcarEvent.deserializeBinaryFromReader);
      msg.setAgenthelcarevent(value);
      break;
    case 8:
      var value = new proto.CarMarkedEvent;
      reader.readMessage(value,proto.CarMarkedEvent.deserializeBinaryFromReader);
      msg.setCarmarkedevent(value);
      break;
    case 9:
      var value = new proto.InitialScenarioEvent;
      reader.readMessage(value,proto.InitialScenarioEvent.deserializeBinaryFromReader);
      msg.setInitialscenarioevent(value);
      break;
    case 10:
      var value = new proto.ForbidDieselEvent;
      reader.readMessage(value,proto.ForbidDieselEvent.deserializeBinaryFromReader);
      msg.setForbiddiesel(value);
      break;
    case 11:
      var value = new proto.ForbidGasolineEvent;
      reader.readMessage(value,proto.ForbidGasolineEvent.deserializeBinaryFromReader);
      msg.setForbidgasoline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialenvcreatedevent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.InitialEnvCreatedEvent.serializeBinaryToWriter
    );
  }
  f = message.getInitialdateevent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.InitialDateEvent.serializeBinaryToWriter
    );
  }
  f = message.getTimeadvancedevent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TimeAdvancedEvent.serializeBinaryToWriter
    );
  }
  f = message.getEndofsimulationevent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.EndOfSimulationEvent.serializeBinaryToWriter
    );
  }
  f = message.getAgentcreatedevent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.AgentCreatedEvent.serializeBinaryToWriter
    );
  }
  f = message.getMovecarevent();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.MoveCarEvent.serializeBinaryToWriter
    );
  }
  f = message.getAgenthelcarevent();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.AgentHelcarEvent.serializeBinaryToWriter
    );
  }
  f = message.getCarmarkedevent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.CarMarkedEvent.serializeBinaryToWriter
    );
  }
  f = message.getInitialscenarioevent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.InitialScenarioEvent.serializeBinaryToWriter
    );
  }
  f = message.getForbiddiesel();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.ForbidDieselEvent.serializeBinaryToWriter
    );
  }
  f = message.getForbidgasoline();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.ForbidGasolineEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional InitialEnvCreatedEvent initialEnvCreatedEvent = 1;
 * @return {?proto.InitialEnvCreatedEvent}
 */
proto.Event.prototype.getInitialenvcreatedevent = function() {
  return /** @type{?proto.InitialEnvCreatedEvent} */ (
    jspb.Message.getWrapperField(this, proto.InitialEnvCreatedEvent, 1));
};


/** @param {?proto.InitialEnvCreatedEvent|undefined} value */
proto.Event.prototype.setInitialenvcreatedevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearInitialenvcreatedevent = function() {
  this.setInitialenvcreatedevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasInitialenvcreatedevent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional InitialDateEvent initialDateEvent = 2;
 * @return {?proto.InitialDateEvent}
 */
proto.Event.prototype.getInitialdateevent = function() {
  return /** @type{?proto.InitialDateEvent} */ (
    jspb.Message.getWrapperField(this, proto.InitialDateEvent, 2));
};


/** @param {?proto.InitialDateEvent|undefined} value */
proto.Event.prototype.setInitialdateevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearInitialdateevent = function() {
  this.setInitialdateevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasInitialdateevent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TimeAdvancedEvent TimeAdvancedEvent = 3;
 * @return {?proto.TimeAdvancedEvent}
 */
proto.Event.prototype.getTimeadvancedevent = function() {
  return /** @type{?proto.TimeAdvancedEvent} */ (
    jspb.Message.getWrapperField(this, proto.TimeAdvancedEvent, 3));
};


/** @param {?proto.TimeAdvancedEvent|undefined} value */
proto.Event.prototype.setTimeadvancedevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearTimeadvancedevent = function() {
  this.setTimeadvancedevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasTimeadvancedevent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional EndOfSimulationEvent endOfSimulationEvent = 4;
 * @return {?proto.EndOfSimulationEvent}
 */
proto.Event.prototype.getEndofsimulationevent = function() {
  return /** @type{?proto.EndOfSimulationEvent} */ (
    jspb.Message.getWrapperField(this, proto.EndOfSimulationEvent, 4));
};


/** @param {?proto.EndOfSimulationEvent|undefined} value */
proto.Event.prototype.setEndofsimulationevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 4, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearEndofsimulationevent = function() {
  this.setEndofsimulationevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasEndofsimulationevent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AgentCreatedEvent agentCreatedEvent = 5;
 * @return {?proto.AgentCreatedEvent}
 */
proto.Event.prototype.getAgentcreatedevent = function() {
  return /** @type{?proto.AgentCreatedEvent} */ (
    jspb.Message.getWrapperField(this, proto.AgentCreatedEvent, 5));
};


/** @param {?proto.AgentCreatedEvent|undefined} value */
proto.Event.prototype.setAgentcreatedevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 5, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearAgentcreatedevent = function() {
  this.setAgentcreatedevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasAgentcreatedevent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional MoveCarEvent moveCarEvent = 6;
 * @return {?proto.MoveCarEvent}
 */
proto.Event.prototype.getMovecarevent = function() {
  return /** @type{?proto.MoveCarEvent} */ (
    jspb.Message.getWrapperField(this, proto.MoveCarEvent, 6));
};


/** @param {?proto.MoveCarEvent|undefined} value */
proto.Event.prototype.setMovecarevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 6, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearMovecarevent = function() {
  this.setMovecarevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasMovecarevent = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AgentHelcarEvent agentHelcarEvent = 7;
 * @return {?proto.AgentHelcarEvent}
 */
proto.Event.prototype.getAgenthelcarevent = function() {
  return /** @type{?proto.AgentHelcarEvent} */ (
    jspb.Message.getWrapperField(this, proto.AgentHelcarEvent, 7));
};


/** @param {?proto.AgentHelcarEvent|undefined} value */
proto.Event.prototype.setAgenthelcarevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 7, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearAgenthelcarevent = function() {
  this.setAgenthelcarevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasAgenthelcarevent = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional CarMarkedEvent carMarkedEvent = 8;
 * @return {?proto.CarMarkedEvent}
 */
proto.Event.prototype.getCarmarkedevent = function() {
  return /** @type{?proto.CarMarkedEvent} */ (
    jspb.Message.getWrapperField(this, proto.CarMarkedEvent, 8));
};


/** @param {?proto.CarMarkedEvent|undefined} value */
proto.Event.prototype.setCarmarkedevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 8, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearCarmarkedevent = function() {
  this.setCarmarkedevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasCarmarkedevent = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional InitialScenarioEvent initialScenarioEvent = 9;
 * @return {?proto.InitialScenarioEvent}
 */
proto.Event.prototype.getInitialscenarioevent = function() {
  return /** @type{?proto.InitialScenarioEvent} */ (
    jspb.Message.getWrapperField(this, proto.InitialScenarioEvent, 9));
};


/** @param {?proto.InitialScenarioEvent|undefined} value */
proto.Event.prototype.setInitialscenarioevent = function(value) {
  jspb.Message.setOneofWrapperField(this, 9, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearInitialscenarioevent = function() {
  this.setInitialscenarioevent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasInitialscenarioevent = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ForbidDieselEvent forbidDiesel = 10;
 * @return {?proto.ForbidDieselEvent}
 */
proto.Event.prototype.getForbiddiesel = function() {
  return /** @type{?proto.ForbidDieselEvent} */ (
    jspb.Message.getWrapperField(this, proto.ForbidDieselEvent, 10));
};


/** @param {?proto.ForbidDieselEvent|undefined} value */
proto.Event.prototype.setForbiddiesel = function(value) {
  jspb.Message.setOneofWrapperField(this, 10, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearForbiddiesel = function() {
  this.setForbiddiesel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasForbiddiesel = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ForbidGasolineEvent forbidGasoline = 11;
 * @return {?proto.ForbidGasolineEvent}
 */
proto.Event.prototype.getForbidgasoline = function() {
  return /** @type{?proto.ForbidGasolineEvent} */ (
    jspb.Message.getWrapperField(this, proto.ForbidGasolineEvent, 11));
};


/** @param {?proto.ForbidGasolineEvent|undefined} value */
proto.Event.prototype.setForbidgasoline = function(value) {
  jspb.Message.setOneofWrapperField(this, 11, proto.Event.oneofGroups_[0], value);
};


proto.Event.prototype.clearForbidgasoline = function() {
  this.setForbidgasoline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.Event.prototype.hasForbidgasoline = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InitialEnvCreatedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InitialEnvCreatedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.InitialEnvCreatedEvent.displayName = 'proto.InitialEnvCreatedEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InitialEnvCreatedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.InitialEnvCreatedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InitialEnvCreatedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InitialEnvCreatedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InitialEnvCreatedEvent}
 */
proto.InitialEnvCreatedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InitialEnvCreatedEvent;
  return proto.InitialEnvCreatedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InitialEnvCreatedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InitialEnvCreatedEvent}
 */
proto.InitialEnvCreatedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InitialEnvCreatedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InitialEnvCreatedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InitialEnvCreatedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InitialEnvCreatedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InitialDateEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InitialDateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.InitialDateEvent.displayName = 'proto.InitialDateEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InitialDateEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.InitialDateEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InitialDateEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InitialDateEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    initialdate: (f = msg.getInitialdate()) && date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InitialDateEvent}
 */
proto.InitialDateEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InitialDateEvent;
  return proto.InitialDateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InitialDateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InitialDateEvent}
 */
proto.InitialDateEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setInitialdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InitialDateEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InitialDateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InitialDateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InitialDateEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInitialdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional Date initialDate = 1;
 * @return {?proto.Date}
 */
proto.InitialDateEvent.prototype.getInitialdate = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 1));
};


/** @param {?proto.Date|undefined} value */
proto.InitialDateEvent.prototype.setInitialdate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.InitialDateEvent.prototype.clearInitialdate = function() {
  this.setInitialdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.InitialDateEvent.prototype.hasInitialdate = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InitialScenarioEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InitialScenarioEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.InitialScenarioEvent.displayName = 'proto.InitialScenarioEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InitialScenarioEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.InitialScenarioEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InitialScenarioEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InitialScenarioEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    scenario: (f = msg.getScenario()) && scenario_pb.Scenario.toObject(includeInstance, f),
    configuration: (f = msg.getConfiguration()) && configuration_pb.Configuration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InitialScenarioEvent}
 */
proto.InitialScenarioEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InitialScenarioEvent;
  return proto.InitialScenarioEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InitialScenarioEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InitialScenarioEvent}
 */
proto.InitialScenarioEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scenario_pb.Scenario;
      reader.readMessage(value,scenario_pb.Scenario.deserializeBinaryFromReader);
      msg.setScenario(value);
      break;
    case 2:
      var value = new configuration_pb.Configuration;
      reader.readMessage(value,configuration_pb.Configuration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InitialScenarioEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InitialScenarioEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InitialScenarioEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InitialScenarioEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScenario();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scenario_pb.Scenario.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      configuration_pb.Configuration.serializeBinaryToWriter
    );
  }
};


/**
 * optional Scenario scenario = 1;
 * @return {?proto.Scenario}
 */
proto.InitialScenarioEvent.prototype.getScenario = function() {
  return /** @type{?proto.Scenario} */ (
    jspb.Message.getWrapperField(this, scenario_pb.Scenario, 1));
};


/** @param {?proto.Scenario|undefined} value */
proto.InitialScenarioEvent.prototype.setScenario = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.InitialScenarioEvent.prototype.clearScenario = function() {
  this.setScenario(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.InitialScenarioEvent.prototype.hasScenario = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Configuration configuration = 2;
 * @return {?proto.Configuration}
 */
proto.InitialScenarioEvent.prototype.getConfiguration = function() {
  return /** @type{?proto.Configuration} */ (
    jspb.Message.getWrapperField(this, configuration_pb.Configuration, 2));
};


/** @param {?proto.Configuration|undefined} value */
proto.InitialScenarioEvent.prototype.setConfiguration = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.InitialScenarioEvent.prototype.clearConfiguration = function() {
  this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.InitialScenarioEvent.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentCreatedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentCreatedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.AgentCreatedEvent.displayName = 'proto.AgentCreatedEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentCreatedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentCreatedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentCreatedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentCreatedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    agent: (f = msg.getAgent()) && agent_pb.Agent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentCreatedEvent}
 */
proto.AgentCreatedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentCreatedEvent;
  return proto.AgentCreatedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentCreatedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentCreatedEvent}
 */
proto.AgentCreatedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new agent_pb.Agent;
      reader.readMessage(value,agent_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentCreatedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentCreatedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentCreatedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentCreatedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      agent_pb.Agent.serializeBinaryToWriter
    );
  }
};


/**
 * optional Agent agent = 1;
 * @return {?proto.Agent}
 */
proto.AgentCreatedEvent.prototype.getAgent = function() {
  return /** @type{?proto.Agent} */ (
    jspb.Message.getWrapperField(this, agent_pb.Agent, 1));
};


/** @param {?proto.Agent|undefined} value */
proto.AgentCreatedEvent.prototype.setAgent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.AgentCreatedEvent.prototype.clearAgent = function() {
  this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentCreatedEvent.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ForbidDieselEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ForbidDieselEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.ForbidDieselEvent.displayName = 'proto.ForbidDieselEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ForbidDieselEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.ForbidDieselEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ForbidDieselEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ForbidDieselEvent.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ForbidDieselEvent}
 */
proto.ForbidDieselEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ForbidDieselEvent;
  return proto.ForbidDieselEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ForbidDieselEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ForbidDieselEvent}
 */
proto.ForbidDieselEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ForbidDieselEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ForbidDieselEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ForbidDieselEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ForbidDieselEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ForbidGasolineEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ForbidGasolineEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.ForbidGasolineEvent.displayName = 'proto.ForbidGasolineEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ForbidGasolineEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.ForbidGasolineEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ForbidGasolineEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ForbidGasolineEvent.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ForbidGasolineEvent}
 */
proto.ForbidGasolineEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ForbidGasolineEvent;
  return proto.ForbidGasolineEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ForbidGasolineEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ForbidGasolineEvent}
 */
proto.ForbidGasolineEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ForbidGasolineEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ForbidGasolineEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ForbidGasolineEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ForbidGasolineEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AgentHelcarEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AgentHelcarEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.AgentHelcarEvent.displayName = 'proto.AgentHelcarEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AgentHelcarEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.AgentHelcarEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AgentHelcarEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentHelcarEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    agent: (f = msg.getAgent()) && agent_pb.Agent.toObject(includeInstance, f),
    helcardecision: jspb.Message.getFieldWithDefault(msg, 2, 0),
    key: jspb.Message.getFieldWithDefault(msg, 3, 0),
    date: (f = msg.getDate()) && date_pb.Date.toObject(includeInstance, f),
    configuration: (f = msg.getConfiguration()) && configuration_pb.Configuration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AgentHelcarEvent}
 */
proto.AgentHelcarEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AgentHelcarEvent;
  return proto.AgentHelcarEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AgentHelcarEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AgentHelcarEvent}
 */
proto.AgentHelcarEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new agent_pb.Agent;
      reader.readMessage(value,agent_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 2:
      var value = /** @type {!proto.AgentHelcarEvent.HelcarDecision} */ (reader.readEnum());
      msg.setHelcardecision(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKey(value);
      break;
    case 4:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 5:
      var value = new configuration_pb.Configuration;
      reader.readMessage(value,configuration_pb.Configuration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AgentHelcarEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AgentHelcarEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AgentHelcarEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AgentHelcarEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      agent_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getHelcardecision();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      configuration_pb.Configuration.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.AgentHelcarEvent.HelcarDecision = {
  UNKNOWN: 0,
  BUY_PETROL: 1,
  BUY_DIESEL: 2,
  BUY_H2: 3,
  BUY_CNG: 4,
  DO_NOTHING: 5,
  BUY_HYBRID_DIESEL: 6,
  BUY_PLUG_IN_HYBRID_DIESEL: 7,
  BUY_HYBRID_PETROL: 8,
  BUY_PLUG_IN_HYBRID_PETROL: 9,
  BUY_ELECTRIC: 10
};

/**
 * optional Agent agent = 1;
 * @return {?proto.Agent}
 */
proto.AgentHelcarEvent.prototype.getAgent = function() {
  return /** @type{?proto.Agent} */ (
    jspb.Message.getWrapperField(this, agent_pb.Agent, 1));
};


/** @param {?proto.Agent|undefined} value */
proto.AgentHelcarEvent.prototype.setAgent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.AgentHelcarEvent.prototype.clearAgent = function() {
  this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentHelcarEvent.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HelcarDecision helcarDecision = 2;
 * @return {!proto.AgentHelcarEvent.HelcarDecision}
 */
proto.AgentHelcarEvent.prototype.getHelcardecision = function() {
  return /** @type {!proto.AgentHelcarEvent.HelcarDecision} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.AgentHelcarEvent.HelcarDecision} value */
proto.AgentHelcarEvent.prototype.setHelcardecision = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 key = 3;
 * @return {number}
 */
proto.AgentHelcarEvent.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.AgentHelcarEvent.prototype.setKey = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Date date = 4;
 * @return {?proto.Date}
 */
proto.AgentHelcarEvent.prototype.getDate = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 4));
};


/** @param {?proto.Date|undefined} value */
proto.AgentHelcarEvent.prototype.setDate = function(value) {
  jspb.Message.setWrapperField(this, 4, value);
};


proto.AgentHelcarEvent.prototype.clearDate = function() {
  this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentHelcarEvent.prototype.hasDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Configuration configuration = 5;
 * @return {?proto.Configuration}
 */
proto.AgentHelcarEvent.prototype.getConfiguration = function() {
  return /** @type{?proto.Configuration} */ (
    jspb.Message.getWrapperField(this, configuration_pb.Configuration, 5));
};


/** @param {?proto.Configuration|undefined} value */
proto.AgentHelcarEvent.prototype.setConfiguration = function(value) {
  jspb.Message.setWrapperField(this, 5, value);
};


proto.AgentHelcarEvent.prototype.clearConfiguration = function() {
  this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.AgentHelcarEvent.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MoveCarEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MoveCarEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.MoveCarEvent.displayName = 'proto.MoveCarEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MoveCarEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.MoveCarEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MoveCarEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MoveCarEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    agent: (f = msg.getAgent()) && agent_pb.Agent.toObject(includeInstance, f),
    key: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextkm: +jspb.Message.getFieldWithDefault(msg, 3, 0.0),
    nextnoncumulatedkm: +jspb.Message.getFieldWithDefault(msg, 4, 0.0),
    nextage: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MoveCarEvent}
 */
proto.MoveCarEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MoveCarEvent;
  return proto.MoveCarEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MoveCarEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MoveCarEvent}
 */
proto.MoveCarEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new agent_pb.Agent;
      reader.readMessage(value,agent_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNextkm(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNextnoncumulatedkm(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MoveCarEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MoveCarEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MoveCarEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MoveCarEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      agent_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNextkm();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getNextnoncumulatedkm();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getNextage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional Agent agent = 1;
 * @return {?proto.Agent}
 */
proto.MoveCarEvent.prototype.getAgent = function() {
  return /** @type{?proto.Agent} */ (
    jspb.Message.getWrapperField(this, agent_pb.Agent, 1));
};


/** @param {?proto.Agent|undefined} value */
proto.MoveCarEvent.prototype.setAgent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.MoveCarEvent.prototype.clearAgent = function() {
  this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.MoveCarEvent.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 key = 2;
 * @return {number}
 */
proto.MoveCarEvent.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.MoveCarEvent.prototype.setKey = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double nextKm = 3;
 * @return {number}
 */
proto.MoveCarEvent.prototype.getNextkm = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 3, 0.0));
};


/** @param {number} value */
proto.MoveCarEvent.prototype.setNextkm = function(value) {
  jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double nextNonCumulatedKm = 4;
 * @return {number}
 */
proto.MoveCarEvent.prototype.getNextnoncumulatedkm = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 4, 0.0));
};


/** @param {number} value */
proto.MoveCarEvent.prototype.setNextnoncumulatedkm = function(value) {
  jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional int32 nextAge = 5;
 * @return {number}
 */
proto.MoveCarEvent.prototype.getNextage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.MoveCarEvent.prototype.setNextage = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CarMarkedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CarMarkedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.CarMarkedEvent.displayName = 'proto.CarMarkedEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CarMarkedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.CarMarkedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CarMarkedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CarMarkedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    agent: (f = msg.getAgent()) && agent_pb.Agent.toObject(includeInstance, f),
    key: jspb.Message.getFieldWithDefault(msg, 2, 0),
    car: (f = msg.getCar()) && car_pb.Car.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CarMarkedEvent}
 */
proto.CarMarkedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CarMarkedEvent;
  return proto.CarMarkedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CarMarkedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CarMarkedEvent}
 */
proto.CarMarkedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new agent_pb.Agent;
      reader.readMessage(value,agent_pb.Agent.deserializeBinaryFromReader);
      msg.setAgent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKey(value);
      break;
    case 3:
      var value = new car_pb.Car;
      reader.readMessage(value,car_pb.Car.deserializeBinaryFromReader);
      msg.setCar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CarMarkedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CarMarkedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CarMarkedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CarMarkedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      agent_pb.Agent.serializeBinaryToWriter
    );
  }
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCar();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      car_pb.Car.serializeBinaryToWriter
    );
  }
};


/**
 * optional Agent agent = 1;
 * @return {?proto.Agent}
 */
proto.CarMarkedEvent.prototype.getAgent = function() {
  return /** @type{?proto.Agent} */ (
    jspb.Message.getWrapperField(this, agent_pb.Agent, 1));
};


/** @param {?proto.Agent|undefined} value */
proto.CarMarkedEvent.prototype.setAgent = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.CarMarkedEvent.prototype.clearAgent = function() {
  this.setAgent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.CarMarkedEvent.prototype.hasAgent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 key = 2;
 * @return {number}
 */
proto.CarMarkedEvent.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.CarMarkedEvent.prototype.setKey = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Car car = 3;
 * @return {?proto.Car}
 */
proto.CarMarkedEvent.prototype.getCar = function() {
  return /** @type{?proto.Car} */ (
    jspb.Message.getWrapperField(this, car_pb.Car, 3));
};


/** @param {?proto.Car|undefined} value */
proto.CarMarkedEvent.prototype.setCar = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.CarMarkedEvent.prototype.clearCar = function() {
  this.setCar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.CarMarkedEvent.prototype.hasCar = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeAdvancedEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TimeAdvancedEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.TimeAdvancedEvent.displayName = 'proto.TimeAdvancedEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeAdvancedEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeAdvancedEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeAdvancedEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeAdvancedEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    nextdate: (f = msg.getNextdate()) && date_pb.Date.toObject(includeInstance, f),
    unit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    configuration: (f = msg.getConfiguration()) && configuration_pb.Configuration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeAdvancedEvent}
 */
proto.TimeAdvancedEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeAdvancedEvent;
  return proto.TimeAdvancedEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeAdvancedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeAdvancedEvent}
 */
proto.TimeAdvancedEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new date_pb.Date;
      reader.readMessage(value,date_pb.Date.deserializeBinaryFromReader);
      msg.setNextdate(value);
      break;
    case 2:
      var value = /** @type {!proto.Date.Frequency} */ (reader.readEnum());
      msg.setUnit(value);
      break;
    case 3:
      var value = new configuration_pb.Configuration;
      reader.readMessage(value,configuration_pb.Configuration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeAdvancedEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeAdvancedEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeAdvancedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeAdvancedEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNextdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      configuration_pb.Configuration.serializeBinaryToWriter
    );
  }
};


/**
 * optional Date nextDate = 1;
 * @return {?proto.Date}
 */
proto.TimeAdvancedEvent.prototype.getNextdate = function() {
  return /** @type{?proto.Date} */ (
    jspb.Message.getWrapperField(this, date_pb.Date, 1));
};


/** @param {?proto.Date|undefined} value */
proto.TimeAdvancedEvent.prototype.setNextdate = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.TimeAdvancedEvent.prototype.clearNextdate = function() {
  this.setNextdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.TimeAdvancedEvent.prototype.hasNextdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Date.Frequency unit = 2;
 * @return {!proto.Date.Frequency}
 */
proto.TimeAdvancedEvent.prototype.getUnit = function() {
  return /** @type {!proto.Date.Frequency} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {!proto.Date.Frequency} value */
proto.TimeAdvancedEvent.prototype.setUnit = function(value) {
  jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Configuration configuration = 3;
 * @return {?proto.Configuration}
 */
proto.TimeAdvancedEvent.prototype.getConfiguration = function() {
  return /** @type{?proto.Configuration} */ (
    jspb.Message.getWrapperField(this, configuration_pb.Configuration, 3));
};


/** @param {?proto.Configuration|undefined} value */
proto.TimeAdvancedEvent.prototype.setConfiguration = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.TimeAdvancedEvent.prototype.clearConfiguration = function() {
  this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.TimeAdvancedEvent.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EndOfSimulationEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EndOfSimulationEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.EndOfSimulationEvent.displayName = 'proto.EndOfSimulationEvent';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EndOfSimulationEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.EndOfSimulationEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EndOfSimulationEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EndOfSimulationEvent.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EndOfSimulationEvent}
 */
proto.EndOfSimulationEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EndOfSimulationEvent;
  return proto.EndOfSimulationEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EndOfSimulationEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EndOfSimulationEvent}
 */
proto.EndOfSimulationEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EndOfSimulationEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EndOfSimulationEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EndOfSimulationEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EndOfSimulationEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto);
