import React from "react"
import {connect} from 'react-redux'
import xlsxConverter from '../utils/xlsxConverter'
import {setConfigurationAction} from '../features/configurationLoader'

function checkAndSetConfiguration(file, setConfiguration) {
    // TODO:
    //if valid store and enable button
    // else not store and show error and disable button
    console.info("TODO: ADD validation")
    xlsxConverter(file, setConfiguration)
}

class HermesUploadConverter extends React.Component {
    constructor(props) {
        super(props);
        this.uploadRef = React.createRef();
    }

    componentDidMount() {
        this.uploadRef.current.addEventListener(
            'upload-request',
            (e) => {
                e.preventDefault()
                this.uploadRef.current.files[0].status = String("Parsing ...")
                checkAndSetConfiguration(e.target.files[0], this.props.setConfiguration)
                if (this.props.onUpload) {
                    this.props.onUpload()
                }
                this.uploadRef.current.files[0].status = String("Done")
                this.uploadRef.current.files[0].complete = true
            }
        )
    }

    componentWillReceiveProps(props) {
        if (props.title !== this.props.title) {
            this.dropdownRef.current.title = props.title;
        }
    
        if (props.show !== this.props.show) {
            this.dropdownRef.current.show = props.show;
        }
    }

    render() {
        return (
            <vaadin-upload
                ref={this.uploadRef}
                max-files="1"
                accept=".xlsx"
                noAuto={true}
            >
                {this.props.children}
            </vaadin-upload>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setConfiguration: configuration => dispatch(
            setConfigurationAction(
                configuration
            )
        )
    }
}

export default connect(null, mapDispatchToProps)(HermesUploadConverter);