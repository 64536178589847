import React from 'react'
import {EuiButton} from '@elastic/eui';
import notify from '../VaadinNotificationUtil'
import gatewayClient from '../../client/configurationLauncher'

function sendConfiguration(configuration, disableButton, setId, descriptionRef) {
    const descriptionField = descriptionRef.current
    const description = descriptionField.value
    configuration.setDescription(description)
    gatewayClient.runSimulation(configuration, null,
        (err, response) => {
            if (err) {
                console.log(err.code);
                console.log(err.message);
                notify(`Error ${err.code}:`,  `${err.message}`)
                

            } else {
                const configId = response.getId();
                setId(configId)
            }
        }
    )
    disableButton()
}

export default function SubmitButton(props) {

    return (
        <EuiButton
            fill
            isDisabled={!props.enable}
            onClick={() => sendConfiguration(props.configuration, props.disableButton, props.setConfigId, props.descriptionRef)}>
            {
                props.enable ? "Start simulation" : `Simulation ${props.configId !== null? `${props.configId} started` : "is starting ..."}`
            }
        </EuiButton>
    )
}