import React from 'react'
import {useHistory} from "react-router-dom"

import {
    EuiEmptyPrompt,
    EuiButton,
    EuiTitle
} from "@elastic/eui"

import { Browser } from "react-kawaii"

export default function Error404() {
    const history = useHistory()
    return <EuiEmptyPrompt
            title={<EuiTitle size="l"><h1>
                Error 404
            </h1></EuiTitle>}
            body={<Browser size={200} mood="ko" color="#D3DAE6" />}
            actions={ <EuiButton
                    color="primary"
                    href='/'
                    onClick={
                        event => {
                            event.preventDefault()
                            history.push("/")
                        }
                    }
                >
                    Back home
                </EuiButton>
            }
        />
}